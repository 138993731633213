import { ChartOverviewObject } from 'src/interfaces/infoPages'
import { renderLink } from './dataTermsOfServices'

const chartOverviewObjects: ChartOverviewObject[] = [
  {
    pageIntro: '',
    chartObjects: [
      {
        chartName: '',
        id: '1.1',
        chartDescriptionArray: [
          {
            text: `This Privacy Policy describes our policies and procedures on the collection, use and disclosure of Your information when you use StarSight and tells you about your privacy rights and how the law protects you.
            `
          },
          {
            text: `We use your Personal Information to provide and improve our products and services. By using our products and services, you acknowledge our collection and use of information in accordance with this Privacy Policy.
            `
          }
        ]
      },
      {
        chartName: 'Overview',
        id: '1.2',
        chartDescriptionArray: [
          {
            text: (
              <>
                Opportunity at Work, Inc., (alternately, “O@W”, “we”, “us” or
                “our”) respects your privacy and is committed to complying with
                this privacy policy (“Privacy Policy”), which describes what
                information we collect about you, including how we collect it,
                how we use it, with whom we may share it and what choices you
                have regarding our use of your information. This Privacy Policy
                applies to information collected on our website located at{` `}
                {renderLink({
                  link: 'https://stellarsight.org',
                  isEmail: false
                })}{' '}
                hereby referred to as “the Platform” for the rest of this
                document.
              </>
            )
          },
          {
            text: `O@W collects and stores certain information from or about you that can be used to uniquely contact, identify, or locate you ("Personal Information").
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'What Information Do We Collect?',
    chartObjects: [
      {
        id: '2.1',
        chartDescriptionArray: [
          {
            text: `O@W collects information, including Personal Information about you from a variety of sources, including information that you provide directly to us when you contact us, sign-up for an account and create a profile, and information that we automatically collect about you when you access and use the Platform.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Information You Provide Us',
    chartObjects: [
      {
        id: '2.3',
        chartDescriptionArray: [
          {
            text: `To manage and provide the Platform, if you sign-up for an account, or otherwise contact us, we and/or our service providers collect the requested information that you provide which includes your email address, organization, and persona type.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Information Collected Automatically',
    chartObjects: [
      {
        id: '2.4',
        chartDescriptionArray: [
          {
            text: `We collect information automatically from you when you visit and use the Platform. Such information includes the following:
            `
          },
          {
            text: `Usage information. We collect information about how you interact with the Platform to operate and improve the Platform. This includes information such as the dates and times that you visit the Platform, the page you view, referring / exit URLs, and your browser type. In some cases, we collect this information through cookies and similar technologies. To learn more about these technologies, please see the Cookies section below.
            `
          },
          {
            text: 'Device Information. To manage the Platform, we collect information about the devices you use to access the Platform, including your device model, IP address, inferred geolocation information, operating system, and unique device identifiers.'
          },
          {
            text: 'Company reserves the right to take whatever lawful actions it may deem appropriate in response to actual or suspected violations of these Terms and Conditions, including, without limitation, the suspension or termination of Your access and/or Individual Account and/or Organizational Account. Company may cooperate with legal authorities and/or third parties in the investigation of any suspected or alleged crime or civil wrong. Company reserves the right at all times to disclose any information as Company deems necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in Company’s sole discretion. You also agree to reimburse Company for any damage, loss, cost or expense Company incurs (including fees or costs of attorneys, accountants, professional advisors, and other experts incurred in connection with the defense or settlement of the foregoing) because of Your use of the Platform or the Services for any unlawful or prohibited purpose.'
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Cookies and Usage Reports',
    chartObjects: [
      {
        id: '2.5',
        chartDescriptionArray: [
          {
            text: `By placing a small file known as a "cookie" on your computer (or other device), O@W’s and its third party service providers’ servers passively gather information about all visitors' use of the Platform for several reasons, including, but not limited to, the following: statistics collection and analysis, Platform optimization, analytics, and maintenance of user login information. The information that we and our third party service providers track with cookies includes, but is not limited to, the type of browser (such as Google Chrome or Internet Explorer) and Internet-connected devices being used to access the Platform, your Internet protocol (“IP”) address, your home domain or Internet service provider, your referrer URL (which is the URL for the website that you were viewing prior to visiting the Platform), how you were directed to the Platform, which specific pages you access on the Platform, how long you view each page, the time and date you access our Platform and the total number of visitors to the Platform and any portions thereof. We, and/or our third party service providers, may use the information collected from cookies or similar files on your computer for security purposes (such as authentication), to facilitate site navigation and to personalize your experience while visiting the Platform. This data helps us and our third party service providers improve our respective products and services.
            `
          },
          {
            text: `Most popular Internet browser applications will allow you to configure the browser so as not to accept cookies. However, setting your browser to reject cookies may, in certain instances, prevent you from taking full advantage of the Platform (or some portion thereof) and its capabilities, or cause some features of the Platform to function improperly or more slowly.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Google Analytics',
    chartObjects: [
      {
        id: '3.4',
        chartDescriptionArray: [
          {
            text: (
              <>
                We use Google Analytics, a web analysis service provided by
                Google, in order to better understand your use of the Platform
                and how we can improve it. Google Analytics collects information
                such as how often users visit a website, what pages you visit
                when you do so, and what other websites you used prior to coming
                to such a website. Google Analytics collects only the IP address
                assigned to you on the date you visit a website, rather than
                your name or other directly identifying information. We do not
                combine the information collected through the use of Google
                Analytics with your Personal Information. Although Google
                Analytics plants a persistent cookie on your web browser to
                identify you as a unique user the next time you visit a website,
                the cookie cannot be used by anyone but Google. Google Analytics
                uses its cookie(s) and the information Google Analytics collects
                to track your online activities over time and across different
                websites when you use our Platform, and other websites. Google’s
                ability to use and share information collected by Google
                Analytics about your visits to the Platform is governed by the
                Google Analytics Terms of Service and the Google Privacy Policy.
                Google utilizes the data collected to track and examine the use
                of the Platform, to prepare reports on its activities, and share
                them with other Google services. To more fully understand how
                Google may use the data it collects on the Platform, please
                review “How Google Uses Information From Sites or Apps That Use
                Our Services”.
                {renderLink({
                  link: 'https://policies.google.com/technologies/partner-sites?hl=en-US',
                  isEmail: false
                })}
              </>
            )
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Correspondence',
    chartObjects: [
      {
        id: '1',
        chartDescriptionArray: [
          {
            text: `We appreciate your questions and comments about the Platform and welcome your messages at support@stellarsight.org. If you correspond with O@W, we may collect information including the content of, and metadata regarding, any correspondence you may provide us. We may share your messages with those within our organization who are most capable of addressing the issues contained in your message. We may archive your message for a certain period of time or discard it.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'How Can I Opt-Out of Promotional Correspondence?',
    chartObjects: [
      {
        id: '2',
        chartDescriptionArray: [
          {
            text: `We may send you emails with promotional offers only if you opt-in to receiving such emails. If you would no longer like to receive information, newsletters or other promotional messages from us, please click the link at the bottom of any such email you receive from us and follow the instructions, or alternatively you may contact us at support@stellarsight.org with the word “UNSUBSCRIBE” or “REMOVE” in the subject line. Your email address will be removed from our marketing list. Please allow us a reasonable period of time in order to satisfy your request, as some promotions may already be in process.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Online Account Management',
    chartObjects: [
      {
        id: '3',
        chartDescriptionArray: [
          {
            text: `If you elect to subscribe to certain services available on the Platform, including the ability to access any password-protected areas of the Platform through your account, you will be required to submit Personal Information for the purpose of verifying your identity and your authority to manage the account. This Personal Information will be used exclusively by us and our third-party service providers for internal use and to respond to your service requests.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'How Do We Use The Information That We Collect?',
    chartObjects: [
      {
        id: '4',
        chartDescriptionArray: [
          {
            text: `O@W and our third party service providers (such as hosting, email marketing, and payment processing providers) collect and use the Personal Information that we collect to operate the Platform, including to provide certain services and improve the user experience, address Platform integrity or security issues, and conduct research and analysis to maintain, protect, develop, and improve the Platform, and, additionally, use the information submitted by users as described below or elsewhere in this Privacy Policy or the Terms of Service. 
            `
          },
          {
            text: `We also use your personal information to help provide our products and services. We may send you emails or in-app messages to receive information about the Platform.
            `
          },
          {
            text: `We retain the Personal Information that we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with the services you requested or to comply with an applicable legal requirement).
            `
          },
          {
            text: `Except as set forth in this Privacy Policy, O@W will not use, disclose, or transfer your Personal Information unless:
            `,
            bullets: [
              {
                content: 'you expressly authorize O@W to do so, '
              },
              {
                content: `it is necessary to allow O@W’s service providers, agents, or other third parties to provide products or services for, or to O@W,
                  `
              },
              {
                content: `it is necessary in order to provide the Platform,
                `
              },
              {
                content: `O@W is sending you other information that may be useful to you,
                subject to applicable contractual or legal restrictions, it is disclosed to entities that perform marketing services on O@W’s behalf or to other entities with whom O@W has joint marketing agreements,
                `
              },
              {
                content: `it is necessary to protect the confidentiality or security of your records,
                `
              },
              {
                content: `it is necessary in connection with other business purposes including, without limitation, verifying your identity, investigating complaints about the Platform, risk assessment, security, fraud and crime prevention/detection, monitoring, research and analysis, marketing, and dispute resolution,
                `
              },
              {
                content: `it is necessary to comply with law enforcement, a governmental mandate, or other legal requirement, if appropriate, for your protection or in connection with an investigation or prosecution of possible unlawful activity,
                `
              },
              {
                content: `subject to applicable contractual or legal restrictions, it is necessary in connection with a sale of all or substantially all of the assets of O@W or the merger of O@W into another entity or any consolidation, share exchange, combination, reorganization, or like transaction in which O@W is not the survivor, or
                `
              },
              {
                content: `it is otherwise necessary for O@W to disclose it as required or permitted by law.
                `
              }
            ]
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'With Whom Do We Share Information That We Collect?',
    chartObjects: [
      {
        id: '5',
        chartDescriptionArray: [
          {
            text: `We may disclose Personal Information if we become subject to a subpoena or court order, or if we are otherwise legally required to disclose information. We may also use and disclose Personal Information to establish or exercise our legal rights, to enforce the Terms of Service, to assert and defend against legal claims, or if we believe such disclosure is necessary to investigate, prevent, or take other action regarding actual or suspected illegal or fraudulent activities or potential threats to the physical safety or well-being of any person.
            `
          },
          {
            text: `It is possible that our organizational structure might change or that we might combine with another organization. In any such transactions, customer information generally is, and should be expected to be, one of the transferred business assets. Also, if any bankruptcy, liquidation or reorganization proceeding is brought by or against us, your information may be considered a company asset that may be sold or transferred to third parties.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'What Choices Do You Have?',
    chartObjects: [
      {
        id: '6',
        chartDescriptionArray: [
          {
            text: `When submitting information, corresponding, making requests for information, and otherwise interacting with O@W and its representatives through or in connection with the Platform, you generally choose what information to supply or submit, whether you wish to receive further information, and how you may be contacted. Please take care to share only such information as is needed or that you believe is appropriate. Any promotional email we send to you will contain a link that will allow you to opt out of receiving any further correspondence. If you have created an online account, you may also change your correspondence preferences by logging into your account. You may disable your online account access at any time. 
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'How Do We Protect Information Collected About You?',
    chartObjects: [
      {
        id: '7',
        chartDescriptionArray: [
          {
            text: `O@W takes commercially reasonable measures to secure and protect information transmitted via or stored on the Platform. Nevertheless, no security system is impenetrable. We cannot guarantee that information that users of the Platform may happen to transmit or otherwise supply will be totally secure.
            `
          },
          {
            text: `You agree to immediately notify us of any breach of the Platform's security, this Privacy Policy, or the Terms of Service of which you become aware by emailing us at support@stellarsight.org.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Children',
    chartObjects: [
      {
        id: '8',
        chartDescriptionArray: [
          {
            text: `You may not use the Platform if you are under the age of 13. If we discover that we have collected Personal Information from someone under the age of 13, then we will immediately erase that information.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Linked Platforms',
    chartObjects: [
      {
        id: '9',
        chartDescriptionArray: [
          {
            text: `For your convenience, some hyperlinks may be posted on the Platform that link to other websites not under our control. We are not responsible for, and this Privacy Policy does not apply to, the privacy practices of those sites or of any companies that we do not own or control. We cannot be responsible for the privacy practices of any such websites or pages not under our control and we do not endorse any of those websites or pages, the services or products described or offered on such sites or pages, or any of the content contained on those sites or pages. We encourage you to seek out and read the privacy policy of each website that you visit. In addition, should you happen to initiate a transaction on a website that our Platform links to, even if you reached that site through the Platform, the information that you submit to complete that transaction becomes subject to the privacy practices of the operator of that linked website. You should read that website's privacy policies to understand how Personal Information that is collected about you is used and protected.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'User-Provided Content',
    chartObjects: [
      {
        id: '3.5',
        chartDescriptionArray: [
          {
            text: `Any information, communications, or material of any type or nature that you submit to the public-facing portion of the Platform by email, posting, messaging, uploading, downloading, or otherwise (collectively, a “Submission(s)”) is done at your own risk and without any expectation of privacy. We cannot control the actions of other users of the Platform and we are therefore not responsible for any Submissions contained on the Platform.
            `
          },
          {
            text: `Additionally, in the event that we offer a message board or any other interactive or social-type feature on the Platform, please be aware that these areas may allow you to post, and share with other users, certain messages, content, or other information. We encourage you to be wary of giving out any Personal Information, or any Personal Information of others to which you may access, in such forums. The information you post can be collected and used by other people. We cannot guarantee the privacy and safety of these areas and are therefore not responsible for any information you choose to post. Your use of these features is fully at your own risk.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Social Media Platforms and Platforms',
    chartObjects: [
      {
        id: '3.7',
        chartDescriptionArray: [
          {
            text: `Any Submission that you submit to any of our pages contained on a social media platform or website by email, posting, messaging, uploading, downloading, or otherwise is done at your own risk and without any expectation of privacy. We cannot control the actions of other users of a social media platform and we are therefore not responsible for any Submissions contained on a social media platform or website.`
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Do Not Track Requests',
    chartObjects: [
      {
        id: '3.8',
        chartDescriptionArray: [
          {
            text: (
              <>
                {' '}
                We do not currently honor “Do Not Track” requests. To find out
                more about “Do Not Track”, you may wish to visit{' '}
                {renderLink({
                  link: 'http://www.allaboutdnt.com',
                  isEmail: false
                })}
              </>
            )
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Data Protection Rights',
    chartObjects: [
      {
        id: '3.9',
        chartDescriptionArray: [
          {
            text: `You can also contact us if you need assistance. Please note, we may not accommodate a request to change information if we believe the change would violate any law or legal requirement. If you are accessing our Platform as a representative of an employer, we may not honor your requests to the extent they violate our agreement with your employer.
            `
          },
          {
            text: `Subject to applicable data protection laws, you may have the right to access, update, and correct inaccuracies in your Personal Information. Typically, these rights should be exercised with our partners that help support our Platform rather than directly with us, for example, an employer with whom you were employed or applied for a job.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Changes to our Privacy Policy',
    chartObjects: [
      {
        id: '3.10',
        chartDescriptionArray: [
          {
            text: `We may change this Privacy Policy at any time and from time to time. The most recent version of the Privacy Policy is reflected by the effective date located at the top of this Privacy Policy. All such updates and amendments are effective immediately upon notice thereof, which we may give by any means, including, but not limited to, by posting a revised version of this Privacy Policy or other notice on the Platform.
            `
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Whom Can You Contact For More Information?',
    chartObjects: [
      {
        id: '3.10',
        chartDescriptionArray: [
          {
            text: (
              <>
                If you have any questions or suggestions about the Platform,
                O@W, or our privacy practices, please contact us{' '}
                {renderLink({
                  link: 'support@stellarsight.org',
                  isEmail: true
                })}
              </>
            )
          }
        ]
      }
    ]
  }
]

export { chartOverviewObjects }
