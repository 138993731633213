import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Link } from 'react-router-dom';
import { buildParamHashLink } from '../util/util';

function CustomLegend({ legendKeys, chartId, stacked = false }) {
  // Construct a URL to the specified chart's guidance
  const chartGuidanceLinkTo = buildParamHashLink('/data-guidance', chartId);

  const gradientLegendsDetails = [
    {
      chartId: '1.1',
      labelText: ['More STARs', 'Fewer STARs'],
    },
    {
      chartId: '3.1',
      labelText: ['Most required', 'Least required'],
    },
  ];
  const gradientLegend = gradientLegendsDetails.find(
    (gradientLegend) => gradientLegend.chartId === chartId
  );

  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='flex-end'
      spacing={2}
    >
      <Grid item>
        <Grid container alignItems='flex-center'>
          {gradientLegend ? (
            <React.Fragment>
              <Typography
                variant='caption'
                style={{ paddingBottom: '.1rem', color: '#636464' }}
              >
                {gradientLegend.labelText[0]}
              </Typography>
              <Box
                sx={{
                  height: '14px',
                  width: '10rem',
                  margin: '0 0.5rem',
                  background: `linear-gradient(to right, ${legendKeys.map(
                    (legendKey) => legendKey.colorStr
                  )})`,
                }}
              ></Box>
              <Typography
                variant='caption'
                style={{ paddingBottom: '.1rem', color: '#636464' }}
              >
                {gradientLegend.labelText[1]}
              </Typography>
            </React.Fragment>
          ) : (
            legendKeys.map((legendKey, index) => {
              return (
                <React.Fragment key={legendKey.keyName + '-fragment'}>
                  <Grid
                    item
                    xs={stacked ? 12 : null}
                    key={legendKey.keyName + '-color-key'}
                  >
                    {legendKey.color ? (
                      <SquareRoundedIcon
                        color={legendKey.color}
                        style={{ fontSize: '1rem', marginBottom: '-0.2rem' }}
                      />
                    ) : (
                      <SquareRoundedIcon
                        style={{
                          fontSize: '1rem',
                          color: legendKey.colorStr,
                          marginBottom: '-0.2rem',
                        }}
                      />
                    )}
                    <Typography
                      key={legendKey.keyName + 'key'}
                      variant='caption'
                      style={{
                        color: '#636464',
                        paddingLeft: '0.25rem',
                        paddingRight:
                          index === legendKeys.length - 1 ? null : '1rem',
                      }}
                    >
                      {legendKey.keyName}
                    </Typography>
                  </Grid>
                </React.Fragment>
              );
            })
          )}
        </Grid>
      </Grid>
      <Grid item style={{ zIndex: 1, marginLeft: 'auto' }}>
        <Typography
          variant='body2'
          color='primary'
          style={{ fontSize: '0.8rem', fontStyle: 'italic' }}
        >
          <Link to={chartGuidanceLinkTo} style={{ color: '#636464' }}>
            {'View Data Sources & Information >'}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default CustomLegend;
