import { Link as RouterLink } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { Container, Grid, Link, Typography, Box } from '@mui/material'
import { useTheme } from '@emotion/react'

import oawLogo from '../assets/images/oaw_logo.svg'
import logoLinkedin from '../assets/images/linkedin.png'
import logoTwitter from '../assets/images/twitter.png'
import logoFacebook from '../assets/images/facebook.png'
import { footerClick } from 'src/util/gtm_utils'

export default function Footer() {
  const { isAuthenticated } = useAuth0()

  const theme = useTheme()

  return (
    <Box sx={{ backgroundColor: theme.palette.neutral.gray900, pt: 6, pb: 6 }}>
      <Container>
        <Grid
          container
          justifyContent='space-between'
          direction='row'
          sx={{ pb: 1, pt: 3, borderTop: 1, borderColor: '#E5E7EB' }}
        >
          <Grid item>
            <Link
              href='https://opportunityatwork.org'
              onClick={() => footerClick('O@W logo', isAuthenticated)}
            >
              <img src={oawLogo} alt='' />
            </Link>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Link
                  href='https://www.linkedin.com/company/opportunity-work'
                  onClick={() => footerClick('linkedin', isAuthenticated)}
                >
                  <img src={logoLinkedin} alt='' />
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href='https://www.facebook.com/opportunityatwork'
                  onClick={() => footerClick('facebook', isAuthenticated)}
                >
                  <img src={logoFacebook} alt='' />
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href='https://twitter.com/OpptyatWork'
                  onClick={() => footerClick('twitter', isAuthenticated)}
                >
                  <img src={logoTwitter} alt='' />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent='space-between'
          direction='row'
          sx={{ pb: 3, pt: 1, borderBottom: 1, borderColor: '#E5E7EB' }}
        >
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Link
                  component={RouterLink}
                  to='/privacy-policy'
                  underline='none'
                  onClick={() =>
                    footerClick('privacy policy', isAuthenticated)
                  }
                  sx={{
                    color: theme.palette.neutral.white
                  }}
                >
                  Privacy policy
                </Link>
              </Grid>
              <Grid item>
                <Link
                  component={RouterLink}
                  to='/terms-of-services'
                  underline='none'
                  onClick={() =>
                    footerClick('terms of service', isAuthenticated)
                  }
                  sx={{
                    color: theme.palette.neutral.white
                  }}
                >
                  Terms of service
                </Link>
              </Grid>
              <Grid item>
                <Link
                  underline='none'
                  onClick={() => footerClick('contact us', isAuthenticated)}
                  href='mailto:support@stellarsight.org'
                  sx={{
                    color: theme.palette.neutral.white
                  }}
                >
                  Contact us
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                {isAuthenticated && (
                  <Link
                    component={RouterLink}
                    to='/data-guidance'
                    underline='none'
                    onClick={() => footerClick('data sources', isAuthenticated)}
                    sx={{
                      color: theme.palette.neutral.white
                    }}
                  >
                    Data sources and info
                  </Link>
                )}
              </Grid>
              <Grid item>
                <Typography
                  variant='body'
                  sx={{
                    color: theme.palette.neutral.gray500
                  }}
                >
                  &copy; 2024 Opportunity at Work.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
