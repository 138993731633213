import { Button, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import { STATEINITIALS } from '../../../constants/demographics'
import { Region } from 'src/interfaces/demographics'

export default function GoToStateButton({
  handleSearchRegionChange,
  region
}: {
  handleSearchRegionChange: Function
  region: Region
}) {
  const theme = useTheme()

  const stateInitials =
    region?.geo_type_id === 2
      ? `${
          STATEINITIALS[
            region.region_name_short?.replace(
              / /g,
              '_'
            ) as keyof typeof STATEINITIALS
          ]
        } state map`
      : 'Go to metro'

  return (
    <Button
      variant='outlined'
      onClick={(event) => handleSearchRegionChange(event, region)}
      sx={{
        width: '100%',
        textTransform: 'none',
        color: theme?.palette.neutral.gray900,
        border: '2px solid #E5E7EB',
        borderRadius: '120px',
        fontFamily: 'DM Sans'
      }}
    >
      <Typography variant='bodyBold'>{stateInitials}</Typography>
    </Button>
  )
}
