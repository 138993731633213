import React from 'react'
import { Typography } from '@mui/material'

const TEXT_INSUFFICIENT_DATA = 'Insufficient data'

function EmptyStateText({ alertText }) {
  const text = alertText || TEXT_INSUFFICIENT_DATA
  return (
    <Typography
      variant='h6'
      align='center'
      style={{ color: '#903246', padding: '2rem' }}
    >
      {text}
    </Typography>
  )
}

export default EmptyStateText
