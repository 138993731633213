import { createTheme } from '@mui/material/styles'

const neutralGray300 = '#F1F1F1'
const neutralGray500 = '#C6C6C6'
const neutralGray700 = '#777777'
const neutralGray900 = '#3D3D3D'
const neutralWhite = '#FFFFFF'
const orangeOrange100 = '#FF7F41'
const orangeOrange125 = '#FF651A'
const primaryColor = '#834D95'
const secondaryColor = '#FFB81C'

const theme = createTheme({
  shadows: {
    0: 'none',
    4: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    8: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    400: '0px 2px 6px rgba(61, 61, 61, 0.1), 0px 0px 4px rgba(61, 61, 61, 0.06)'
  },
  palette: {
    primary: {
      main: primaryColor
    },
    secondary: {
      main: secondaryColor
    },
    neutral: {
      white: neutralWhite,
      gray300: neutralGray300,
      gray700: neutralGray700,
      gray500: neutralGray500,
      gray900: neutralGray900
    },
    orange: {
      orange125: orangeOrange125,
      orange100: orangeOrange100
    }
  },
  typography: {
    fontFamily: ['Euclid Circular B', 'helvetica', 'arial', 'sans-serif'].join(
      ','
    ),
    color: neutralGray900,
    display: {
      fontFace: 'DM Sans',
      fontSize: '64px',
      fontWeight: 500,
      lineHeight: '72px'
    },
    display2: {
      fontFace: 'DM Sans',
      fontSize: '56px',
      fontStyle: 'normal',
      fontWeight: 'medium',
      lineHeight: '56px' /* 100% */,
      color: 'black'
    },
    body: {
      fontWeight: 400,
      fontSize: '16px'
    },
    bodyBold: {
      fontWeight: 600,
      fontSize: '16px'
    },
    headerLarge: {
      fontWeight: 600,
      fontSize: '40px'
    },
    header: {
      fontWeight: 600,
      fontSize: '24px'
    },
    subheader: {
      fontWeight: 600,
      fontSize: '21px'
    },
    subheader2: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '20px'
    },
    small: {
      fontWeight: 400,
      fontSize: '14px'
    },
    breadCrumbs: {
      fontWeight: 600,
      fontSize: '14px'
    },
    h1: {
      fontWeight: 700,
      fontSize: '6rem'
    },
    h2: {
      fontWeight: 400,
      fontSize: '3.75rem'
    },
    h3: {
      fontWeight: 400,
      fontSize: '3rem'
    },
    h4: {
      fontWeight: 400,
      fontSize: '2.125rem'
    },
    h5: {
      fontWeight: 400,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 700,
      fontSize: '1.25rem',
      lineHeight: '1.5rem'
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem'
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.875rem'
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px'
      // lineHeight: "1.5rem"
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px'
    },
    button: {
      fontWeight: 500,
      fontSize: '0.875rem'
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: '1rem'
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.625rem',
      lineHeight: '1rem'
    }
  },
  components: {
    // MuiTabs: {
    //   styleOverrides: {
    //     indicator:{
    //       backgroundColor: 'white',
    //       height: 0
    //     }
    //   }
    // },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 400,
            color: neutralGray900
          },
          fontWeight: 400,
          color: neutralGray900
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: '16px'
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 400
      },
      styleOverrides: {
        root: {
          borderRadius: '10px'
        },
        elevation10: {
          minWidth: '1160px',
          maxWidth: '1160px'
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          fontSize: '0.75rem'
        },
        rail: {
          opacity: '1',
          color: primaryColor
        },
        track: {
          color: secondaryColor
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: '0.75rem',
          fontWeight: 700,
          color: primaryColor,
          lineHeight: '0.75rem',
          padding: '1rem 0.5rem'
        },
        body: {
          padding: '0.5rem'
        }
      }
    }
  }
})

export default theme
