import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function HireStarsSlider({ sliderLabels, title, startingLevel, reset, handleResetSkills, handleUpdateSwarmData, customSliderValue }) {
    const startingValue = sliderLabels.indexOf(startingLevel)
    const customStartingValue = sliderLabels.indexOf(customSliderValue) !== -1 ? sliderLabels.indexOf(customSliderValue): startingValue
    const tickDistance = 1
    const numMarks = sliderLabels.length
    const max = numMarks-1
    const [sliderValue, setSliderValue] = useState([startingValue, customStartingValue]);

    function mappedSliderLabels() {
        let sliderLabelsObject = {}
        sliderLabels.forEach((label, index)=> {
            switch(index){
              case 0:
                return sliderLabelsObject[0]=label
              default:
                return sliderLabelsObject[(index*tickDistance)]=label
            }
        })
        return sliderLabelsObject
    }

    function sliderMarks() {
        const marks = Object.keys(mappedSliderLabels())
            return marks.map(mark => {
                return {
                  value: parseFloat(mark),
                  label: mappedSliderLabels()[mark]
                }
        })
    }

    function valuetext(value) {
        return mappedSliderLabels()[value];
    }

    const handleChange = (event, newValue, activeThumb) => {  
        let checkArrPosition = newValue.find(val => val !== startingValue)
        let customSliderNum 
        if(checkArrPosition || checkArrPosition === 0){
            customSliderNum = checkArrPosition
        } else {
            customSliderNum = startingValue
        }
        let customValues =  []
        customValues.push(title?.replace(/Slider/, '')?.trim(), sliderLabels[customSliderNum])
        handleUpdateSwarmData(customValues)

        if (!Array.isArray(newValue)) {
            return
        }
        if (!newValue.includes(startingValue)){
            return
        } else {
            setSliderValue(newValue)
        }
        
    };

    useEffect(() => {
        setSliderValue([startingValue, customStartingValue]);
        handleResetSkills(false)

      // eslint-disable-next-line
      }, [reset, startingValue]);

    return (
      <Box sx={{ width: '100%' }}>
          <Slider
              getAriaLabel={() => title }
              value={sliderValue}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              step={tickDistance}
              marks={sliderMarks()}
              valueLabelFormat={valuetext}
              max={max}
              size="small"
              color="primary"
          />
      </Box>
    );
}

export default HireStarsSlider