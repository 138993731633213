import React from 'react'
import { useLocation, Outlet } from 'react-router-dom'

import TopNav from '../pages/TopNav'
import Footer from '../pages/Footer'

import { useAuth0 } from '@auth0/auth0-react'

import Toolbar from '@mui/material/Toolbar'

function Layout() {
  const { isAuthenticated } = useAuth0()
  const { pathname } = useLocation()

  function dontShowTopNav(pathname) {
    const ignorePaths = ['/login-questionnaire', '/redirect']

    return ignorePaths.includes(pathname)
  }

  function showPageNav(pathname) {
    const ignorePaths = ['/', '/login-questionnaire', '/redirect']

    return !ignorePaths.includes(pathname)
  }

  if (dontShowTopNav(pathname)) {
    return <Outlet />
  }

  return (
    <>
      <TopNav showPageNav={showPageNav(pathname)} />
      <Toolbar />
      {isAuthenticated && showPageNav(pathname) && <Toolbar />}
      <Outlet />
      <Footer />
    </>
  )
}

export default Layout
