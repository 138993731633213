import React from 'react'
import { ResponsiveRadar } from '@nivo/radar'
import { formattedLeftTickLabel } from '../../util/formattedLabels'
import { Grid, Paper, Typography } from '@mui/material'
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

const PathwaysRadar = ({ data }) => {
    function CustomLabel(props){
        return ( 
        <text {...props} textAnchor={props.anchor} style={{textTransform:"capitalize"}}>
            {formattedLeftTickLabel(props.id, 12, props.x, props.y, 10)}
        </text>
        )
    }

    function customTooltip(node){
        function findSkillLevel(skillTitle, key){
            return data.data.find(obj => obj.skill === skillTitle)[key]
        }

        return (
            <Paper elevation={1} style={{padding: '0.5rem', borderRadius:"4px", maxWidth:"250px"}}>
                <Typography variant="subtitle2" style={{fontWeight: 700, marginBottom:"0.25rem", textTransform:"capitalize"}}>
                        {node.index}
                </Typography>
                    {
                        node.data.map((radarObj, index) => {
                            return(
                                <React.Fragment key={"Skill-fragment-"+index} >
                                    <Grid container key={"Skill-grid-"+index} >
                                        <Grid item alignItems="center">
                                            <SquareRoundedIcon style={{color: radarObj.color, fontSize:"1rem", marginBottom: "-0.2rem"}} />
                                            <Typography display="inline" style={{paddingLeft:"0.25rem", fontWeight: 400, fontSize:"0.75rem"}}>{radarObj.id}</Typography>
                                        </Grid>   
                                    </Grid>
                                    <Typography display="inline" style={{paddingLeft:"1.25rem", fontWeight: 700, fontSize:"0.75rem"}}>{findSkillLevel(node.index, radarObj.id+"_skill_level")}</Typography>
                                </React.Fragment>
                            )
                        })
                    }
            </Paper>
        )
    }

    return (<ResponsiveRadar
        data={data.data}
        keys={data.keys}
        indexBy="skill"
        margin={{ top: 30, right: 80, bottom: 10, left: 80 }}
        borderColor={{ from: 'color' }}
        gridLabelOffset={16}
        gridLevels={6}
        gridLabel={props => CustomLabel(props) }
        gridShape="linear"
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        colors={['#8C4799', '#FFB81C']}
        blendMode="multiply"
        motionConfig="wobbly"
        sliceTooltip={node => customTooltip(node)}
    />
    )
}

export default PathwaysRadar