import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import LinearProgress from '@mui/material/LinearProgress'
import EmptyStateText from '../EmptyStateText'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import CustomLegend from '../CustomLegend'

function createData(jobTitle, openRole, employers, infoText) {
  return { jobTitle, openRole, employers, infoText }
}

export default function PathwaysTable({
  tableData,
  submittedSearchTerm,
  tableLoading,
  jobSearchLoading
}) {
  const rows = tableData.map((rowElement) => {
    let infoText = (
      <div>
        {rowElement.employers.map((employer) => {
          return (
            <React.Fragment key={employer + 'tooltip'}>
              {employer} <br />
            </React.Fragment>
          )
        })}
      </div>
    )
    return createData(
      rowElement.job_title,
      rowElement.open_roles.toLocaleString('en-US'),
      rowElement.employers,
      infoText
    )
  })

  return (
    <TableContainer component={Paper}>
      <Box flexDirection='column' sx={{ padding: '1rem' }}>
        <Box sx={{ paddingY: '0.5rem' }}>
          <Typography variant='subheader'>
            What are alternative job titles for {submittedSearchTerm}?
          </Typography>
        </Box>

        <Box>
          <Typography variant='subheader2' style={{ paddingTop: '0.5rem' }}>
            Below are the top titles used by employers that currently have the
            most job openings in your region.
          </Typography>
        </Box>
      </Box>

      {tableData.length > 0 && (
        <Table aria-label='Alternate Job Titles Table'>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textTransform: 'uppercase',
                  width: '35%',
                  paddingLeft: '1rem'
                }}
              >
                Job Title
              </TableCell>
              <TableCell
                style={{
                  textTransform: 'uppercase',
                  width: '25%',
                  paddingLeft: '1rem',
                  paddingRight: '1rem'
                }}
              >
                #Open Roles
              </TableCell>
              <TableCell
                style={{
                  textTransform: 'uppercase',
                  width: '40%',
                  paddingRight: '1rem'
                }}
              >
                Employers
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!(tableLoading || jobSearchLoading) && (
              <>
                {rows.map((row, index) => (
                  <TableRow key={row.jobTitle + index}>
                    <TableCell
                      component='th'
                      scope='row'
                      style={{ paddingLeft: '1rem' }}
                    >
                      {row.jobTitle}
                    </TableCell>
                    <TableCell style={{ paddingLeft: '1rem' }}>
                      {row.openRole}
                    </TableCell>
                    <TableCell style={{ paddingRight: '1rem' }}>
                      {row.employers.length > 3 ? (
                        <Grid container alignItems='center'>
                          <Grid item xs={10}>
                            {row.employers
                              .slice(0, 3)
                              .map((employer, index) => {
                                return (
                                  <Typography
                                    key={employer + index}
                                    variant='body'
                                    display='block'
                                  >
                                    {employer}
                                  </Typography>
                                )
                              })}
                          </Grid>
                          <Grid item xs={2} style={{ paddingLeft: '.25rem' }}>
                            <Tooltip
                              data-html='true'
                              title={row.infoText}
                              placement='top'
                              arrow
                            >
                              <InfoIcon
                                color='primary'
                                fontSize='small'
                                style={{ opacity: '0.4' }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ) : (
                        row.employers.map((employer, index) => {
                          return (
                            <Typography
                              key={employer + index}
                              variant='body'
                              display='block'
                            >
                              {employer}
                            </Typography>
                          )
                        })
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      )}
      {(tableLoading || jobSearchLoading) && (
        <div style={{ height: '20rem' }}>
          <LinearProgress color='primary' />
        </div>
      )}
      {!(tableData.length > 0) && !(tableLoading || jobSearchLoading) && (
        <div style={{ height: '20rem' }}>
          <EmptyStateText />
        </div>
      )}
      <div style={{ padding: '1rem' }}>
        <CustomLegend legendKeys={[]} chartId='2.2' />
      </div>
    </TableContainer>
  )
}
