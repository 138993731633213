import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import {
  Avatar,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Box,
  Typography
} from '@mui/material'
import Container from '@mui/material/Container'
import Footer from '../components/Footer'
import { ReactComponent as HeaderImage } from '../assets/images/pathways_header.svg'
import { ReactComponent as RemoveDegreeLogo } from '../assets/images/remove_degree_logo.svg'
import { ReactComponent as BuildCultureLogo } from '../assets/images/build_culture_logo.svg'
import { ReactComponent as CreatePathwaysLogo } from '../assets/images/create_pathways_logo.svg'
import { ReactComponent as EngageLocalsLogo } from '../assets/images/engage_locals_logo.svg'
import { ReactComponent as PlayButton } from '../assets/images/purple_play.svg'
import CustomLegend from '../components/CustomLegend'
import EmptyStateText from '../components/EmptyStateText'
import quoteImage from '../assets/images/quote_image_giancarlo.png'
import PathwaysBar from '../components/pathways/PathwaysBar'
import PathwaysTable from '../components/pathways/PathwaysTable'
import PathwaysSankey from '../components/pathways/PathwaysSankey'
import PathwaysRadar from '../components/pathways/PathwaysRadar'
import JobSearchSection from '../components/JobSearchSection'
import ResultShare from '../components/ResultShare'
import {
  pathwaysBarLegendKeys,
  pathwaysSankeyLegendKeys
} from '../util/legendKeys'
import { formattedNumberLabel } from '../util/formattedLabels'
import { useDocumentTitle } from '../hooks/useDocumentTitle'
import {
  valueOrPlaceholder,
  buildParamHashLink,
  createSearchParam,
  emitChangeJobComparisonEvent,
  regionNameFormatting
} from '../util/util'
import {
  DOWNLOAD_CLASS_NO_DOWNLOAD,
  DOWNLOAD_ID
} from '../constants/globalKeys'

function Pathways({
  msaSelectedRegion,
  jobSearchLoading,
  jobOptions,
  jobSelection,
  handleJobSelection
}) {
  useDocumentTitle('Compare Skills Between Jobs - StarSight')

  const theme = useTheme()

  const regionNameString = () => {
    let regionString = regionNameFormatting(
      msaSelectedRegion?.region_name,
      msaSelectedRegion?.region_code,
      msaSelectedRegion?.geo_type_id
    )

    if (regionString === 'United States') {
      return 'US'
    }

    return regionString
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const jobOptionTitles = jobOptions?.map((job) => job.job_title) ?? []
  const jobSelectionTitle = jobSelection?.job_title
  const [barRolesArray, setBarRolesArray] = useState([])
  const [alternativeJobsArray, setAlternativeJobsArray] = useState([])
  const [possiblePathwaysArray, setPossiblePathwaysArray] = useState({
    links: [],
    nodes: []
  })
  const [radarArray, setRadarArray] = useState({
    keys: [],
    data: []
  })
  const [selectedSankey, setSelectedSankey] = useState([])
  const [pathwaySource, setPathwaySource] = useState({})
  const [pathwayTarget, setPathwayTarget] = useState({})
  const [loading, setLoading] = useState({
    pathwaysBarLoading: true,
    pathwaysTableLoading: true,
    pathwaysSankeyLoading: true,
    pathwaysRadarLoading: true
  })

  const jobType = {
    origin: 'primarily entry-level, low to middle-wage occupation',
    gateway:
      'primarily middle-wage occupation, offering further mobility pathways to higher wages',
    destination: 'middle to high-wage occupation that provides a wage gain'
  }
  const pathwayFrom = searchParams.get('pathway_from')
  const pathwayTo = searchParams.get('pathway_to')

  const api_url = process.env.REACT_APP_API_URL

  useEffect(() => {
    if (pathwaySource?.id && pathwayTarget?.id) {
      const urlParamObj = createSearchParam(
        {
          pathway_from: pathwaySource?.id,
          pathway_to: pathwayTarget?.id
        },
        ['region', 'geo_type_id', 'region_code', 'job_title'],
        searchParams
      )
      setSearchParams(urlParamObj)
    }
    // eslint-disable-next-line
  }, [pathwaySource, pathwayTarget])

  useEffect(() => {
    if (selectedSankey.length > 0) {
      const newParamsObj = createSearchParam(
        {},
        ['region', 'geo_type_id', 'region_code', 'job_title'],
        searchParams
      )

      const currentParamsObj = {}
      for (const [key, value] of searchParams) {
        currentParamsObj[key] = value
      }

      const hasChanged = [
        'region',
        'geo_type_id',
        'region_code',
        'job_title'
      ].some((key) => currentParamsObj[key] !== newParamsObj[key])

      if (hasChanged) {
        const updatedSearchParams = new URLSearchParams()
        Object.entries(newParamsObj).forEach(([key, value]) => {
          updatedSearchParams.set(key, value)
        })
        setSearchParams(updatedSearchParams)
      }
    }
    // eslint-disable-next-line
  }, [jobSelection])

  useEffect(() => {
    const msa_region_code = msaSelectedRegion?.region_code

    if (jobSelection?.occ_code) {
      setLoading((loading) => {
        return {
          ...loading,
          pathwaysBarLoading: true,
          pathwaysTableLoading: true,
          pathwaysSankeyLoading: true,
          pathwaysRadarLoading: true
        }
      })

      fetch(
        api_url +
          `/2.1?occ_code=${jobSelection?.occ_code}&region_code=${msa_region_code}&limit=10`
      )
        .then((resp) => resp.json())
        .then((jsonBarData) => {
          setBarRolesArray(jsonBarData.reverse())
          setLoading((loading) => {
            return { ...loading, pathwaysBarLoading: false }
          })
        })

      fetch(
        api_url +
          `/2.2?occ_code=${jobSelection?.occ_code}&region_code=${msa_region_code}&limit=5`
      )
        .then((resp) => resp.json())
        .then((jsonTableData) => {
          setAlternativeJobsArray(jsonTableData)
          setLoading((loading) => {
            return { ...loading, pathwaysTableLoading: false }
          })
        })

      fetch(
        api_url +
          `/2.3?occ_code=${jobSelection?.occ_code}&region_code=${msa_region_code}&limit=10`
      )
        .then((resp) => resp.json())
        .then((jsonData) => {
          // Handle an empty array (ex. occ_code = 8420)
          if (jsonData?.links?.length > 0 || jsonData?.nodes?.length > 0) {
            jsonData.links.forEach(
              (link) => (link.value = Math.round(link.value))
            )
            let sourceObject
            let targetObject

            if (pathwayFrom && pathwayTo) {
              sourceObject = jsonData.nodes.find(
                (nodeObject) => nodeObject?.id === pathwayFrom
              )
              targetObject = jsonData.nodes.find(
                (nodeObject) => nodeObject?.id === pathwayTo
              )
            }

            if (!sourceObject?.occ_code || !targetObject?.occ_code) {
              sourceObject = jsonData.nodes.find(
                (nodeObject) => nodeObject.id === jsonData.links[0].source
              )
              targetObject = jsonData.nodes.find(
                (nodeObject) => nodeObject.id === jsonData.links[0].target
              )
            } else {
              setSelectedSankey([sourceObject, targetObject])
            }

            setPathwaySource({
              occ_code: sourceObject.occ_code,
              label: sourceObject.id
            })
            setPathwayTarget({
              occ_code: targetObject.occ_code,
              label: targetObject.id
            })
            setPossiblePathwaysArray(jsonData)
          } else {
            setPossiblePathwaysArray({
              links: [],
              nodes: []
            })
            setRadarArray({
              keys: [],
              data: []
            })
            setLoading((loading) => {
              return { ...loading, pathwaysRadarLoading: false }
            })
          }
          setLoading((loading) => {
            return { ...loading, pathwaysSankeyLoading: false }
          })
        })
      return
    }
    // eslint-disable-next-line
  }, [api_url, jobSelection, msaSelectedRegion])

  useEffect(() => {
    if (pathwaySource?.occ_code && pathwayTarget?.occ_code) {
      fetch(
        api_url +
          '/2.4?occ_from=' +
          pathwaySource?.occ_code +
          '&occ_to=' +
          pathwayTarget?.occ_code
      )
        .then((resp) => resp.json())
        .then((jsonData) => {
          const skillData = jsonData['data']
          skillData?.forEach((skillObject) => {
            skillObject[pathwaySource?.label] =
              skillObject['occ_from_skill_score']
            skillObject[pathwayTarget?.label] =
              skillObject['occ_to_skill_score']
            delete skillObject['occ_from_skill_score']
            delete skillObject['occ_to_skill_score']
            skillObject[pathwaySource?.label + '_skill_level'] =
              skillObject['occ_from_skill_level']
            skillObject[pathwayTarget?.label + '_skill_level'] =
              skillObject['occ_to_skill_level']
            delete skillObject['occ_from_skill_level']
            delete skillObject['occ_to_skill_level']
            skillObject[pathwaySource?.label] =
              skillObject[pathwaySource?.label].toFixed(2)
            skillObject[pathwayTarget?.label] =
              skillObject[pathwayTarget?.label].toFixed(2)
          })
          jsonData['keys'] = [pathwaySource?.label, pathwayTarget?.label]
          jsonData['data'] = skillData
          setRadarArray(jsonData)
          setLoading((loading) => {
            return { ...loading, pathwaysRadarLoading: false }
          })
        })
      return
    }
  }, [api_url, pathwaySource, pathwayTarget])

  function handleSearchTermChange(event, userInput) {
    handleJobSelection(jobOptions.find((el) => el.job_title === userInput))
    setPathwaySource({})
    setPathwayTarget({})
    window.dataLayer.push({ event: 'Page Loaded' })
  }

  function handlePathwayClick(node) {
    if (node.source && node.target) {
      setPathwaySource(node.source)
      setPathwayTarget(node.target)
      emitChangeJobComparisonEvent(node, msaSelectedRegion)
      setLoading((loading) => {
        return { ...loading, pathwaysRadarLoading: true }
      })
    }
  }

  const radarKeys = radarArray.keys.map((element, index) => {
    const color = index === 0 ? 'primary' : 'secondary'
    return { keyName: element, color }
  })

  return (
    <>
      <header
        style={{
          backgroundColor: theme.palette.secondary.main,
          alignItems: 'center',
          paddingBottom: '8rem'
        }}
      >
        <Grid
          container
          sx={{ position: 'relative', width: 1160, margin: 'auto' }}
        >
          <HeaderImage style={{ position: 'absolute', right: '0' }} />
        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            width: 1160,
            margin: 'auto',
            maxWidth: 1160,
            minWidth: 1160
          }}
          sx={{
            paddingTop: 5,
            paddingBottom: 5
          }}
        >
          <Grid item xs={7}>
            <Grid item>
              <Typography variant='display2'>
                Discover skills that can transfer between jobs for{' '}
                {regionNameString()} workers
              </Typography>
            </Grid>
            <Grid item sx={{ paddingTop: 4 }}>
              <Typography variant='subheader2'>
                Explore how skills transfer between jobs and source talent from
                similarly skilled roles.
                <br />
                <br />
              </Typography>
            </Grid>
            <Grid item sx={{ paddingBottom: 4 }}>
              <Typography variant='subheader2'>
                Compare skill sets between jobs to discover pathways for
                mobility within your organization and across the region.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </header>
      <div id={DOWNLOAD_ID} style={{ margin: '-10rem 0rem 1.5rem 0rem' }}>
        <Container sx={{ width: 1160 }}>
          <JobSearchSection
            careerSearchOptions={jobOptionTitles}
            searchTerm={jobSelectionTitle}
            handleSearchTermChange={handleSearchTermChange}
            submittedSearchObj={jobSelection}
            msaSelectedRegion={msaSelectedRegion}
            jobSearchLoading={jobSearchLoading}
          />
          <section style={{ paddingTop: '2rem', paddingBottom: '4rem' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={5} justifyContent='space-between'>
                  <Grid item xs={12}>
                    <PathwaysTable
                      tableData={alternativeJobsArray}
                      submittedSearchTerm={valueOrPlaceholder(
                        jobSelection?.job_title
                      )}
                      tableLoading={loading.pathwaysTableLoading}
                      jobSearchLoading={jobSearchLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      <Grid container spacing={1} style={{ padding: '1rem' }}>
                        <Box flexDirection='column' sx={{ padding: '1rem' }}>
                          <Box sx={{ paddingY: '0.5rem' }}>
                            <Typography variant='subheader'>
                              Which roles prepare workers for{' '}
                              {valueOrPlaceholder(jobSelection?.job_title)}?
                            </Typography>
                          </Box>

                          <Box>
                            <Typography
                              variant='subheader2'
                              style={{ paddingTop: '0.5rem' }}
                            >
                              Find source jobs in order of greatest skills-match
                              starting from the top, along with the demographic
                              breakdown of STARs in this region.
                            </Typography>
                          </Box>
                        </Box>
                        <Grid
                          item
                          xs={12}
                          style={{ height: '30rem', marginTop: '-4rem' }}
                        >
                          {loading.pathwaysBarLoading || jobSearchLoading ? (
                            <div style={{ paddingTop: '4rem' }}>
                              <LinearProgress color='primary' />
                            </div>
                          ) : barRolesArray.length > 0 ? (
                            <PathwaysBar data={barRolesArray} />
                          ) : (
                            <div style={{ paddingTop: '4rem' }}>
                              <EmptyStateText />
                            </div>
                          )}
                        </Grid>
                        <Grid item xs={12} alignItems='flex-end'>
                          <CustomLegend
                            legendKeys={pathwaysBarLegendKeys}
                            chartId='2.1'
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      <Grid container spacing={1} style={{ padding: '1rem' }}>
                        <Box flexDirection='column' sx={{ padding: '1rem' }}>
                          <Box sx={{ paddingY: '0.5rem' }}>
                            <Typography variant='subheader'>
                              Which career paths are possible with skills from{' '}
                              {valueOrPlaceholder(jobSelection?.job_title)}?
                            </Typography>
                          </Box>

                          <Box sx={{ paddingTop: '0.5rem' }}>
                            <Typography variant='subheader2'>
                              This chart illustrates how skills can create job
                              mobility across common career paths. The colors
                              indicate hourly wage levels. Hover on a band to
                              see how many workers are in that job, or
                              transition into it each year.
                            </Typography>
                          </Box>
                          <Box sx={{ paddingTop: '1rem' }}>
                            <Typography variant='subheader2'>
                              Opportunity@Work defines this as{' '}
                              {jobSelection?.job_type?.toLowerCase() ===
                              'origin'
                                ? 'an'
                                : 'a'}{' '}
                              <strong
                                style={{ color: theme.palette.primary.main }}
                              >
                                {jobSelection?.job_type?.toLowerCase()}{' '}
                              </strong>
                              job, meaning it is a{' '}
                              {jobType[jobSelection?.job_type?.toLowerCase()]}.
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        justifyContent='space-between'
                        style={{ padding: '1rem' }}
                      >
                        <Grid item xs={7}>
                          <Paper
                            elevation={0}
                            style={{
                              padding: '1rem',
                              border: '#BDBDBD 1px solid'
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography variant='h6'>
                                  Hover to discover how many workers transition
                                  between each job and where wage gains are most
                                  likely.
                                </Typography>
                              </Grid>
                              <Grid item xs={12} style={{ height: '32rem' }}>
                                {loading.pathwaysSankeyLoading ||
                                jobSearchLoading ? (
                                  <LinearProgress color='primary' />
                                ) : possiblePathwaysArray.links.length > 0 &&
                                  possiblePathwaysArray.nodes.length > 0 ? (
                                  <PathwaysSankey
                                    data={possiblePathwaysArray}
                                    onPathwayClick={handlePathwayClick}
                                  />
                                ) : (
                                  <EmptyStateText />
                                )}
                              </Grid>
                              <Grid item xs={12} alignItems='flex-end'>
                                <Typography
                                  variant='body2'
                                  style={{ padding: '1rem 0rem 0.5rem 0rem' }}
                                >
                                  Hourly wage levels
                                </Typography>
                                <CustomLegend
                                  legendKeys={pathwaysSankeyLegendKeys}
                                  chartId='2.3'
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                        <Grid item xs={5}>
                          <Paper
                            elevation={0}
                            style={{
                              padding: '1rem',
                              border: '#BDBDBD 1px solid'
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item>
                                <Typography variant='h6'>
                                  To compare skills between jobs, click on a
                                  transition path in the chart to the left.
                                </Typography>
                              </Grid>
                              {loading.pathwaysRadarLoading ||
                              jobSearchLoading ? (
                                <Grid item xs={12} style={{ height: '32rem' }}>
                                  <LinearProgress color='primary' />
                                </Grid>
                              ) : (
                                <>
                                  {radarArray?.skills_match ? (
                                    <Grid
                                      item
                                      xs={12}
                                      xl={9}
                                      container
                                      alignItems='flex-start'
                                    >
                                      <Grid item xs={3}>
                                        {radarArray?.skills_match ===
                                          undefined ||
                                        isNaN(radarArray?.skills_match) ? (
                                          <Typography
                                            variant='h5'
                                            color='secondary'
                                            style={{ fontWeight: 700 }}
                                          >
                                            N/A
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant='h5'
                                            color='secondary'
                                            style={{ fontWeight: 700 }}
                                          >
                                            {Math.round(
                                              radarArray.skills_match * 100
                                            )}{' '}
                                            %
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Typography variant='body1'>
                                          Skill Match
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  ) : null}
                                  {radarArray?.total_transitions && (
                                    <Grid
                                      item
                                      xs={12}
                                      xl={9}
                                      container
                                      alignItems='flex-start'
                                    >
                                      <Grid item xs={3}>
                                        {radarArray?.total_transitions ===
                                          undefined ||
                                        isNaN(radarArray?.total_transitions) ? (
                                          <Typography
                                            variant='h5'
                                            color='secondary'
                                            style={{ fontWeight: 700 }}
                                          >
                                            N/A
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant='h5'
                                            color='secondary'
                                            style={{ fontWeight: 700 }}
                                          >
                                            {formattedNumberLabel(
                                              radarArray.total_transitions
                                            )}
                                          </Typography>
                                        )}
                                      </Grid>
                                      <Grid item xs={9}>
                                        <Typography
                                          variant='body1'
                                          align='left'
                                        >
                                          National number of workers
                                          transitioning annually
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      height: '25rem',
                                      paddingTop: '0.5rem'
                                    }}
                                  >
                                    {radarArray?.keys?.length > 0 &&
                                    radarArray?.data?.length > 0 ? (
                                      <PathwaysRadar data={radarArray} />
                                    ) : (
                                      <EmptyStateText />
                                    )}
                                  </Grid>
                                </>
                              )}
                              <Grid
                                item
                                container
                                xs={12}
                                alignItems='flex-end'
                              >
                                {radarKeys && (
                                  <CustomLegend
                                    legendKeys={radarKeys}
                                    stacked='true'
                                    chartId='2.4'
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </section>
        </Container>
      </div>
      <section style={{ padding: '0 4rem' }}>
        <Container>
          <Grid
            className={DOWNLOAD_CLASS_NO_DOWNLOAD}
            style={{
              backgroundColor: '#F4EDF5',
              margin: '-1rem 0rem 3.5rem 0rem',
              position: 'relative',
              width: 1160
            }}
          >
            <ResultShare
              fileNameParts={[
                msaSelectedRegion?.region_name,
                jobSelection?.job_title
              ]}
            />
          </Grid>
        </Container>
      </section>
      <section
        id='creating-org-pathways'
        style={{
          backgroundColor: theme.palette.secondary.main,
          padding: '2rem 0rem 4rem 0rem'
        }}
      >
        <Typography
          align='center'
          variant='h5'
          style={{ fontWeight: 700, padding: '2rem 0rem' }}
        >
          Creating pathways for STARs in your organization
        </Typography>
        <Container>
          <Grid container sx={{ width: 1160 }}>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <Paper style={{ padding: '1rem', minWidth: 0 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6' color='primary'>
                        Four ways employers can take action:
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <RemoveDegreeLogo />
                      <Typography
                        variant='caption'
                        align='center'
                        display='block'
                        style={{ paddingTop: '1rem' }}
                      >
                        Remove unnecessary degree requirements and hire based on
                        skills.
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <CreatePathwaysLogo />
                      <Typography
                        variant='caption'
                        align='center'
                        display='block'
                        style={{ paddingTop: '1rem' }}
                      >
                        Develop pathways for STARs within your organization and
                        across local companies.
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <EngageLocalsLogo />
                      <Typography
                        variant='caption'
                        align='center'
                        display='block'
                        style={{ paddingTop: '1rem' }}
                      >
                        Engage with local talent developers to source and hire
                        STARs.
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <BuildCultureLogo />
                      <Typography
                        variant='caption'
                        align='center'
                        display='block'
                        style={{ paddingTop: '1rem' }}
                      >
                        Build a culture of belonging so that STARs can thrive in
                        your workplace.
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={5}>
                <Paper
                  style={{ padding: '1rem', position: 'relative', minWidth: 0 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6' color='primary'>
                        Star Story
                      </Typography>
                      <IconButton
                        onClick={() =>
                          window.open('https://youtu.be/bdUntuFQURk')
                        }
                        style={{
                          position: 'absolute',
                          top: '0.5rem',
                          right: '0.5rem'
                        }}
                      >
                        <PlayButton />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1'>
                        “I was motivated to pursue tech when I wanted to build a
                        networking platform for the National Hispanic Institute.
                        I was driven by my desire to find a means beyond my
                        financial limitations to connect young Latinx students
                        and professionals.”
                      </Typography>
                    </Grid>
                    <Grid item container alignItems='center' spacing={2}>
                      <Grid item>
                        <Avatar
                          alt="Giancarlo Martinez, click to go to Giancarlos's video, 'Skilled THrough Alternative Routes (STARs)'"
                          src={quoteImage}
                          sx={{ width: 80, height: 80 }}
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            window.open('https://youtu.be/bdUntuFQURk')
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant='caption'
                          style={{ fontWeight: 700 }}
                        >
                          Giancarlo Martinez
                        </Typography>
                        <Typography variant='caption' display='block'>
                          Senior UI Developer, Projekt202
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer
        footerText='Find the right skills for your job openings'
        buttonText='Assess your job requirements'
        buttonUrl={buildParamHashLink('/hire')}
      />
    </>
  )
}

export default Pathways
