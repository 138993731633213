import { User } from '@auth0/auth0-react'
import { Region } from 'src/interfaces/demographics'

function internalExternal(org: string) {
  let internalOrgs = [
    'blah',
    'bleh',
    'na',
    'o&w',
    'o@w',
    'oaw',
    'opportunity@work',
    'opportunityatwork',
    'test inc',
    'test user',
    'test',
    'user test'
  ]
  let processedOrg = org.toLowerCase().replace(/\s/g, '')

  return internalOrgs.includes(processedOrg) ? 'internal' : 'external'
}

function loginType(user: User) {
  return user.sub?.split('|')[0]
}

function GtmMapHover(
  clickedRegion: string,
  hoveredRegion: string,
  regionType: number,
  region_name: string
) {
  let event = {
    event: !regionType
      ? 'national_map_hover'
      : regionType === 2
      ? 'state_map_hover'
      : regionType === 1
      ? 'metro_map_hover'
      : 'county_map_hover',
    region: !regionType ? 'national' : region_name,
    clicked_region: clickedRegion ? clickedRegion : region_name || 'national',
    hovered_region: hoveredRegion
  }
  window.dataLayer.push(event)
}

function GtmMapClick(
  clickedRegion: string,
  regionType: number,
  region_name: string
) {
  let event = {
    event: !regionType
      ? 'national_map_click'
      : regionType === 2
      ? 'state_map_click'
      : regionType === 1
      ? 'metro_map_click'
      : 'county_map_click',
    region: !regionType ? 'national' : region_name,
    clicked_region: clickedRegion
  }
  window.dataLayer.push(event)
}

function GtmMapButtonClick(
  currentRegion: Region,
  selectedRegion: Region,
  region: Region
) {
  let event = {
    event:
      currentRegion?.region_code === 'national'
        ? 'national_map_filter'
        : currentRegion?.geo_type_id === 2
        ? 'state_map_filter'
        : 'metro_map_filter',
    region: currentRegion?.region_name,
    filter_level:
      selectedRegion?.region === 'msa' ? 'metro' : selectedRegion?.region,
    clicked_region: region?.region_name,
    filter_selection: selectedRegion?.region_name
  }
  window.dataLayer.push(event)
}

function GtmMapBack(currentRegion: Region, goBackRegion: Region) {
  let event = {
    event: !goBackRegion
      ? 'state_map_back'
      : currentRegion?.geo_type_id === 1
      ? 'metro_map_back'
      : 'county_map_back',
    region: currentRegion?.region_name,
    filter_level: !goBackRegion
      ? 'national'
      : goBackRegion?.geo_type_id === 1
      ? 'metro'
      : 'state',
    filter_selection: !goBackRegion ? 'national' : goBackRegion?.region_name
  }

  window.dataLayer.push(event)
}

function GtmShowStarsSwitch(toggleValue: boolean) {
  let event = {
    event: 'stars_only_toggle',
    region: 'national',
    toggle: toggleValue ? 'on' : 'off',
    toggle_selection: toggleValue ? 'off' : 'on'
  }
  window.dataLayer.push(event)
}

function GtmResetTalentPool(region: string, job: string) {
  let event = {
    event: 'reset_talent_pool',
    region,
    job
  }
  window.dataLayer.push(event)
}

function GtmPathMap(currentRegion: Region, nextRegion: Region) {
  let event = {
    event: 'path_map_click',
    region: currentRegion?.region_name || 'national',
    filter_selection: nextRegion?.region_name,
    filter_level:
      nextRegion?.region_code === 'national'
        ? 'national'
        : nextRegion?.geo_type_id === 2
        ? 'state'
        : nextRegion?.geo_type_id === 1
        ? 'metro'
        : 'county'
  }

  window.dataLayer.push(event)
}

type FilterType = 'map filter' | 'path map click' | 'map back' | 'geo search'

function GtmRegionChange(
  currentRegion: Region,
  nextRegion: Region,
  filter_type: FilterType
) {
  let event = {
    event: 'region_change',
    region: currentRegion?.region_name || 'national',
    filter_selection: nextRegion?.region_name,
    filter_level:
      nextRegion?.region_code === 'national'
        ? 'national'
        : nextRegion?.geo_type_id === 2
        ? 'state'
        : nextRegion?.geo_type_id === 1
        ? 'metro'
        : 'county',
    filter_type
  }

  window.dataLayer.push(event)
}

function topNavClick(navItem: string, loggedIn: boolean = false) {
  let event = {
    event: 'top_nav_click',
    nav_item: navItem,
    login_status: loggedIn ? 'logged-in' : 'logged-out'
  }

  window.dataLayer.push(event)
}

function footerClick(navItem: string, loggedIn: boolean = false) {
  let event = {
    event: 'footer_click',
    nav_item: navItem,
    login_status: loggedIn ? 'logged-in' : 'logged-out'
  }
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(event)
}

function GtmSearchBarFilter(currentRegion: Region, nextRegion: Region) {
  let event = {
    event: 'search_bar_filter',
    region: currentRegion?.region_name || 'national',
    filter_selection: nextRegion?.region_name,
    filter_level:
      nextRegion?.region_code === 'national'
        ? 'national'
        : nextRegion?.geo_type_id === 2
        ? 'state'
        : nextRegion?.geo_type_id === 1
        ? 'metro'
        : 'county'
  }

  window.dataLayer.push(event)
}

export {
  footerClick,
  internalExternal,
  loginType,
  GtmMapHover,
  GtmMapClick,
  topNavClick,
  GtmMapButtonClick,
  GtmMapBack,
  GtmShowStarsSwitch,
  GtmResetTalentPool,
  GtmPathMap,
  GtmRegionChange,
  GtmSearchBarFilter
}
