import { Button } from "@mui/material";

function RoundedButton(props){
    const roundedStyle = {borderRadius: '47px'}
    const styleObj = {...roundedStyle, ...props.style}
    return(
        <Button {...props} style={styleObj}  />
    )
}

export default RoundedButton