import React from 'react'
import { Toolbar, Typography, Grid, Stack, Box } from '@mui/material'
import { buildParamHashLink } from '../util/util'
import { Link, useLocation } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { topNavClick } from 'src/util/gtm_utils'
import RegionAutocomplete from 'src/components/region-autocomplete/RegionAutocomplete'

function PageNav() {
  const theme = useTheme()

  const topNavOptions = [
    {
      title: 'Explore',
      subTitle: 'Get insights into talent landscapes',
      pathName: '/find-stars',
      bold: true
    },
    {
      title: 'Compare',
      subTitle: 'Discover skills across jobs',
      pathName: '/match-skills',
      bold: true
    },
    {
      title: 'Assess',
      subTitle: 'Review your job requirements',
      pathName: '/hire',
      bold: true
    }
  ]

  function computedNavStyle(navItem) {
    let styleObj = {
      marginRight: 10
    }
    if (location.pathname === navItem.pathName) {
      return {
        ...styleObj,
        borderLeft: `4px solid ${theme.palette.orange.orange100}`
      }
    } else {
      return {
        ...styleObj,
        borderLeft: `4px solid ${theme.palette.neutral.white}`
      }
    }
  }

  function handleOnMouseOver(navItem, navId) {
    if (location.pathname === navItem.pathName) {
      return
    } else {
      const navComponent = document.getElementById(navId)
      navComponent.style.color = theme.palette.orange.orange100
    }
  }

  function handleOnMouseOut(navItem, navId) {
    if (location.pathname === navItem.pathName) {
      return
    } else {
      const navComponent = document.getElementById(navId)
      navComponent.style.color = theme.palette.neutral.white
    }
  }

  function handleOnClick(navId) {
    const navComponent = document.getElementById(navId)
    navComponent.style.color = theme.palette.neutral.white
  }

  const location = useLocation()
  const currentPath = location.pathname
  const showRegionAutocomplete = [
    '/find-stars',
    '/match-skills',
    '/hire'
  ].includes(currentPath)

  return (
    <Toolbar>
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Grid container spacing={1} sx={{ minWidth: '776px' }}>
          {topNavOptions.map((navItem, index) => {
            const preppedTitle = navItem.title
              .split(' ')
              .join('-')
              .toLowerCase()
            const navId = preppedTitle + '-nav'

            return (
              <Grid
                item
                xs={3}
                sx={{ minWidth: '246px' }}
                key={index}
                style={computedNavStyle(navItem)}
                onMouseOver={() => handleOnMouseOver(navItem, navId)}
                onMouseOut={() => handleOnMouseOut(navItem, navId)}
                onClick={() => handleOnClick(navId)}
              >
                <Link
                  to={buildParamHashLink(navItem.pathName)}
                  onClick={() => topNavClick(navItem.title.toLowerCase(), true)}
                >
                  <Stack direction='row' justifyContent='space-between'>
                    <Typography
                      variant='bodyBold'
                      gutterBottom={false}
                      sx={{
                        fontWeight: 700,
                        color: theme.palette.neutral.gray900
                      }}
                    >
                      {navItem.title}
                    </Typography>
                    <ArrowForwardIcon
                      id={navId}
                      sx={{ color: theme.palette.neutral.white }}
                    />
                  </Stack>
                  <Typography
                    variant='small'
                    gutterBottom={false}
                    sx={{
                      fontWeight: 400,
                      color: theme.palette.neutral.gray900
                    }}
                  >
                    {navItem.subTitle}
                  </Typography>
                </Link>
              </Grid>
            )
          })}{' '}
        </Grid>{' '}
        {showRegionAutocomplete && <RegionAutocomplete />}
      </Box>
    </Toolbar>
  )
}

export default PageNav
