import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import { chartOverviewObjects } from '../data/dataPrivacyPolicy'
import { useDocumentTitle } from '../hooks/useDocumentTitle'
import {
  Bullet,
  ChartObject,
  ChartOverviewObject
} from 'src/interfaces/infoPages'

const PrivacyPolicy: React.FC = () => {
  useDocumentTitle('Privacy Policy - StarSight')

  const theme = useTheme()
  const [hashId, setHashId] = useState<string>('')

  useEffect(() => {
    window.scrollTo(0, 0)
    const scrollToHashElement = () => {
      const { hash } = window.location
      const foundHashId = hash?.replace('#', '')
      if (!foundHashId) {
      } else {
        setHashId(foundHashId)
      }
    }
    scrollToHashElement()
  }, [])

  useEffect(() => {
    if (hashId) {
      const elementToScroll = document.getElementById(hashId)
      window.scrollTo({
        top: elementToScroll?.offsetTop ?? 0 - 20,
        behavior: 'smooth'
      })
    }
  }, [hashId])

  const renderBullet = (bullet: Bullet) => (
    <>
      <strong>{bullet.title}</strong> {bullet.content}
    </>
  )

  function chartOverviewElements(chartOverviewObj: ChartObject) {
    return (
      <Grid item key={chartOverviewObj.id + '-grid'}>
        <section id={chartOverviewObj.id}>
          <Typography
            variant='h6'
            fontFamily='DM Sans'
            style={{
              marginTop: '1rem',
              padding: '0.2rem 0rem',
              backgroundColor:
                hashId === chartOverviewObj.id ? 'rgba(236, 226, 240, 1)' : ''
            }}
          >
            {chartOverviewObj.chartName}
          </Typography>
          {chartOverviewObj.chartDescriptionArray.map((textObj, index) => {
            return (
              <React.Fragment
                key={chartOverviewObj?.chartName ?? '' + index + '-fragment'}
              >
                <Typography
                  variant='body1'
                  fontFamily='DM Sans'
                  style={{ paddingTop: index === 0 ? '0.25rem' : '1.5rem' }}
                >
                  {textObj.text}{' '}
                </Typography>
                {textObj.bullets && (
                  <ul style={{ margin: '0rem' }}>
                    {textObj.bullets.map((bullet, index2) => {
                      return (
                        <li
                          key={
                            chartOverviewObj.chartName +
                            '-bullet-' +
                            index +
                            '-' +
                            index2
                          }
                        >
                          <Typography fontFamily='DM Sans'>
                            {renderBullet(bullet)}
                          </Typography>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </React.Fragment>
            )
          })}
        </section>
      </Grid>
    )
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'Page Loaded' })
  }, [])

  return (
    <>
      <header style={{ backgroundColor: theme.palette.secondary.main }}>
        <Box
          sx={{
            flexDirection: 'column',
            padding: '6rem 0rem',
            maxWidth: '1176px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Typography
            variant='display2'
            style={{ padding: '1rem 0rem' }}
            paragraph={true}
          >
            Privacy Policy
          </Typography>
          <Typography variant='body'>Last updated: 12/2023</Typography>
        </Box>
      </header>
      <Box
        sx={{
          flexDirection: 'column',
          padding: '2rem 0rem',
          maxWidth: '1176px',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        {chartOverviewObjects.map(
          (pageOverviewObj: ChartOverviewObject, index: number) => {
            return (
              <React.Fragment key={'page-intro-fragment' + index}>
                <Typography
                  key={'page-intro' + (index + 1)}
                  variant='h5'
                  fontFamily='DM Sans'
                  style={{
                    paddingTop: index === 0 ? '0.5rem' : '1.5rem'
                  }}
                >
                  {pageOverviewObj.pageIntro}
                </Typography>
                {pageOverviewObj.chartObjects.map(
                  (chartOverviewObj: ChartObject) => {
                    return chartOverviewElements(chartOverviewObj)
                  }
                )}
              </React.Fragment>
            )
          }
        )}
      </Box>
    </>
  )
}

export default PrivacyPolicy
