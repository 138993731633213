import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import {
  Avatar,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  Container
} from '@mui/material'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'
import { CssVarsProvider, extendTheme } from '@mui/joy/styles'
import Autocomplete from '@mui/joy/Autocomplete'
import Footer from '../components/Footer'
import RoundedButton from '../components/RoundedButton'
import { ReactComponent as HeaderImage } from '../assets/images/hire_skills_header.svg'
import CustomLegend from '../components/CustomLegend'
import EmptyStateText from '../components/EmptyStateText'
import HireStarsTreeMap from '../components/hire-stars/HireStarsTreeMap'
import HireStarsSwarmPlot from '../components/hire-stars/HireStarsSwarmPlot'
import HireStarsSlider from '../components/hire-stars/HireStarsSlider'
import { hirestarsTreeLegendKeys } from '../util/legendKeys'
import { capitalizeFirstLetter } from '../util/formattedLabels'
import { useDocumentTitle } from '../hooks/useDocumentTitle'
import {
  valueOrPlaceholder,
  createSearchParam,
  emitUpdateTalentPoolEvent,
  regionNameFormatting
} from '../util/util'
import quoteImage from '../assets/images/quote_image_rachele.png'
import { GtmResetTalentPool } from 'src/util/gtm_utils'

import {
  NATIONAL_CODE,
  NATIONAL_NAME,
  UNIDENTIFIED_COUNTIES,
  DOWNLOAD_CLASS_NO_DOWNLOAD,
  DOWNLOAD_ID
} from '../constants/globalKeys'
import _ from 'lodash'

import JobSearchSection from '../components/JobSearchSection'
import ResultShare from '../components/ResultShare'

/** iterator used for logging fetch IDs for 3.4 POST */
let talentpool_count = 0

/**
 * Creates a new AbortController used for cancelling 3.4 POST fetching
 * @returns AbortController
 */
function newPoolAbortController() {
  const controllerForCustomPool = new AbortController()

  controllerForCustomPool.signal.addEventListener('abort', (event) => {
    console.debug(
      '[POST 3.4] aborting request!',
      controllerForCustomPool.signal.aborted,
      controllerForCustomPool.signal.reason
    )
  })

  return controllerForCustomPool
}

function HireStars({
  msaSelectedRegion,
  jobSearchLoading,
  jobOptions,
  jobSelection,
  handleJobSelection,
  msaRegion
}) {
  useDocumentTitle('Assess Your Job Requirements - StarSight')

  const theme = useTheme()

  const regionNameString = () => {
    let regionString = regionNameFormatting(
      msaSelectedRegion?.region_name,
      msaSelectedRegion?.region_code,
      msaSelectedRegion?.geo_type_id
    )

    if (regionString === 'United States') {
      return 'the US'
    }
    return `the ${regionString}`
  }

  const [searchParams, setSearchParams] = useSearchParams()
  const jobOptionTitles = jobOptions?.map((job) => job.job_title) ?? []
  const jobSelectionTitle = jobSelection?.job_title
  const [topSkills, setTopSkills] = useState([])
  const [isInitialLoad, setIsInitialLoad] = useState(true) // keeps track of initial load of page
  const [swarmDemographicsFilter, setSwarmDemographicsFilter] = useState('All')
  const [swarmCounties, setSwarmCounties] = useState([]) // sets the swarm bubble left axis
  const [skillRequirements, setSkillRequirements] = useState({
    // default initial values for sliders
    education: {},
    skills: [],
    occ_code: ''
  })
  const [resetSkills, setResetSkills] = useState(true)
  const [swarmObjects, setSwarmObjects] = useState([]) // used to display bubbles - combination of average (purple) and custom (yellow) bubbles
  const [averageSwarmObjects, setAverageSwarmObjects] = useState([]) // average (purple) bubbles
  const [customSwarmPayload, setCustomSwarmPayload] = useState({}) // payload sent in POST for 3.4
  const [displayCustomError, setDisplayCustomError] = useState(false)

  const [loading, setLoading] = useState({
    hireStarsTreeMapLoading: true,
    hireStarsSliderLoading: true,
    hireStarsSwarmPlotLoading: true,
    hireStarsSwarmPlotCustomLoading: true,
    hireStarsSwarmPlotUpdateLoading: false
  })
  const api_url = process.env.REACT_APP_API_URL
  const educationLevels = ['None', 'HS/GED', 'Associates', 'BA', 'Masters+']
  const educationLevelMappings = {
    None: 'None',
    'HS/GED': 'HS/GED', // 2
    Certification: 'HS/GED', // 2
    'Some College': 'HS/GED', // 2
    Associates: 'Associates', // 2.5
    BA: 'BA', // 3
    Masters: 'Masters+', // 3.5
    PhD: 'Masters+' // 3.5
  }
  const skillLevels = ['Entry', 'Mid', 'Advanced']
  const raceFilters = ['All', 'Asian', 'Black', 'Hispanic', 'White']

  const raceFilterFromUrl = searchParams.get('race_filter')
  const educationFromUrl = searchParams.get('education')
  const isNational = !!(
    msaSelectedRegion?.region_code?.toLowerCase() === NATIONAL_CODE
  )
  const msa_region_code = msaSelectedRegion?.region_code

  const [poolAbortController, setPoolAbortController] = useState(
    newPoolAbortController()
  )
  const prevJobRef = useRef()
  const prevRegionRef = useRef()

  useEffect(() => {
    // Compare selections to prev
    if (jobSelection?.occ_code !== prevJobRef.current) {
      poolAbortController.abort(
        `Job selection has changed: ${jobSelection?.occ_code} => ${prevJobRef.current}`
      )
    } else if (msaSelectedRegion?.region_code !== prevRegionRef.current) {
      poolAbortController.abort(
        `Region selection has changed: ${msaSelectedRegion?.region_code} => ${prevRegionRef.current}`
      )
    }

    // Apply new selections to previous state
    prevJobRef.current = jobSelection?.occ_code
    prevRegionRef.current = msaSelectedRegion?.region_code

    // reset controller
    setPoolAbortController(newPoolAbortController())

    if (jobSelection?.occ_code) {
      setLoading((loading) => {
        return {
          ...loading,
          hireStarsTreeMapLoading: true,
          hireStarsSliderLoading: true,
          hireStarsSwarmPlotLoading: true
        }
      })
      // Search top skills for selected occ
      fetch(
        api_url +
          `/3.1?occ_code=${jobSelection?.occ_code}&region_code=${msa_region_code}`
      )
        .then((resp) => resp.json())
        .then((jsonData) => {
          setTopSkills(jsonData)
          setLoading((loading) => {
            return { ...loading, hireStarsTreeMapLoading: false }
          })
        })

      fetch(
        api_url +
          `/3.3?occ_code=${jobSelection?.occ_code}&region_code=${msa_region_code}`
      )
        .then((resp) => resp.json())
        .then((jsonData) => {
          if (jsonData.length > 0) {
            setSkillRequirements(jsonData[0])
            // sets initial values from parameters or default values in json response
            const initialCustomEducation = educationLevels.includes(
              educationFromUrl
            )
              ? educationFromUrl
              : educationLevelMappings[jsonData[0]?.education.average_level]
            const initialSkillsFromUrlParams = createCustomSkills(
              jsonData[0]?.skills
            )
            console.debug('set CSP on initial 3.4 query')
            setCustomSwarmPayload({
              selected_job: jobSelection?.occ_code,
              region_code: msa_region_code,
              education: initialCustomEducation,
              skills: initialSkillsFromUrlParams
            })
          } else {
            setSkillRequirements({
              education: {},
              skills: [],
              occ_code: ''
            })
          }
          setLoading((loading) => {
            return {
              ...loading,
              hireStarsSliderLoading: false,
              hireStarsSwarmPlotCustomLoading: false
            }
          })
        })

      // GET swarm data
      fetch(
        api_url +
          `/3.4?occ_code=${jobSelection?.occ_code}&region_code=${msa_region_code}`
      )
        .then((resp) => resp.json())
        .then((jsonData) => {
          const average_num_workers = jsonData.average_number_of_workers.filter(
            (obj) => isNaN(obj.group)
          )
          const county_names = jsonData.county_names
            .sort((a, b) => {
              if (isNational) {
                if (a === NATIONAL_NAME) return -1
                if (b === NATIONAL_NAME) return 1
              }
              if (!isNational) {
                if (a.toLowerCase() === UNIDENTIFIED_COUNTIES.toLowerCase())
                  return 1
                if (b.toLowerCase() === UNIDENTIFIED_COUNTIES.toLowerCase())
                  return -1
                if (msaRegion.find((regionObj) => regionObj.region_name === a))
                  return -1
                if (msaRegion.find((regionObj) => regionObj.region_name === b))
                  return 1
              }
              return a.localeCompare(b)
            })
            .filter((countyName) => isNaN(countyName))
          const initialRaceFilter = raceFilters.includes(raceFilterFromUrl)
            ? raceFilterFromUrl
            : 'All'
          setSwarmDemographicsFilter(initialRaceFilter)
          console.debug('set SO on initial 3.4 query')
          setSwarmObjectsWithVolume(average_num_workers)
          setAverageSwarmObjects(average_num_workers)
          setSwarmCounties(county_names.map(matchToDisplayNames))
          setLoading((loading) => {
            return { ...loading, hireStarsSwarmPlotLoading: false }
          })
        })
    }

    // eslint-disable-next-line
  }, [api_url, jobSelection, msaSelectedRegion])

  // Call handleUpdateTalentPool function if there is any slider params in url
  useEffect(() => {
    if (
      isInitialLoad &&
      Object.keys(customSwarmPayload).length > 0 &&
      Object.keys(averageSwarmObjects).length > 0
    ) {
      let isCustomSkills = false
      const params = createParamsName(customSwarmPayload?.skills)
      params.forEach((skill_param) => {
        if (searchParams.get(skill_param)) {
          isCustomSkills = true
          return false
        }
      })

      if (isCustomSkills) {
        handleUpdateTalentPool(customSwarmPayload, true)
      } else {
        setLoading((loading) => {
          return { ...loading, hireStarsSwarmPlotCustomLoading: false }
        })
      }
      setIsInitialLoad(false)
    }
    // eslint-disable-next-line
  }, [averageSwarmObjects, customSwarmPayload])

  /**
   * matchToDisplayNames - helper function to map region names from 3.4 endpoint to the formats required as series labels
   * @param {*} name - region name input via 3.4 enpoint
   * @param {*} i - index number. Used because the 0 index is special in all cases
   * @returns the properly formatted region name string
   */
  function matchToDisplayNames(name, i) {
    if (msaSelectedRegion?.region_code === NATIONAL_CODE) {
      // first item in the list, when national, is already properly formatted
      // otherwise, swap in the region's short name
      return i === 0 ? `${name} Workforce` : swapInShortName(name)
    } else {
      // when not national, first item in the list is the only one that needs a swap
      return i === 0 ? swapInShortName(name) : name
    }
  }

  /**
   * swapInShortName - helper function to return region SHORT name based on its simple name as input
   * @param {*} name - string that we expect to match the name key in the region object
   * @returns region_name_short of the matched region (if any)
   */
  function swapInShortName(name) {
    const matchedRegion = msaRegion.find(
      (region) => region.region_name === name
    )
    return matchedRegion?.region_name || name
  }

  function handleSearchTermChange(event, userInput) {
    handleJobSelection(
      jobOptions.find((el) => el.job_title === userInput),
      { resetSkills: true }
    )
    window.dataLayer.push({ event: 'Page Loaded' })
  }

  function filteredSwarmDemographicsObject(demographicsArray, filterTerm) {
    const filteredArray = demographicsArray?.filter(
      (e) => e?.demographic.toLowerCase() === filterTerm?.toLowerCase()
    )

    return filteredArray.map((obj) => {
      let matchedRegion
      if (msaSelectedRegion.region_code === NATIONAL_CODE) {
        matchedRegion = msaRegion.find(
          (region) => region.region_name === obj.group
        )
      } else {
        matchedRegion = msaRegion.find(
          (region) =>
            region.region_name === obj.group &&
            region.region_code === msaSelectedRegion.region_code
        )
      }

      const mappedObj = matchedRegion
        ? {
            ...obj,
            group: matchedRegion.region_name,
            group_old: matchedRegion.name
          }
        : obj

      if (matchedRegion?.region_code === NATIONAL_CODE) {
        mappedObj.group += ' Workforce'
      }

      return mappedObj
    })
  }

  function setSwarmObjectsWithVolume(swarmArray) {
    const populationValues = swarmArray.map((el) => el.population)
    const minValue = Math.min(...populationValues)
    const maxValue = Math.max(...populationValues)
    const difference = maxValue - minValue === 0 ? 1 : maxValue - minValue
    const updatedArray = swarmArray.map((swarmObj) => {
      const volume = ((swarmObj.population - minValue) / difference) * 100
      return { ...swarmObj, volume }
    })
    setSwarmObjects(updatedArray)
  }

  function handleResetSkills(isReset) {
    setResetSkills(isReset)
    setDisplayCustomError(false)

    if (isReset) {
      setCustomSwarmPayload({
        selected_job: jobSelection?.occ_code,
        region_code: msa_region_code,
        education: _.cloneDeep(skillRequirements.education.average_level),
        skills: _.cloneDeep(skillRequirements.skills)
      })

      const urlParam = createSearchParam(
        {},
        ['region', 'geo_type_id', 'region_code', 'job_title'],
        searchParams
      )
      GtmResetTalentPool(
        msaSelectedRegion?.region_name,
        jobSelection?.job_title
      )
      setSearchParams(urlParam)
    }
  }

  function handleResetSwarm(isReset) {
    setResetSkills(isReset)
    const updatedRaceFilter = isReset ? 'All' : raceFilterFromUrl || 'All'
    setSwarmDemographicsFilter(updatedRaceFilter)
    console.debug('set SO')
    setSwarmObjectsWithVolume(averageSwarmObjects)
  }

  function handleUpdateSwarmData(data) {
    const title = data[0]
    const level = data[1]

    if (title.toLowerCase() === 'education requirements') {
      console.debug('set CSP on handleUpdateSwarmData (education)')
      setCustomSwarmPayload((prevState) => ({
        ...prevState,
        education: level
      }))
    } else {
      let updatedSkills = [...customSwarmPayload.skills]
      customSwarmPayload.skills.forEach((obj, index) => {
        if (obj.skill.toLowerCase() === title.toLowerCase()) {
          updatedSkills[index].average_level = level
        }
      })
      console.debug('set CSP on handleUpdateSwarmData (skills)')
      setCustomSwarmPayload((prevState) => ({
        ...prevState,
        skills: updatedSkills
      }))
    }
  }

  function selectedDefaultSliders(defaultSkills, customPayload) {
    // If customPayload is empty (ex while loading), Update Talent Pool is disabled
    if (Object.keys(customPayload).length === 0) {
      return true
    }

    // Checks default/custom educational slider valueand default/custom skill sliders
    // If all match, return true so Update Talent Pool is disabled
    const isEducationDefault = !!(
      defaultSkills.education.average_level === customPayload.education
    )
    const isSkillsObjDefault = _.isEqual(
      defaultSkills.skills,
      customPayload.skills
    )

    return isEducationDefault && isSkillsObjDefault
  }

  function handleUpdateTalentPool(payload, preloaded = false) {
    setDisplayCustomError(false)

    talentpool_count++
    const prefix = `[POST 3.4] [#${talentpool_count}] `

    console.debug(`${prefix}handleUpdateTalentPayload called`, payload)
    console.debug(
      `${prefix}/3.4?occ_code=${jobSelection?.occ_code}&region_code=${msa_region_code}`
    )
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      signal: poolAbortController.signal
    }

    // set swarm loading
    setLoading((loading) => {
      return {
        ...loading,
        hireStarsSwarmPlotUpdateLoading: true
      }
    })
    const full_url =
      api_url +
      `/3.4?occ_code=${jobSelection?.occ_code}&region_code=${msa_region_code}`

    fetch(full_url, requestOptions)
      .then((resp) => {
        console.debug(`${prefix} ${resp.status}`)
        return resp.json()
      })
      .then((jsonData) => {
        console.debug(`${prefix} Apply response body`)

        if (jsonData.error) {
          console.error(jsonData.error)
          console.debug('set SO', averageSwarmObjects)
          setSwarmObjectsWithVolume(averageSwarmObjects)
        } else {
          console.debug('averageSwarmObjects', averageSwarmObjects)
          console.debug(
            'set SO via handleUpdateTalentPool (no error)',
            averageSwarmObjects.concat(jsonData)
          )
          setSwarmObjectsWithVolume(
            averageSwarmObjects.concat(
              jsonData.filter((obj) => isNaN(obj.group))
            )
          )
          if (jsonData.length === 0) {
            setDisplayCustomError(true)
          }
        }

        // set swarm not loading
        setLoading((loading) => {
          return {
            ...loading,
            hireStarsSwarmPlotUpdateLoading: false
          }
        })
      })
      .catch((error) => {
        console.error(`${prefix}Broken Fetch chain`, error)
      })

    // this happens if the update talent pool button is pressed
    if (!preloaded) {
      emitUpdateTalentPoolEvent(
        payload,
        msaSelectedRegion.region_name,
        jobCodeToName(payload.selected_job)
      )
      const { skills } = payload
      const skillsParams = {}
      for (let i = 0; i < skills.length; i++) {
        skillsParams[skills[i].skill.split(' ').join('_')] =
          capitalizeFirstLetter(skills[i].average_level)
      }
      const urlParam = createSearchParam(
        { education: customSwarmPayload.education, ...skillsParams },
        ['region', 'geo_type_id', 'region_code', 'job_title', 'race_filter'],
        searchParams
      )
      console.debug('setSearchParams from handleUpdateTalentPool')
      setSearchParams(urlParam)
    }
  }

  function jobCodeToName(jobCode) {
    const foundJob = jobOptions.find((job) => job.occ_code === jobCode)
    return foundJob.job_title
  }

  function handleChangeSwarmFilter(e, userInput) {
    setSwarmDemographicsFilter(userInput)
    const paramList = createParamsName(customSwarmPayload.skills)
    const urlParam = createSearchParam(
      { race_filter: userInput },
      [
        'region',
        'geo_type_id',
        'region_code',
        'job_title',
        'education',
        ...paramList
      ],
      searchParams
    )
    console.debug('setSearchParams from handleChangeSwarmFilter')
    let defaultDataLayer = {
      event: 'race_filter_selection',
      job: jobSelectionTitle,
      race_filter_selection: userInput,
      region: msaSelectedRegion.region_name_long,
      talent_pool_status: 'unmodified'
    }
    let modifiedDataLayer = selectedDefaultSliders(
      skillRequirements,
      customSwarmPayload
    )
      ? {}
      : {
          talent_pool_status: 'modified',
          skills: customSwarmPayload.skills,
          education: customSwarmPayload.education
        }
    window.dataLayer.push({ ...defaultDataLayer, ...modifiedDataLayer })
    setSearchParams(urlParam)
  }

  // Create skill params name dynamically
  function createParamsName(skills) {
    const paramsList = []
    for (let i = 0; i < skills.length; i++) {
      paramsList.push(skills[i].skill.split(' ').join('_'))
    }
    return paramsList
  }

  function createCustomSkills(averageSkills) {
    const customSkills = []
    averageSkills.forEach((skillObj) => {
      const customSkill = {
        ...skillObj,
        average_level:
          searchParams
            .get(skillObj.skill.split(' ').join('_'))
            ?.toLowerCase() || skillObj.average_level
      }
      customSkills.push(customSkill)
    })
    return customSkills
  }

  const joyType = {
    'title-lg': theme.typography.subheader,
    'title-md': theme.typography.subheader,
    'title-sm': theme.typography.subheader
  }

  const joyTheme = extendTheme({
    fontFamily: {
      display: theme.typography.fontFamily,
      body: theme.typography.fontFamily
    },
    typography: { ...joyType, ...theme.typography }
  })

  return (
    <>
      <header
        style={{
          backgroundColor: theme.palette.secondary.main,
          alignItems: 'center',
          paddingBottom: '8rem'
        }}
      >
        <Grid
          container
          sx={{ position: 'relative', width: 1160, margin: 'auto' }}
        >
          <HeaderImage
            style={{ position: 'absolute', right: '0', overflow: 'visible' }}
          />
        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            width: 1160,
            margin: 'auto',
            maxWidth: 1160,
            minWidth: 1160
          }}
          sx={{
            paddingTop: 5,
            paddingbottom: 5
          }}
        >
          <Grid item xs={7}>
            <Grid item>
              <Typography variant='display2'>
                {`  Assess your job requirements for ${regionNameString()} ${
                  msaSelectedRegion?.geo_type_id === 1 &&
                  msaSelectedRegion?.region_code !== 'national'
                    ? 'Metro Area'
                    : ''
                }`}
              </Typography>
            </Grid>
            <Grid item sx={{ paddingTop: 4, paddingBottom: 4 }}>
              <Typography variant='subheader2'>
                Understand the impact job requirements have on the size of the
                potential talent pool of skilled workers for the roles you need
                to fill.
                <br />
                <br />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </header>
      <div id={DOWNLOAD_ID} style={{ margin: '-8rem 0rem 1.5rem 0rem' }}>
        <Container sx={{ width: 1160 }}>
          <JobSearchSection
            careerSearchOptions={jobOptionTitles}
            searchTerm={jobSelectionTitle}
            handleSearchTermChange={handleSearchTermChange}
            submittedSearchObj={jobSelection}
            msaSelectedRegion={msaSelectedRegion}
            jobSearchLoading={jobSearchLoading}
          />
          <section style={{ paddingTop: '2rem', paddingBottom: '4rem' }}>
            <Grid container spacing={5} justifyContent='space-between'>
              <Grid item xs={12}>
                <Paper style={{ padding: '1rem' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography display='inline' variant='h6'>
                        How do job requirements for{' '}
                        {valueOrPlaceholder(jobSelection?.job_title)} impact the
                        available talent pool?
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant='body1'
                        style={{ paddingBottom: '1.5rem' }}
                      >
                        Update the education and skill levels on the left to see
                        how the pool of qualified workers grows or shrinks on
                        the right. Default values are based on historic average
                        requirements for this job.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} justifyContent='space-between'>
                    <Grid item xs={5}>
                      <Paper
                        elevation={0}
                        style={{ padding: '1rem', border: '#BDBDBD 1px solid' }}
                      >
                        <Grid container spacing={1} justifyContent='center'>
                          <Grid item xs={12}>
                            <Typography variant='h6'>
                              Adjust the sliders to see how changing job
                              requirements impact the available talent.
                            </Typography>
                          </Grid>
                          {loading.hireStarsSliderLoading ||
                          jobSearchLoading ? (
                            <Grid item xs={12} style={{ height: '36rem' }}>
                              <LinearProgress color='primary' />
                            </Grid>
                          ) : (
                            <>
                              {skillRequirements?.skills?.length > 0 ? (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: '1rem' }}
                                  >
                                    <Typography
                                      color='primary'
                                      variant='subtitle2'
                                      style={{
                                        fontWeight: 700,
                                        textTransform: 'uppercase'
                                      }}
                                    >
                                      Education Requirement
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={10}
                                    style={{ padding: '0rem 1rem' }}
                                  >
                                    <HireStarsSlider
                                      sliderLabels={educationLevels}
                                      title='Education Requirements Slider'
                                      startingLevel={capitalizeFirstLetter(
                                        educationLevelMappings[
                                          skillRequirements?.education
                                            .average_level
                                        ]
                                      )}
                                      reset={resetSkills}
                                      handleResetSkills={handleResetSkills}
                                      handleUpdateSwarmData={
                                        handleUpdateSwarmData
                                      }
                                      customSliderValue={
                                        customSwarmPayload.education
                                      }
                                    />
                                  </Grid>
                                  {skillRequirements?.skills.map(
                                    (skillObj, index) => {
                                      const customSkill = searchParams.get(
                                        skillObj.skill.split(' ').join('_')
                                      )
                                      return (
                                        <React.Fragment key={index}>
                                          <Grid
                                            key={`Skill-label-${index}`}
                                            item
                                            xs={12}
                                          >
                                            <Typography
                                              key={`Skill-label-typography-${index}`}
                                              variant='subtitle2'
                                              style={{
                                                fontWeight: 700,
                                                textTransform: 'uppercase'
                                              }}
                                            >
                                              {skillObj.skill}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            key={`Skill-Slider-typography-${index}`}
                                            item
                                            xs={10}
                                            style={{ padding: '0rem 1rem' }}
                                          >
                                            <HireStarsSlider
                                              key={`Skill-Slider-${index}`}
                                              sliderLabels={skillLevels}
                                              title={`${skillObj.skill} Slider`}
                                              startingLevel={capitalizeFirstLetter(
                                                skillObj.average_level
                                              )}
                                              reset={resetSkills}
                                              handleResetSkills={
                                                handleResetSkills
                                              }
                                              handleUpdateSwarmData={
                                                handleUpdateSwarmData
                                              }
                                              customSliderValue={customSkill}
                                            />
                                          </Grid>
                                        </React.Fragment>
                                      )
                                    }
                                  )}
                                </>
                              ) : (
                                <div style={{ height: '36rem' }}>
                                  <EmptyStateText />
                                </div>
                              )}
                            </>
                          )}
                          <Grid
                            item
                            container
                            spacing={1}
                            xs={12}
                            justifyContent='space-between'
                            style={{ paddingTop: '1rem' }}
                            className={DOWNLOAD_CLASS_NO_DOWNLOAD}
                          >
                            <Grid item>
                              <Button
                                disabled={selectedDefaultSliders(
                                  skillRequirements,
                                  customSwarmPayload
                                )}
                                variant='contained'
                                color='secondary'
                                onClick={() =>
                                  handleUpdateTalentPool(customSwarmPayload)
                                }
                              >
                                Update Talent Pool
                              </Button>
                            </Grid>
                            <Grid item>
                              <RoundedButton
                                variant='outlined'
                                color='secondary'
                                style={{ color: 'black' }}
                                onClick={() => handleResetSkills(true)}
                              >
                                Reset Skills
                              </RoundedButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item xs={7}>
                      <Paper
                        elevation={0}
                        style={{ padding: '1rem', border: '#BDBDBD 1px solid' }}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              variant='h6'
                              style={{ paddingBottom: '1rem' }}
                            >
                              See how many workers meet the education and skill
                              levels set for this role and filter by desired
                              demographics.
                            </Typography>
                          </Grid>
                          {displayCustomError && (
                            <Grid
                              item
                              xs={12}
                              style={{
                                marginTop: '-1rem',
                                paddingBottom: '0.75rem'
                              }}
                            >
                              <Grid container alignItems='center'>
                                <Grid
                                  item
                                  style={{ width: '30px', paddingTop: '2px' }}
                                >
                                  <ReportProblemRoundedIcon
                                    style={{ color: '#E03C31' }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  style={{ width: 'calc(100% - 30px' }}
                                >
                                  <Typography
                                    variant='body2'
                                    style={{
                                      color: '#E03C31',
                                      fontWeight: 700
                                    }}
                                  >
                                    No similarly-skilled workers in selected
                                    region under this modified definition.
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item xs={6} style={{ zIndex: 1 }}>
                            <CssVarsProvider theme={joyTheme}>
                              <Autocomplete
                                options={[
                                  'All',
                                  'Asian',
                                  'Black',
                                  'Hispanic',
                                  'White'
                                ]}
                                id='swarm-demographics-filter'
                                clearOnEscape
                                disableClearable
                                value={swarmDemographicsFilter}
                                onChange={handleChangeSwarmFilter}
                                sx={{ '--Input-radius': '20px' }}
                              />
                            </CssVarsProvider>
                          </Grid>
                          <Grid item xs={12} style={{ height: '35rem' }}>
                            {loading.hireStarsSwarmPlotLoading ||
                            loading.hireStarsSwarmPlotCustomLoading ||
                            loading.hireStarsSwarmPlotUpdateLoading ||
                            jobSearchLoading ? (
                              <div style={{ marginTop: '1rem' }}>
                                <LinearProgress color='primary' />
                              </div>
                            ) : (
                              swarmObjects && (
                                <HireStarsSwarmPlot
                                  data={filteredSwarmDemographicsObject(
                                    swarmObjects,
                                    swarmDemographicsFilter
                                  )}
                                  reset={resetSkills}
                                  handleResetSwarm={handleResetSwarm}
                                  swarmCounties={swarmCounties}
                                />
                              )
                            )}
                          </Grid>
                          <Grid item xs={12} alignItems='flex-end'>
                            <CustomLegend
                              legendKeys={[
                                { keyName: 'Default', color: 'primary' },
                                { keyName: 'Custom', color: 'secondary' }
                              ]}
                              chartId='3.4'
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper style={{ padding: '1rem' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h6'>
                        What skills are most frequently required for{' '}
                        {valueOrPlaceholder(jobSelection?.job_title)}?
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant='body1'>
                        Larger boxes indicate greater numbers of job postings
                        that require this skill.
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant='body1'>
                        Hover to see how frequently each skill appears in job
                        postings.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ height: '25rem' }}>
                      {loading.hireStarsTreeMapLoading || jobSearchLoading ? (
                        <LinearProgress color='primary' />
                      ) : (
                        topSkills && <HireStarsTreeMap data={topSkills} />
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    alignItems='flex-end'
                    style={{ paddingTop: '1rem' }}
                  >
                    <CustomLegend
                      legendKeys={hirestarsTreeLegendKeys}
                      chartId='3.1'
                    />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </section>
        </Container>
      </div>
      <section style={{ padding: '0 4rem' }}>
        <Container>
          <Grid
            className={DOWNLOAD_CLASS_NO_DOWNLOAD}
            style={{
              backgroundColor: '#F4EDF5',
              margin: '-1rem 0rem 3.5rem 0rem',
              position: 'relative',
              width: 1160
            }}
          >
            <ResultShare
              fileNameParts={[
                msaSelectedRegion?.region_name,
                jobSelection?.job_title
              ]}
            />
          </Grid>
        </Container>
      </section>
      <section
        id='hire-stars'
        style={{
          backgroundColor: theme.palette.secondary.main,
          padding: '2rem 4rem 6rem 4rem'
        }}
      >
        <Typography
          align='center'
          variant='h5'
          style={{ fontWeight: 700, padding: '2rem 0rem' }}
        >
          Hire STARs
        </Typography>
        <Container>
          <Grid container sx={{ width: 1160 }}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Paper style={{ padding: '3rem', minWidth: 0 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h5' color='primary'>
                        Remove barriers
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant='subtitle1'
                        style={{ fontWeight: 700 }}
                      >
                        Look beyond degrees and hire for skills.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1'>
                        A growing number of companies are diversifying their
                        talent pipeline by hiring people for their potential,
                        not pedigree.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RoundedButton
                        id='learn-more-button'
                        variant='outlined'
                        color='secondary'
                        style={{ color: 'black' }}
                        href='https://opportunityatwork.org/our-solutions/stars-insights/'
                        target='_blank'
                      >
                        Learn More
                      </RoundedButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={8}>
                <Paper
                  style={{ padding: '3rem', position: 'relative', minWidth: 0 }}
                >
                  <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={12}>
                      <Typography variant='h5' color='primary'>
                        How employers are meeting goals with STARs
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid
                        container
                        direction='column'
                        alignItems='center'
                        spacing={2}
                      >
                        <Grid item>
                          <Avatar
                            alt='Rachele Zamani; Manager, Business Development Associates at Okta'
                            src={quoteImage}
                            sx={{ width: 137, height: 137 }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            align='center'
                            display='block'
                            variant='caption'
                            style={{ fontWeight: 700 }}
                          >
                            Rachele Zamani
                          </Typography>
                          <Typography
                            align='center'
                            variant='caption'
                            display='block'
                          >
                            Manager, Business Development Associates at Okta
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant='body1'>
                        “At Okta, we know that creating balanced teams is not
                        only the right thing to do, but the only way that we
                        will be successful. We’re casting a wider net when it
                        comes to recruiting and hiring to keep up with our
                        ambitious growth targets and to further diversify our
                        workforce. Hiring based on skills and potential, not a
                        college degree, is the basis of our Business Development
                        Associates program. Through this program, we’re focused
                        on hiring based on motivation, skills, and experience,
                        rather than what college someone went to.”
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer
        footerText='Find talent on Stellarworx'
        buttonText='Hire Stars'
        buttonUrl='https://stellarworx.org/auth/login'
      />
    </>
  )
}

export default HireStars
