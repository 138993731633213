import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Chip from '@mui/material/Chip'
import Avatar from '@mui/material/Avatar'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useTheme } from '@emotion/react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { topNavClick } from 'src/util/gtm_utils'

function UserProfileMenu() {
  const { user, logout } = useAuth0()
  const userDisplay = user?.given_name
    ? user.given_name
    : user?.nickname
    ? user.nickname
    : ''
  const userInitial = Array.from(userDisplay)[0].toUpperCase()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    window.dataLayer.push({
      event: 'logout'
    })
    topNavClick('logout', true)
    localStorage.removeItem('gtmUserLogin')
    localStorage.removeItem('redirect')
    logout({ returnTo: window.location.origin })
  }
  const theme = useTheme()

  return (
    <Stack direction='row' spacing={1}>
      <Chip
        label={<Typography variant='bodyBold'>Help Center</Typography>}
        component='a'
        className='chipStyle'
        href='https://starsight.zendesk.com/hc/en-us'
        target='_blank'
        rel='noopener noreferrer'
        clickable
        onClick={() => topNavClick('help center', true)}
      />
      <Chip
        label={<Typography variant='bodyBold'>Hire STARs</Typography>}
        component='a'
        className='chipStyle'
        href='https://stellarworx.org'
        target='_blank'
        rel='noopener noreferrer'
        clickable
        onClick={() => topNavClick('hire stars', true)}
      />

      <Chip
        avatar={
          <Avatar sx={{ bgcolor: theme.palette.orange.orange100 }}>
            <Typography
              variant='body2'
              sx={{ color: theme.palette.neutral.white, fontWeight: 'bold' }}
            >
              {userInitial}
            </Typography>
          </Avatar>
        }
        label={<ExpandMoreIcon />}
        variant='outlined'
        onClick={handleClick}
        clickable
        sx={{
          border: 2,
          borderColor: theme.palette.neutral.gray500,
          color: theme.palette.neutral.gray700
        }}
      />

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          sx={{ color: theme.palette.neutral.gray700 }}
          onClick={handleClose}
        >
          Profile page coming soon
        </MenuItem>
        <MenuItem component='a' onClick={handleLogout}>
          Log out
        </MenuItem>
      </Menu>
    </Stack>
  )
}

export default UserProfileMenu
