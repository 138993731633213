import React, { useEffect, useState } from 'react'
import { Divider, Grid, Link, Typography } from '@mui/material'
import { useTheme } from '@emotion/react'
import Footer from '../components/Footer'
import { ReactComponent as HeaderImage } from '../assets/images/data_header.svg'
import {
  chartOverviewObjects,
  definitionArray,
  sourceOverviewArray
} from '../data/dataGuidanceCopy'
import { useDocumentTitle } from '../hooks/useDocumentTitle'

function DataGuidance() {
  useDocumentTitle('Data Sources - StarSight')

  const theme = useTheme()
  const [hashId, setHashId] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
    const scrollToHashElement = () => {
      const { hash } = window.location
      const foundHashId = hash?.replace('#', '')
      if (!foundHashId) {
      } else {
        setHashId(foundHashId)
      }
    }
    scrollToHashElement()
  }, [])

  useEffect(() => {
    if (hashId) {
      const elementToScroll = document.getElementById(hashId)
      window.scrollTo({
        top: elementToScroll?.offsetTop ?? 0 - 20,
        behavior: 'smooth'
      })
    }
  }, [hashId])

  function sourceTable(sourceArray) {
    return (
      <React.Fragment key='source-table'>
        <Divider color='black' style={{ margin: '1rem 0rem 1rem 0rem' }} />
        {sourceArray.map((sourceObj, index) => {
          return (
            <React.Fragment key={'source-object-fragment-' + index}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Link
                    href={sourceObj.sourceLink}
                    sx={{
                      textDecoration: 'none',
                      color: 'black',

                      '&:hover': {
                        textDecoration: 'underline',
                        color: `${theme.palette.primary.main} !important`
                      }
                    }}
                  >
                    <Typography variant='subtitle2' style={{ fontWeight: 700 }}>
                      {sourceObj.sourceText}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant='body1'>
                    {sourceObj.sourceDescription}
                  </Typography>
                </Grid>
              </Grid>
              <Divider color='black' style={{ margin: '1rem 0rem' }} />
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }

  function definitionElements(definitionObj) {
    return (
      <React.Fragment key={definitionObj.term + '-fragment'}>
        <Typography
          variant='h6'
          color='primary'
          style={{ paddingTop: '0.5rem', fontWeight: 700 }}
        >
          {definitionObj.term}
        </Typography>
        {definitionObj.definition.map((textObj, index) => {
          const { text, fontStyle } = textObj
          return (
            <Typography
              variant='body1'
              style={{ paddingTop: index === 0 ? '0.25rem' : '1.5rem' }}
              sx={{ fontStyle: fontStyle }}
              key={`${text}-${index}`}
            >
              {text}
            </Typography>
          )
        })}
      </React.Fragment>
    )
  }

  function chartOverviewElements(chartOverviewObj) {
    return (
      <Grid item key={chartOverviewObj.id + '-grid'}>
        <section id={chartOverviewObj.id}>
          <Typography
            variant='h6'
            color='primary'
            style={{
              marginTop: '1rem',
              padding: '0.2rem 0rem',
              backgroundColor:
                hashId === chartOverviewObj.id ? 'rgba(236, 226, 240, 1)' : ''
            }}
          >
            {chartOverviewObj.chartName}
          </Typography>
          {chartOverviewObj.chartDescriptionArray.map((textObj, index) => {
            return (
              <React.Fragment
                key={chartOverviewObj.chartName + index + '-fragment'}
              >
                <Typography
                  variant='body1'
                  style={{ paddingTop: index === 0 ? '0.25rem' : '1.5rem' }}
                >
                  {textObj.text}
                </Typography>
                {textObj.bullets && (
                  <ul style={{ margin: '0rem' }}>
                    {textObj.bullets.map((bullet, index2) => {
                      return (
                        <li
                          key={
                            chartOverviewObj.chartName +
                            '-bullet-' +
                            index +
                            '-' +
                            index2
                          }
                        >
                          <Typography>{bullet}</Typography>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </React.Fragment>
            )
          })}
        </section>
      </Grid>
    )
  }

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'Page Loaded' })
  }, [])

  return (
    <>
      <header style={{ backgroundColor: theme.palette.secondary.main }}>
        <HeaderImage style={{ position: 'absolute', right: '0' }} />
        <Grid container spacing={2} style={{ padding: '6rem 1rem 4rem 4rem' }}>
          <Grid item xs={12}>
            <Typography variant='h4'>Data Guidance</Typography>
          </Grid>
          <Grid item xs={9} md={8} xl={6} style={{ paddingRight: '2rem' }}>
            <Typography variant='body1'>
              This tool utilizes a number of data sources to help better
              understand the opportunities available for STARs. Below we detail
              the datasets and methodologies that help shine a light on these
              critical contributors to the labor force.
            </Typography>
          </Grid>
        </Grid>
      </header>
      <Grid
        container
        spacing={2}
        style={{ padding: '4rem', marginBottom: '2rem' }}
      >
        <Grid item xs={12} sm={9} md={8} xl={6}>
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <section id='sources'>
                <Typography variant='h6' style={{ textTransform: 'uppercase' }}>
                  SOURCES
                </Typography>
                <Typography variant='body1' style={{ paddingTop: '0.5rem' }}>
                  Public use data sources include data from the US Census Bureau
                  as distributed through the Integrated Public Use Microdata
                  Series (IPUMS) as well as data from the Bureau of Labor
                  Statistics and Occupational Information Network. Private
                  datasets on employer job postings are available through
                  Lightcast™.
                </Typography>
                {sourceTable(sourceOverviewArray)}
                <Typography variant='body1'>
                  Note: In many cases, estimates provided here are based on
                  survey data. While this survey data is designed to be
                  representative of the local workforce, estimates may be less
                  reliable for smaller populations, therefore, users should
                  exercise caution when making interpretations based on detailed
                  worker subpopulations.
                </Typography>
              </section>
            </Grid>
            <Grid item>
              <section id='terms'>
                <Typography variant='h6' style={{ textTransform: 'uppercase' }}>
                  TERMS
                </Typography>
                {definitionArray.map((defObj) => {
                  return definitionElements(defObj)
                })}
              </section>
            </Grid>
            <Grid item>
              <section id='data-visualizations-overview'>
                <Typography variant='h6' style={{ textTransform: 'uppercase' }}>
                  DATA VISUALIZATIONS
                </Typography>
                {chartOverviewObjects.map((pageOverviewObj, index) => {
                  return (
                    <React.Fragment key={'page-intro-fragment' + index}>
                      <Typography
                        key={'page-intro' + (index + 1)}
                        variant='h5'
                        style={{
                          paddingTop: index === 0 ? '0.5rem' : '1.5rem'
                        }}
                      >
                        {pageOverviewObj.pageIntro}
                      </Typography>
                      {pageOverviewObj.chartObjects.map((chartOverviewObj) => {
                        return chartOverviewElements(chartOverviewObj)
                      })}
                    </React.Fragment>
                  )
                })}
              </section>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer
        footerText='Learn more about our STAR research'
        buttonText='Visit O@W'
        buttonUrl='https://opportunityatwork.org/our-solutions/stars-insights/'
      />
    </>
  )
}

export default DataGuidance
