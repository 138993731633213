import { useState, useCallback, useEffect } from 'react'
import {
  GEO_TYPE_STATE,
  GEO_TYPE_METRO_OR_NATIONAL,
  GEO_TYPE_COUNTY
} from '../constants/demographics'
import { useNavigationHistories } from 'src/providers/NavHistoriesContext'
import { GtmMapBack, GtmRegionChange } from 'src/util/gtm_utils'

const useMapHandler = (
  stateData,
  msaData,
  mapPumaData,
  countyData,
  msa_region_code,
  msaRegion,
  handleRegionChange,
  navigationHistories,
  msaSelectedRegion
) => {
  const [mapClickRegion, setMapClickRegion] = useState()
  const [selectedMapRegion, setSelectedMapRegion] = useState({})
  const [filteredDataClick, setFilteredDataClick] = useState(null)
  const [filteredDataHover, setFilteredDataHover] = useState(null)
  const [deselectState, setDeselectState] = useState(false)
  const [isSelectedState, setIsSelectedState] = useState(
    msaSelectedRegion?.region_code !== 'national'
  )

  const [isRegionMap, setIsRegionMap] = useState(
    msaSelectedRegion?.region_code !== 'national' ? true : false
  )

  useEffect(() => {
    if (msaSelectedRegion?.region_code === 'national') {
      setIsRegionMap(false)
      setIsSelectedState(false)
    }
  }, [msaSelectedRegion?.region_code])

  const { navHistoriesValue, setNavHistoriesValue } = useNavigationHistories()

  const handleResetNationalMap = () => {
    const selected_region = {
      region_name: 'National',
      region_name_long: 'National',
      geo_type_id: 1,
      region_code: 'national'
    }

    handleRegionChange(selected_region)
    handleMapClick('15', 'national', false)
    setIsRegionMap(false)
    setIsSelectedState(false)
    setFilteredDataClick(null)
    setFilteredDataHover(null)
    setDeselectState(true)
  }

  const handleOnRegionChange = useCallback(
    (value) => {
      if (!value) {
        return
      }
      var parentRegion = ''

      if (
        value.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL &&
        value.region_code !== 'national' &&
        navHistoriesValue.length === 0 &&
        mapClickRegion
      ) {
        parentRegion = mapClickRegion.region_name
        setNavHistoriesValue([...navHistoriesValue, mapClickRegion, value])
      } else {
        setNavHistoriesValue([...navHistoriesValue, value])
      }

      let data

      if (value.geo_type_id === GEO_TYPE_STATE) {
        data = stateData.find((item) => item.region_code === value.region_code)
      } else if (
        value.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL &&
        value.region_code !== 'national'
      ) {
        data = msaData.find((item) => item.region_code === value.region_code)
        data.region = 'msa'
      } else if (value.geo_type_id === GEO_TYPE_COUNTY) {
        data = countyData.find((item) => item.region_code === value.region_code)
      }
      if (data) {
        data.state_name = value.region_name_long
        data.geo_type_id = value.geo_type_id
        data.parentState =
          navHistoriesValue.length > 0
            ? navHistoriesValue[0].region_name
            : parentRegion

        setFilteredDataClick(data)

        handleRegionChange(value)
      }
    },
    [
      navHistoriesValue,
      mapClickRegion,
      setNavHistoriesValue,
      stateData,
      msaData,
      countyData,
      handleRegionChange
    ]
  )

  const handleOnHideRegionMap = useCallback(
    (deselectRegion = false) => {
      const persistedStore = navHistoriesValue
      const newHistories = deselectRegion
        ? persistedStore
        : persistedStore
            .slice(0, -1)
            .filter((item) => item?.geo_type_id !== undefined)
      const lastHistory = newHistories.at(-1)
      const shouldUpdateParams =
        !persistedStore.some((item) => item?.geo_type_id === undefined) &&
        !deselectRegion
      if (lastHistory || deselectRegion) {
        let data
        if (lastHistory.geo_type_id === GEO_TYPE_STATE) {
          setDeselectState(true)
          data = stateData?.find(
            (item) => item.region_code === lastHistory.region_code
          )
          setIsRegionMap(true)
          setMapClickRegion(newHistories[0])
        } else if (
          lastHistory.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL &&
          lastHistory.region_code !== 'national'
        ) {
          setDeselectState(true)
          data = msaData?.find(
            (item) => item.region_code === lastHistory.region_code
          )
        } else if (lastHistory.geo_type_id === GEO_TYPE_COUNTY) {
          setDeselectState(true)
          data = countyData?.find(
            (item) =>
              Number(item.region_code) === Number(lastHistory.region_code)
          )
        }
        if (data) {
          data.state_name = lastHistory.region_name_long
          data.geo_type_id = lastHistory.geo_type_id
          data.parentState = navHistoriesValue[0].region_name
          setFilteredDataClick(data)
          handleRegionChange(lastHistory, shouldUpdateParams)
        }
      } else {
        handleResetNationalMap()
      }
      const currentRegion = persistedStore.at(-1)
      const backToRegion = persistedStore.at(-2) || null
      if (currentRegion && shouldUpdateParams) {
        GtmMapBack(currentRegion, backToRegion)
        GtmRegionChange(currentRegion, backToRegion, 'map back')
      }
      setNavHistoriesValue(newHistories)
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      navHistoriesValue,
      setNavHistoriesValue,
      stateData,
      msaData,
      countyData,
      handleRegionChange
    ]
  )

  const data =
    msaSelectedRegion?.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL ||
    msaSelectedRegion?.geo_type_id === GEO_TYPE_COUNTY
      ? mapPumaData
      : msaSelectedRegion?.geo_type_id === GEO_TYPE_STATE
      ? msaData
      : null

  const handleMapClick = useCallback(
    (geoId, state_name, selectedState) => {
      try {
        setIsSelectedState(selectedState)

        if (!selectedState) {
          return
        }
        const normalizedGeoId = Number(geoId) || geoId || 'national'

        let filteredObject
        setMapClickRegion(
          msaRegion?.find(
            (region) =>
              region.region_code.toString() === normalizedGeoId.toString()
          )
        )
        if (msa_region_code === 'national') {
          filteredObject = stateData.find((item) => {
            if (item.GEOID === 'national') {
              return false
            }
            return Number(item.GEOID) === Number(geoId)
          })
        } else {
          if (
            msaSelectedRegion?.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL ||
            msaSelectedRegion?.geo_type_id === GEO_TYPE_COUNTY
          ) {
            filteredObject = data?.find(
              (item) => Number(item.GEOID) === Number(geoId)
            )
          } else {
            filteredObject = data?.find((item) => item.region_code === geoId)
          }
          if (filteredObject) {
            const selected_region = {
              state_name: filteredObject?.state_name
            }
            handleOnRegionChange(selected_region)
          }
        }
        if (filteredObject) {
          filteredObject.state_name = state_name
          setFilteredDataClick(filteredObject)
        } else {
          handleOnHideRegionMap(true)
          return
        }

        setDeselectState(false)
      } catch (error) {
        console.error('An error occurred:', error)
      }
    },
    [
      msaRegion,
      msa_region_code,
      stateData,
      msaSelectedRegion?.geo_type_id,
      data,
      handleOnRegionChange,
      handleOnHideRegionMap
    ]
  )

  const handleMapHover = useCallback(
    (geoId, state_name) => {
      if (!geoId) {
        return
      }
      let filteredObject
      if (msa_region_code === 'national') {
        filteredObject = stateData.find((item) => {
          if (item.GEOID === 'national') {
            return false
          }
          return Number(item.GEOID) === Number(geoId)
        })
      } else if (
        msaSelectedRegion?.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL ||
        msaSelectedRegion?.geo_type_id === GEO_TYPE_COUNTY
      ) {
        //msa map
        filteredObject = data?.find(
          (item) => Number(item.GEOID) === Number(geoId)
        )
      } else {
        //state map
        filteredObject = data?.find((item) => item.region_code === geoId)
      }
      if (!filteredObject || filteredDataHover?.state_name === state_name) {
        return
      }
      filteredObject.state_name = state_name
      setFilteredDataHover(filteredObject)
    },
    [
      msa_region_code,
      msaSelectedRegion?.geo_type_id,
      filteredDataHover?.state_name,
      stateData,
      data
    ]
  )

  function handleMouseLeave() {
    setFilteredDataHover(null)
  }

  const handleOnShowRegionMap = (regionCode, geoTypeId) => {
    if (regionCode !== 'national') {
      setIsRegionMap(true)
    } else {
      setIsRegionMap(false)
    }
  }

  return {
    mapClickRegion,
    isSelectedState,
    filteredDataClick,
    filteredDataHover,
    deselectState,
    isRegionMap,
    selectedMapRegion,
    handleMapClick,
    handleMapHover,
    handleMouseLeave,
    handleOnHideRegionMap,
    handleOnRegionChange,
    handleOnShowRegionMap,
    setSelectedMapRegion
  }
}

export { useMapHandler }
