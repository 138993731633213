import { useEffect, useState } from 'react'

export function useQueryApi({
  endpoint,
  enable = true,
  callbackFn,
  dependency
}) {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(
    function callApi() {
      // Función para realizar la solicitud
      const fetchData = () => {
        setIsLoading(true)
        fetch(endpoint)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            return response.json()
          })
          .then((data) => {
            if (callbackFn) {
              callbackFn(data)
            }
            setData(data)
          })
          .catch((error) => {
            console.error('Data fetching error:', error)
            setData(null)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }

      if (enable) {
        fetchData()
      }
    },
    [enable, endpoint, callbackFn, dependency]
  )

  return { data, isLoading }
}
