import { ResponsiveSankey } from '@nivo/sankey'
import { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from "@mui/material"
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { formattedNumberLabel, formattedPercent } from '../../util/formattedLabels'

const PathwaysSankey = ({ data, onPathwayClick }) => {
    const [sortedData, setSortedData] = useState([])

    // TODO: Replace check for safari with feature detection
    const isSafari = !!(navigator.vendor ===  "Apple Computer, Inc.")

    useEffect(() => {
        //Size of node determined by link value. In order to sort nodes, must sort by associated link's value.
        function getLinkage(link, id) {
            return link.source === id || link.target === id
        }

        const sortedNodes = data.nodes.sort((a, b) => {
            const foundLinkageA = data.links.find(link => {
                return getLinkage(link, a.id)
            })
            const foundLinkageB = data.links.find(link => {
                return getLinkage(link, b.id)
            })
            return foundLinkageA.value > foundLinkageB.value ? -1 : 1
        })

        const sortedLinks = data.links.sort((a, b) => a.value > b.value ? -1 : 1)
        const updatedData = { ...data, links: sortedLinks, nodes: sortedNodes }
        setSortedData(updatedData)

    }, [data])

    const sourceNodes = data.nodes.filter(node => node.node_type.toLowerCase() === "origin")
    const destinationNodes = data.nodes.filter(node => node.node_type.toLowerCase() === "destination")

    const mappedColorObj = {
        low: "rgba(227, 228, 141, 1)",
        medium: "rgba(108, 194, 74, 1)",
        upper: "rgba(66, 152, 181, 1)",
        high: "rgba(29, 66, 137, 1)",
    }

    function customNodeToolTip(sankeyNode) {
        return (
            <Paper elevation={1} style={{ padding: '0.5rem', borderRadius: "4px" }}>
                <Grid container alignItems="center">
                    <Grid item>
                        <SquareRoundedIcon style={{ color: mappedColorObj[sankeyNode.node.wage_group.toLowerCase()], fontSize: "1rem", marginBottom: "-0.2rem" }} />
                        <Typography variant="subtitle2" display="inline" style={{ fontWeight: 700, paddingLeft: "0.25rem" }}>{sankeyNode.node.id}</Typography>
                    </Grid>
                </Grid>
                <Typography style={{ paddingLeft: "1.25rem", fontSize: "0.75rem" }}>{formattedNumberLabel(sankeyNode.node.total_workers)} Total Workers</Typography>
                <Typography display="block" style={{ paddingLeft: "1.25rem", fontSize: "0.75rem" }}>{formattedPercent(sankeyNode.node.percent_stars)} STARs</Typography>
            </Paper>
        )
    }

    function customLinkToolTip(sankeyLink) {
        return (
            <Paper elevation={1} style={{ padding: '0.5rem', borderRadius: "4px" }}>
                <Grid container alignItems="center">
                    <Grid item>
                        <SquareRoundedIcon style={{ color: mappedColorObj[sankeyLink.link.source.wage_group.toLowerCase()], fontSize: "1rem", marginBottom: "-0.2rem" }} />
                        <Typography variant="subtitle2" display="inline" style={{ fontWeight: 700, padding: "0rem 0.25rem" }}>{sankeyLink.link.source.id + " > "}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" display="inline" style={{ fontWeight: 700, paddingRight: "0.25rem" }}>{sankeyLink.link.target.id}</Typography>
                        <SquareRoundedIcon style={{ color: mappedColorObj[sankeyLink.link.target.wage_group.toLowerCase()], fontSize: "1rem", marginBottom: "-0.2rem" }} />
                    </Grid>
                </Grid>
                <Typography style={{ paddingLeft: "1.25rem", fontSize: "0.75rem" }}>{formattedNumberLabel(sankeyLink.link.value)} Transitions Nationally</Typography>
            </Paper>
        )
    }

    return (
        <>
            <Grid container justifyContent="space-between" style={{ padding: '20px 30px 0px 30px' }}>
                {
                    sourceNodes.length > 0 &&
                    <Grid item>
                        <Typography style={{ fontSize: "1rem", fontWeight: "700" }}>Sourcing Job</Typography>
                    </Grid>
                }
                <Grid item>
                    <Typography style={{ textTransform: "uppercase", fontSize: "1rem", fontWeight: "700" }}>SELECTED JOB</Typography>
                </Grid>
                {
                    destinationNodes.length > 0 &&
                    <Grid item>
                        <Typography style={{ fontSize: "1rem", fontWeight: "700" }}>Destination Job</Typography>
                    </Grid>
                }
            </Grid>
            <ResponsiveSankey
                data={sortedData}
                margin={{ top: 10, right: 30, bottom: 60, left: 30 }}
                align="justify"
                colors={node => mappedColorObj[node.wage_group.toLowerCase()]}
                colorBy={() => 1}
                nodeOpacity={1}
                nodeHoverOthersOpacity={0.35}
                nodeThickness={18}
                nodeSpacing={16}
                nodeBorderWidth={0}
                enableLabels={false}
                nodeTooltip={(sankeyNode) => customNodeToolTip(sankeyNode)}
                linkTooltip={(sankeyLink) => customLinkToolTip(sankeyLink)}
                nodeBorderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.8
                        ]
                    ]
                }}
                nodeBorderRadius={3}
                linkOpacity={0.5}
                linkHoverOthersOpacity={0.1}
                linkContract={0}
                enableLinkGradient={!isSafari}
                sort="input"
                onClick={onPathwayClick}
            />
        </>
    )
}

export default PathwaysSankey