function formattedNumberLabel(number, precision) {
  // TODO: Update function to use a formatter

  let impreciseNumber = Math.round(number)
  let label = ''
  switch (true) {
    case number >= 1000000:
      impreciseNumber = Math.round(number / 10000) / 100
      label = 'M'
      break
    case number >= 10000:
      impreciseNumber = Math.round(number / 1000)
      label = 'K'
      break
    case number >= 1000:
      impreciseNumber = Math.round(number / 100) / 10
      label = 'K'
      break
    default:
      break
  }

  let finalNumber = precision
    ? parseFloat(impreciseNumber.toFixed(precision))
    : impreciseNumber

  return label ? `${finalNumber} ${label}` : `${finalNumber}`
}

function formattedLeftTickLabel(
  text,
  stringLength = 20,
  x = 0,
  y = 0,
  fontSize = 12
) {
  const colorHex = '#666666'

  if (text.length > stringLength) {
    let wordArray
    if (text.includes('/')) {
      // splits on / and adds in to each word in array except for last
      // may need to refactor to also split on spaces if there are long examples
      // note: look behind is not supported in safari
      wordArray = text.split(/\//)
      wordArray = wordArray.map((word, index) => {
        if (index !== wordArray.length - 1) {
          return word + '/'
        }
        return word
      })
    } else {
      wordArray = text.split(/(?= )/g)
    }
    let line1 = ''
    let line2 = ''
    let completed = false

    wordArray.forEach((word, index) => {
      if (!completed) {
        if (index === 0 && word.length > stringLength) {
          line1 = word.substring(0, stringLength) + '...'
          completed = true
        } else if (line1.length + word.length <= stringLength && line2 === '') {
          line1 += word
        } else if (line2.length + word.length <= stringLength) {
          line2 += word
        } else if (line2.length + word.length >= stringLength) {
          line2 += word
          line2 = line2.substring(0, stringLength) + '...'
          completed = true
        }
      }
    })

    return (
      <>
        <tspan
          x={x}
          y={y - 7}
          fontSize={fontSize}
          fontFamily='DM Sans'
          fill={colorHex}
        >
          {line1}
        </tspan>
        {line2 && (
          <tspan
            x={x}
            y={y + 7}
            fontSize={fontSize}
            fontFamily='DM Sans'
            fill={colorHex}
          >
            {line2}
          </tspan>
        )}
      </>
    )
  }
  return (
    <tspan fontSize={fontSize} fontFamily='DM Sans' fill={colorHex}>
      {text}
    </tspan>
  )
}

function capitalizeFirstLetter(text) {
  return text.replace(/\b\w/g, (letter) => letter.toUpperCase())
}

function formattedPercent(number) {
  return `${Math.round(number * 100)}%`
}

function formattedNumberhundredThousandsFloor(number) {
  // 1899000 -> 1.8 million | 789000 -> 700,000 | 87630.3 -> 87,630
  const hundredThousandsFloor = Math.floor(number / 100000) * 100000
  if (number > 1000000) {
    return hundredThousandsFloor / 1000000 + ' million'
  } else if (number > 100000) {
    return hundredThousandsFloor.toLocaleString()
  }
  return Math.floor(number).toLocaleString()
}

const truncateStateName = (name) => {
  const separators = [',']
  for (let sep of separators) {
    if (name.includes(sep)) {
      return name.split(sep)[0].trim()
    }
  }
  return name
}

function truncateName(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...'
  } else {
    return str
  }
}

export {
  formattedNumberhundredThousandsFloor,
  formattedNumberLabel,
  formattedLeftTickLabel,
  formattedPercent,
  capitalizeFirstLetter,
  truncateStateName,
  truncateName
}
