import React, { useState, useEffect, useMemo, useRef } from 'react'
import { MapContainer, GeoJSON, Marker, Polyline } from 'react-leaflet'
import debounce from 'lodash/debounce'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css'
import 'leaflet-defaulticon-compatibility'

import {
  ACRONYM,
  STATEINITIALS,
  MARKERS,
  mapColorsScale
} from '../../constants/demographics'
import { GtmMapHover, GtmMapClick } from 'src/util/gtm_utils'
import dataJson from '../../data/geoJson.json'
import '../../styles/map.css'

const mapStyle = {
  backgroundColor: 'transparent'
}

const stateCoordinates = {
  Alabama: [32.806671, -86.79113],
  Alaska: [48.583554, -126.592291],
  California: [36.7783, -119.4179],
  Connecticut: [41.209082, -72.006391],
  Hawaii: [35.826818, -125.053962],
  Delaware: [38.692701, -75.055494],
  Texas: [31.511014, -99.815962],
  Montana: [47.237103, -110.077414],
  North_Dakota: [47.71735, -100.483585],
  Washington: [47.510128, -119.876666],
  Oregon: [43.894299, -120.669186],
  Idaho: [43.748625, -114.256701],
  Nevada: [39.649606, -116.879104],
  Utah: [39.237552, -111.751785],
  Arizona: [34.437187, -111.676054],
  Wyoming: [43.151733, -107.764759],
  Colorado: [38.894572, -105.653403],
  New_Mexico: [34.328009, -105.97551],
  South_Dakota: [44.515587, -100.512352],
  Nebraska: [41.611347, -99.422726],
  Kansas: [38.584753, -98.470225],
  Oklahoma: [35.646029, -97.691472],
  Louisiana: [31.139012, -92.442752],
  Arkansas: [34.784481, -92.43166],
  Missouri: [38.478032, -92.637978],
  Iowa: [42.129985, -93.5707],
  Minnesota: [46.391118, -94.767081],
  Wisconsin: [44.853538, -89.669233],
  Illinois: [40.418785, -89.551467],
  Tennessee: [35.809256, -86.833428],
  Mississippi: [33.154465, -89.769268],
  Michigan: [43.66755, -84.806858],
  Indiana: [39.939122, -86.174638],
  Ohio: [40.089622, -82.89149],
  Kentucky: [37.67309, -85.049279],
  Florida: [27.340267, -81.300918],
  Georgia: [32.811895, -83.601007],
  South_Carolina: [33.722262, -80.661243],
  North_Carolina: [35.496648, -78.662891],
  Virginia: [37.545916, -78.617788],
  West_Virginia: [38.705615, -80.6356],
  Pennsylvania: [41.026749, -77.736867],
  New_York: [43.18948, -75.359097],
  New_Jarsey: [39.744237, -74.130007],
  Maine: [45.209803, -69.287867],
  Massachusetts: [42.278636, -70.52153],
  Mary_Land: [38.065606, -75.028624],
  Rhode_Island: [41.463654, -71.269284],
  WashingtonDc: [39.090025, -77.024793],
  Vermont: [45.121148, -72.387701],
  New_Hampshire: [45.365279, -71.303089]
}

const colorCount = Object.keys(mapColorsScale).length

function NationalMap({
  handleMapClick,
  handleMapHover,
  states,
  isRegionMap,
  deselectState = false,
  onMouseLeave
}) {
  const [highlightedState, setHighlightedState] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [filteredStates, setfilteredStates] = useState([])
  const selectedLayerRef = useRef(null)

  useEffect(() => {
    if (deselectState) {
      setSelectedState(null)
      setHighlightedState(null)
    }
  }, [deselectState])

  const statesArray = useMemo(
    () =>
      dataJson.features.map((feature) => ({
        id: feature.properties.id,
        name: feature.properties.name
      })),
    []
  )

  function handleMouseLeave() {
    onMouseLeave()
    if (selectedLayerRef.current) {
      selectedLayerRef.current.bringToFront()
    }
  }

  useEffect(() => {
    resetMap()
  }, [isRegionMap])

  function resetMap() {
    setSelectedState(null)
    setHighlightedState(null)
  }

  useEffect(() => {
    const filteredStates = states.filter((state) => state.GEOID !== 'national')
    const percentStarsArray = filteredStates.map(
      (item) => parseFloat(item?.percent_stars.replace('%', '')) / 100
    )
    const minValue = Math.min(...percentStarsArray)
    const maxValue = Math.max(...percentStarsArray)
    const range = maxValue - minValue

    const percentiles = Array.from(
      { length: colorCount },
      (_, i) => minValue + (range * (i + 1)) / colorCount
    )

    const newStatesArray = statesArray.map((statesArray) => {
      const matchingState = filteredStates.find(
        (item) => Number(item.GEOID) === Number(statesArray.id)
      )

      if (matchingState) {
        const percentStars =
          parseFloat(matchingState.percent_stars.replace('%', '')) / 100

        let stateColor, labelColor

        for (let i = 0; i < percentiles.length; i++) {
          if (percentStars <= percentiles[i]) {
            stateColor = mapColorsScale[`--purple-${i + 1}`]
            labelColor =
              i + 1 > colorCount / 2 && statesArray?.name !== 'Hawaii'
                ? '#fff'
                : '#000000'

            break
          }
        }
        const layerObject = {
          id: matchingState.id,
          name: statesArray.name,
          total_stars: matchingState.total_stars_origin,
          state_color: stateColor,
          label_color: labelColor
        }
        return layerObject
      }

      return null
    })

    const finalStatesArray = newStatesArray.filter((state) => state !== null)

    setfilteredStates(finalStatesArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleState = (state, layer) => {
    let isSameState = false
    setSelectedState((current) => {
      isSameState = current === state
      return isSameState ? null : state
    })
    selectedLayerRef.current = layer

    if (isSameState) {
      handleMapClick('15', 'national', false)
    } else {
      const stateObject = statesArray.find(
        (item) => item.name === state.replace(/_/g, ' ')
      )
      GtmMapClick(stateObject.name)
      handleMapClick(stateObject.id, stateObject.name, true)
      highlightLayer(layer, true)
    }
  }

  useEffect(() => {
    if (!highlightedState) {
      return
    }

    if (selectedState === highlightedState) {
      return
    }

    const timer = setTimeout(
      () => GtmMapHover(selectedState, highlightedState),
      5000
    )

    return () => clearTimeout(timer)
  }, [highlightedState, selectedState])

  const handleStateMouseOver = (stateName, layer) => {
    const formattedStateName = stateName.replace(/_/g, ' ')

    setHighlightedState(formattedStateName)

    const stateObject = statesArray.find(
      (item) => item.name === formattedStateName
    )

    handleMapHover(stateObject.id, stateObject.name)
    highlightLayer(layer)
  }

  const highlightLayer = (layer, selected) => {
    layer?.setStyle({
      //weight: 10,
      fillOpacity: 0.7
    })

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer?.bringToFront()
    }
  }

  const onEachFeature = (feature, layer) => {
    layer.on({
      click: () => {
        toggleState(feature.properties.name, layer)
      },
      mouseover: () => {
        handleStateMouseOver(feature.properties.name, layer)
      },
      mouseout: () => {
        setHighlightedState(null)
        handleMouseLeave()
      }
    })
  }
  const geoJSONStyle = (feature) => {
    const isHighlighted = highlightedState === feature.properties.name
    const isSelected = selectedState === feature.properties.name
    const fillColor = filteredStates.find(
      (state) => state.name === feature.properties.name
    ).state_color
    let weight, color, zIndex
    if (selectedState && isSelected) {
      weight = 4
      color = '#3D3D3D'
      //zIndex = 50
    } else if (isHighlighted) {
      weight = 4
      color = '#ff651a'
      //zIndex = 10
    } else {
      weight = 1
      color = '#fff'
    }

    return { weight, color, fillColor, zIndex }
  }

  const handleMouseOutDebounced = debounce(() => {
    setHighlightedState(null)
    handleMouseLeave()
  }, 10)

  return (
    <>
      {filteredStates.length > 0 && (
        <MapContainer
          center={[39.036665, -98.949833]}
          style={mapStyle}
          maxBoundsViscosity={1.0}
          zoom={4.49}
          zoomControl={false}
          scrollWheelZoom={false}
          dragging={false}
          touchZoom={false}
          doubleClickZoom={false}
        >
          <GeoJSON
            data={dataJson}
            style={geoJSONStyle}
            onEachFeature={onEachFeature}
          />
          {Object.keys(ACRONYM).map((state, key) => (
            <Marker
              key={key}
              position={stateCoordinates[state]}
              icon={L.divIcon({
                className: 'custom-ca-icon',
                iconSize: [0.1, 0.1],
                html: `<div style="color:${
                  filteredStates.find(
                    (dado) => dado.name === state.replace(/_/g, ' ')
                  ).label_color
                };">${ACRONYM[state]}</div>`
              })}
              eventHandlers={{
                click: () => {
                  const stateFormated = state.replace(/_/g, ' ')
                  toggleState(stateFormated)
                },
                mouseover: () => {
                  const stateFormated = state.replace(/_/g, ' ')
                  handleStateMouseOver(stateFormated)
                },
                mouseout: () => {
                  setHighlightedState(null)
                }
              }}
            />
          ))}
          {filteredStates.length > 0 && (
            <div>
              {' '}
              <Polyline
                positions={[
                  stateCoordinates['Massachusetts'],
                  [42.3601, -69.0589]
                ]}
                color={
                  filteredStates.find((state) => state.name === 'Massachusetts')
                    .state_color
                }
                dashArray='5'
                weight={2}
              />
              <Polyline
                positions={[
                  stateCoordinates['Rhode_Island'],
                  [41.102193, -68.68787]
                ]}
                color={
                  filteredStates.find((state) => state.name === 'Rhode Island')
                    .state_color
                }
                dashArray='5'
                weight={2}
              />
              <Polyline
                positions={[
                  stateCoordinates['Connecticut'],
                  [39.506154, -68.817351]
                ]}
                color={
                  filteredStates.find((state) => state.name === 'Connecticut')
                    .state_color
                }
                dashArray='5'
                weight={2}
              />
              <Polyline
                positions={[
                  stateCoordinates['Delaware'],
                  [36.048229, -68.959175]
                ]}
                color={
                  filteredStates.find((state) => state.name === 'Delaware')
                    .state_color
                }
                dashArray='5'
                weight={2}
              />
              <Polyline
                positions={[
                  stateCoordinates['New_Jarsey'],
                  [37.731534, -69.060285]
                ]}
                color={
                  filteredStates.find((state) => state.name === 'New Jersey')
                    .state_color
                }
                dashArray='5'
                weight={2}
              />
              <Polyline
                positions={[
                  stateCoordinates['Mary_Land'],
                  [34.655768, -68.867875]
                ]}
                color={
                  filteredStates.find((state) => state.name === 'Maryland')
                    .state_color
                }
                dashArray='5'
                weight={2}
              />
              <Polyline
                positions={[
                  stateCoordinates['WashingtonDc'],
                  [32.623537, -68.915744]
                ]}
                color={
                  filteredStates.find(
                    (state) => state.name === 'District of Columbia'
                  ).state_color
                }
                dashArray='5'
                weight={2}
              />
              <Polyline
                positions={[
                  stateCoordinates['New_Hampshire'],
                  [47.719071, -71.616722]
                ]}
                color={
                  filteredStates.find((state) => state.name === 'New Hampshire')
                    .state_color
                }
                dashArray='5'
                weight={2}
              />
              <Polyline
                positions={[
                  stateCoordinates['Vermont'],
                  [47.822163, -75.790719]
                ]}
                color={
                  filteredStates.find((state) => state.name === 'Vermont')
                    .state_color
                }
                dashArray='5'
                weight={2}
              />{' '}
              {MARKERS.map((marker, index) => (
                <Marker
                  key={`marker_state_${index}`}
                  position={marker.Coordinate}
                  icon={L.divIcon({
                    className: 'circular-icon',
                    html: `<div class="circle" style="background-color:${
                      filteredStates.find(
                        (dado) => dado.name === marker.StateName
                      ).state_color ?? '#fff'
                    }; color:  ${
                      filteredStates.find(
                        (dado) => dado.name === marker.StateName
                      ).label_color
                    };">${
                      STATEINITIALS[marker.StateName.replace(/ /g, '_')]
                    }</div>`
                  })}
                  eventHandlers={{
                    click: () => {
                      toggleState(marker.StateName)
                    },
                    mouseover: () => {
                      if (highlightedState !== marker.StateName)
                        handleStateMouseOver(marker.StateName)
                    },
                    mouseout: handleMouseOutDebounced
                  }}
                ></Marker>
              ))}
            </div>
          )}
        </MapContainer>
      )}
    </>
  )
}

export default React.memo(NationalMap)
