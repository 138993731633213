import React, { createContext, useState, useEffect } from 'react'

const NavigationHistoriesContext = createContext()

export const NavigationHistoriesProvider = ({ children }) => {
  const [navHistoriesValue, setNavHistories] = useState(() => {
    const savedNavHistories = localStorage.getItem('navHistories')
    const initialHistories = savedNavHistories
      ? JSON.parse(savedNavHistories)
      : []
    return initialHistories.filter((item) => item.geo_type_id !== undefined)
  })
  const setNavHistoriesValue = (value) => {
    setNavHistories(value)
    localStorage.setItem('navHistories', JSON.stringify(value))
  }

  useEffect(() => {
    localStorage.setItem('navHistories', JSON.stringify(navHistoriesValue))
  }, [navHistoriesValue])

  return (
    <NavigationHistoriesContext.Provider
      value={{ navHistoriesValue, setNavHistoriesValue }}
    >
      {children}
    </NavigationHistoriesContext.Provider>
  )
}

export const useNavigationHistories = () =>
  React.useContext(NavigationHistoriesContext)
