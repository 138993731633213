import React from 'react'
import { ReactComponent as FooterDesign1 } from '../assets/images/footer_1_design_1.svg'
import { ReactComponent as FooterDesign2 } from '../assets/images/footer_1_design_2.svg'
import { useTheme } from '@emotion/react'
import { Grid, Typography, Container } from '@mui/material'
import RoundedButton from './RoundedButton'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { footerClick } from 'src/util/gtm_utils'

function Footer({ footerText, buttonText, buttonUrl }) {
  const theme = useTheme()

  // Used to send event to GTM
  const firstWord = buttonText.replace(/ .*/, '').toLowerCase()

  return (
    <footer
      style={{
        backgroundColor: theme.palette.primary.main,
        height: '10.75rem',
        position: 'relative'
      }}
    >
      <FooterDesign1
        style={{ position: 'absolute', left: '-5rem', bottom: '0rem' }}
      />
      <FooterDesign2 style={{ position: 'absolute', right: '0rem' }} />
      <Container disableGutters={true}>
        <Grid
          container
          spacing={4}
          justifyContent='center'
          alignItems='center'
          style={{ padding: '2rem', width: 1160 }}
        >
          <Grid item>
            <Typography variant='h6' color='secondary'>
              {footerText}
            </Typography>
          </Grid>
          {buttonText && (
            <Grid item>
              <RoundedButton
                id='footer-button'
                variant='outlined'
                href={buttonUrl}
                color='secondary'
                endIcon={<ArrowRightAltIcon />}
                onClick={() => footerClick(firstWord, true)}
              >
                {buttonText}
              </RoundedButton>
            </Grid>
          )}
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
