import { useState } from 'react'
import { Region } from 'src/interfaces/demographics'
import { Button, Typography, Menu, MenuItem } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTheme } from '@emotion/react'

import { STATEINITIALS } from '../../../constants/demographics'

export default function GoToCountyButton({
  handleSearchRegionChange,
  region,
  subregionsData,
  loadingDropdown
}: {
  handleSearchRegionChange: Function
  region: Region
  subregionsData: Region[]
  loadingDropdown: Boolean
}) {
  const theme = useTheme()

  const [anchorCountyMenu, setAnchorCountyMenu] = useState<Element | null>(null)
  const open = Boolean(anchorCountyMenu)
  const handleClose = () => {
    setAnchorCountyMenu(null)
  }
  const handleCountyMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorCountyMenu(event.currentTarget as Element)
  }

  const stateInitials = STATEINITIALS[region.region_name.replace(/ /g, '_')]

  const buttonLabel =
    region?.region_code === '22'
      ? 'Parish'
      : region?.region_code === '2'
      ? 'Muni.'
      : region?.region_code === '11'
      ? ''
      : 'counties'

  const buttonText = stateInitials
    ? `${stateInitials} ${buttonLabel}`
    : subregionsData.length === 1
    ? `Go to ${subregionsData[0].region_name}`
    : 'Counties'

  return (
    <>
      <Button
        variant='outlined'
        onClick={(event) => {
          if (subregionsData.length === 1 && region.geo_type_id !== 2) {
            handleSearchRegionChange(event, subregionsData[0])
          } else {
            handleCountyMenuClick(event)
          }
        }}
        sx={{
          width: '102%',
          textTransform: 'none',
          color: theme.palette.neutral.gray900,
          border: '2px solid #E5E7EB',
          borderRadius: '120px',
          fontFamily: 'DM Sans'
        }}
      >
        <Typography variant='bodyBold'>{buttonText}</Typography>
        {(subregionsData.length > 1 || region.geo_type_id === 2) && (
          <ExpandMoreIcon />
        )}
      </Button>
      {loadingDropdown && <></>}

      {!loadingDropdown && subregionsData.length === 0 && (
        <Menu
          id='countyMenu'
          anchorEl={anchorCountyMenu}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            sx={{ color: theme.palette.neutral.gray700 }}
            onClick={handleClose}
          >
            No counties found
          </MenuItem>
        </Menu>
      )}
      {!loadingDropdown &&
        (subregionsData.length > 1 || region.geo_type_id === 2) && (
          <Menu
            id='countyMenu'
            anchorEl={anchorCountyMenu}
            open={open}
            onClose={handleClose}
          >
            {subregionsData.map((subregion, index) => {
              return (
                <MenuItem
                  key={`subregion_menuitem_${index}`}
                  onClick={(event) =>
                    handleSearchRegionChange(event, subregion)
                  }
                  sx={{ typography: 'body' }}
                >
                  {subregion.region_name}
                </MenuItem>
              )
            })}
          </Menu>
        )}
    </>
  )
}
