import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useNavigationHistories } from 'src/providers/NavHistoriesContext'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { GtmPathMap, GtmRegionChange } from 'src/util/gtm_utils'

const BreadcrumbNavigation = ({
  handleRegionChange,
  searchParams,
  msaRegion
}) => {
  const { setNavHistoriesValue, navHistoriesValue } = useNavigationHistories()

  const [nav, setNav] = React.useState([])

  useEffect(() => {
    const regionCode = searchParams.get('region_code')
    const region = msaRegion?.find(
      (item) => Number(item.region_code) === Number(regionCode)
    )
    const newFilterNavHistories = []

    if (region) {
      newFilterNavHistories[0] =
        region?.statefip && Number(region?.statefip) !== Number(regionCode)
          ? msaRegion?.find(
              (item) => Number(region?.statefip) === Number(item.region_code)
            )
          : region
    }
    if (region?.statefip && Number(region?.statefip) !== Number(regionCode)) {
      newFilterNavHistories[1] = region
    }

    setNav(newFilterNavHistories)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, msaRegion])

  const handleBreadcrumbClick = (index) => {
    const currentRegion =
      index != null && index >= 0 ? navHistoriesValue.slice(0, index + 1) : []
    setNavHistoriesValue(currentRegion)
    const nextRegion =
      index != null && index >= 0
        ? navHistoriesValue[index]
        : {
            region_name: 'National',
            region_name_long: 'National',
            geo_type_id: 1,
            region_code: 'national'
          }
    handleRegionChange(nextRegion)
    GtmPathMap(navHistoriesValue[navHistoriesValue.length - 1], nextRegion)
    GtmRegionChange(
      navHistoriesValue[navHistoriesValue.length - 1],
      nextRegion,
      'path map click'
    )
  }

  if (!nav.length) return null

  return (
    <Box
      sx={{
        width: '100%',
        height: '38px',
        backgroundColor: '#FF7F41',
        zIndex: 1000,
        position: 'fixed',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'relative',
          maxWidth: '1170px',
          padding: '0 20px'
        }}
      >
        {nav.length > 0 && (
          <>
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={() => handleBreadcrumbClick(null)}
              variant='breadCrumbs'
              color='white'
            >
              United States
            </Typography>
            {nav?.map((item, index) => (
              <React.Fragment key={index}>
                <KeyboardArrowRightIcon
                  sx={{ mx: 1, color: '#FFBFA0' }}
                  variant='breadCrumbs'
                />

                <Typography
                  variant='breadCrumbs'
                  color='white'
                  underline='hover'
                  sx={{
                    cursor: index === nav.length - 1 ? 'default' : 'pointer',
                    textDecoration:
                      index === nav.length - 1 ? 'underline' : 'none'
                  }}
                  onClick={
                    index !== nav.length - 1
                      ? () => handleBreadcrumbClick(index)
                      : null
                  }
                >
                  {item.region_name}
                </Typography>
              </React.Fragment>
            ))}
          </>
        )}
      </Box>
    </Box>
  )
}

export default BreadcrumbNavigation
