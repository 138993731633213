import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

type EventName =
  | 'create_account'
  | 'linkedin_create_account'
  | 'login'
  | 'linkedin_login'

interface GTMEvent {
  event: EventName
  account_type?: string
}

const useAuthGTMEvents = (): void => {
  const { isLoading, getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      const sendGTMEvent = (gtmEvent: GTMEvent) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(gtmEvent)
      }

      const gtmUserLogin = localStorage.getItem('gtmUserLogin')

      const checkAuthStatus = async () => {
        const claims = await getIdTokenClaims()

        if (!gtmUserLogin && claims) {
          const isFirstLogin = claims?.is_first_login as boolean
          const identityProvider = claims?.identity_provider as string
          let eventName: EventName = 'login'

          if (isFirstLogin) {
            eventName =
              identityProvider === 'auth0'
                ? 'create_account'
                : 'linkedin_create_account'
          } else {
            eventName =
              identityProvider === 'auth0' ? 'login' : 'linkedin_login'
          }

          sendGTMEvent({
            event: eventName,
            account_type: identityProvider
          })

          localStorage.setItem('gtmUserLogin', 'true')
        }
      }

      checkAuthStatus().catch(console.error)
    }
  }, [isLoading, getAccessTokenSilently, getIdTokenClaims])
}

export default useAuthGTMEvents
