import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { useTheme } from '@emotion/react'
import {
  formattedNumberLabel,
  formattedLeftTickLabel
} from '../../util/formattedLabels'

function PathwaysBar({ data }) {
  const theme = useTheme()
  const legendKeys = ['Asian', 'Black', 'Hispanic', 'White', 'Other']

  data.forEach((dataObj) => {
    // iterate through legend keys in reverse and add "lastElement" attribute for
    // last bar segment
    for (const keyLabel of [...legendKeys].reverse()) {
      if (dataObj[keyLabel]) {
        dataObj.lastElement = keyLabel
        break
      }
    }
  })

  function sumData(dataObj) {
    // map through keys and find sum the values in the dataObj for each key
    const initialValue = 0
    return legendKeys.reduce((previousValue, legend) => {
      return previousValue + (dataObj[legend] || 0)
    }, initialValue)
  }

  function findMaxSum(dataArray) {
    // map to data sums
    const sums = dataArray.map((dataObj) => sumData(dataObj))

    // get the max
    return Math.max(...sums)
  }

  function customBottomLabel(defaultLabel) {
    let customLabel = defaultLabel
    // breakpoints
    let limitArray = [
      2000000, 1600000, 1200000, 800000, 400000, 200000, 100000, 40000, 20000,
      8000, 4000, 2000
    ]
    const max = findMaxSum(data)

    // iterate through breakpoints and add a label at breakpoint/4
    for (const limit of limitArray) {
      if (max >= limit) {
        if (defaultLabel % (limit / 4) === 0) {
          customLabel = defaultLabel.toLocaleString('en-US')
        } else {
          customLabel = ''
        }
        break
      }
    }
    return customLabel
  }

  const SumLabel = ({ bars }) => {
    // array of all the bars rendered
    let sumLabels = bars.map(({ key, data, x, y, width, height }) => {
      // if the bar data has the attribute "last Element" (generated above) and if
      // the key matches that element, generate sum label
      if (data.data.lastElement) {
        if (key.includes(data.data.lastElement + '.')) {
          return (
            <text
              key={key}
              x={x + width + 3}
              y={y + height / 2 + 3}
              fontSize={14}
              fontFamily='DM Sans'
              fill={theme.palette.primary.main}
              style={{ pointerEvents: 'none' }}
            >
              {formattedNumberLabel(sumData(data.data))}
            </text>
          )
        }
      }
      return ''
    })
    return <g>{sumLabels}</g>
  }

  function mappedColors() {
    return {
      Asian: {
        default: 'rgba(255, 127, 65, 0.7)',
        active: 'rgba(255, 127, 65)'
      },
      Black: {
        default: 'rgba(61, 61, 61, 0.8)',
        active: ' rgba(61, 61, 61)'
      },
      Hispanic: {
        default: 'rgba(66, 152, 181, 0.7)',
        active: 'rgba(66, 152, 181)'
      },
      White: {
        default: 'rgba(229, 231, 235, 0.7)',
        active: 'rgba(229, 231, 235)'
      },
      Other: {
        default: 'rgba(177, 181, 188, 0.7)',
        active: 'rgba(177, 181, 188)'
      },
      NoData: {
        default: 'rgba(247, 247, 247)'
      }
    }
  }

  function isAllZeros(data) {
    return !Object.keys(data).some(
      (key) => key !== 'job_title' && data[key] > 0
    )
  }

  const ZeroDataBar = ({ bars, xScale, yScale, ...rest }) => {
    return bars.map((bar) => {
      const { key, data, y, height } = bar

      if (isAllZeros(data.data)) {
        return (
          <g transform={`translate(${0}, ${y})`} key={key}>
            <rect
              x={0}
              y={0}
              width={900}
              height={height}
              fill={mappedColors().NoData.default}
            />
            <text
              x={450}
              y={height / 2}
              textAnchor='middle'
              dominantBaseline='central'
              fill='rgba(160, 160, 160)'
              fontSize={12}
              style={{
                pointerEvents: 'none'
              }}
            >
              Not enough regional STARs data to display this chart, but this
              role still has skills-overlap and established transitions.
            </text>
          </g>
        )
      }
      return null
    })
  }

  return (
    <ResponsiveBar
      data={data}
      keys={legendKeys}
      indexBy='job_title'
      margin={{ top: 50, right: 100, bottom: 60, left: 120 }}
      padding={0.6}
      enableLabel={false}
      enableGridX={false}
      enableGridY={false}
      onMouseEnter={(_data, event) => {
        event.target.style.fill = mappedColors()[_data.id].active
      }}
      onMouseLeave={(_data, event) => {
        event.target.style.fill = mappedColors()[_data.id].default
      }}
      layout='horizontal'
      valueScale={{ type: 'linear', reverse: false }}
      indexScale={{ type: 'band', round: true }}
      colors={(bar) => {
        if (isAllZeros(bar.data)) {
          return mappedColors().NoData.default
        }
        return mappedColors()[bar.id].default
      }}
      valueFormat={(number) => Math.round(number).toLocaleString('en-US')}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      axisTop={null}
      axisRight={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legendOffset: 80,
        legend: 'Similarlity to selected job',
        legendPosition: 'middle',
        format: () => ''
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 0,
        tickRotation: 0,
        format: (defaultLabel) => customBottomLabel(defaultLabel),
        legend: 'Number of STARs in job',
        legendPosition: 'middle',
        legendOffset: 40
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        format: (defaultLabel) => formattedLeftTickLabel(defaultLabel, 18)
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      layers={[
        'grid',
        'axes',
        'bars',
        SumLabel,
        ZeroDataBar,
        'markers',
        'legends',
        'annotations'
      ]}
      role='application'
      ariaLabel='Roles with transferable skills table'
      theme={{
        axis: {
          ticks: { text: { fontSize: 14 } }
        }
      }}
    />
  )
}

export default PathwaysBar
