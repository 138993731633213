import 'src/styles/mapRegion.css'

function EmptyMap({ alertText }) {
  return (
    <main className='map-container'>
      <span>
        <strong>Map coming soon</strong>
        <br />
        Review region data &#x003E;
        <br />
        or <a href='mailto:support@stellarsight.org'>email us</a> for a custom
        map.
      </span>
    </main>
  )
}

export default EmptyMap
