import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useCookies } from 'react-cookie'

import { useTheme, LinearProgress } from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'

function RequireAuth() {
  const { user, loginWithRedirect, isLoading, isAuthenticated } = useAuth0()
  const [cookies, setCookie, removeCookie] = useCookies(['user'])
  const theme = useTheme()

  useEffect(() => {
    async function saveRedirectAndLogin() {
      await Promise.resolve().then(() => {
        let redirect
        switch (window.location.pathname) {
          case '/':
            redirect = '/find-stars?region=national&geo_type_id=1'
            break
          case '/data-guidance':
            redirect = '/data-guidance' + window.location.hash
            break
          default:
            redirect = window.location.pathname + window.location.search
        }

        localStorage.setItem('redirect', redirect)
      })
      loginWithRedirect()
    }

    if (!isLoading) {
      if (isAuthenticated) {
        if (cookies.user !== user?.sub) {
          setCookie('user', user?.sub, {
            secure: true,
            maxAge: 7776000,
            sameSite: 'lax',
            path: '/'
          })
        }
      } else {
        localStorage.removeItem('gtmUserLogin')
        removeCookie('user')
        saveRedirectAndLogin()
      }
    }
  }, [
    isAuthenticated,
    isLoading,
    cookies,
    user,
    loginWithRedirect,
    setCookie,
    removeCookie
  ])

  if (!isLoading && !isAuthenticated) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100wh',
          backgroundColor: theme?.palette.secondary.main
        }}
      >
        <LinearProgress />
      </div>
    )
  }

  return <Outlet />
}

export default RequireAuth
