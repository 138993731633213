import { useEffect, useState } from 'react'
import { Outlet, useLocation, Navigate } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import { useTheme, LinearProgress } from '@mui/material'

import { isObjectEmpty } from '../util/util'
import { internalExternal, loginType } from '../util/gtm_utils'

function RequireQuestionnaire() {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0()
  const [formData, setformData] = useState({})
  const [formDataLoading, setformDataLoading] = useState(true)
  const location = useLocation()
  const theme = useTheme()

  useEffect(() => {
    async function getMetaData() {
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
            scope: 'read:current_user'
          }
        })
        const userId = user?.sub
        const apiUrl =
          process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN ||
          process.env.REACT_APP_AUTH0_DOMAIN

        var myHeaders = new Headers()
        myHeaders.append('Accept', 'application/json')
        myHeaders.append('Authorization', `Bearer ${accessToken}`)

        fetch(`https://${apiUrl}/api/v2/users/${userId}`, {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        })
          .then((response) => response.json())
          .then((result) => {
            const user_metadata =
              typeof result?.user_metadata === 'undefined'
                ? {}
                : result.user_metadata

            if (!isObjectEmpty(user_metadata)) {
              setformData(result.user_metadata.formData)
              window.dataLayer.push({
                event: 'questionnaire_check',
                organizationName:
                  result.user_metadata.formData.dataForm.organizationName,
                persona:
                  result.user_metadata.formData.dataForm.selectedOption.value,
                loginType: loginType(user),
                internalExternal: internalExternal(
                  result.user_metadata.formData.dataForm.organizationName
                )
              })
            } else {
              setformData({
                dataForm: {
                  organizationName: '',
                  selectedOption: {
                    name: '',
                    value: ''
                  }
                },
                isFormSubmitted: false
              })
            }
            setformDataLoading(false)
          })
      }
    }
    getMetaData()
  }, [getAccessTokenSilently, isAuthenticated, user])

  if (formDataLoading) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100wh',
          backgroundColor: theme?.palette.secondary.main
        }}
      >
        <LinearProgress />
      </div>
    )
  }

  if (!formDataLoading && !formData.isFormSubmitted) {
    return <Navigate to='/login-questionnaire' state={{ from: location }} />
  }

  return <Outlet />
}

export default RequireQuestionnaire
