import '../../styles/mapDataTable.css'
import { formatNumber } from '../../helpers/numberFormating'
import Box from '@mui/material/Box'
import { truncateName } from '../../util/formattedLabels'
import { Typography } from '@mui/material'
import EmptyStateText from '../EmptyStateText'

function calculatePercentageDifference(percentageStr1, percentageStr2) {
  const percentage1 = parseFloat(percentageStr1.replace('%', ''))
  const percentage2 = parseFloat(percentageStr2.replace('%', ''))

  const decimal1 = percentage1 / 100
  const decimal2 = percentage2 / 100

  let differenceDecimal = decimal2 - decimal1

  var differencePercentage = (differenceDecimal * 100).toFixed(0)

  if (differencePercentage === '0') {
    return ''
  }

  return `${Number(differencePercentage) > 0 ? '+' : '-'}${Math.abs(
    differencePercentage
  )}`
}

function MapDataTable(props) {
  if (!props.dataNational) return null
  if (props.isSelectedState && !props.dataStateClick) return null

  let filteredDataClick

  if (props.isSelectedState) {
    if (props.dataStateClick.GEOID === '11')
      props.dataStateClick.state_name = 'Washington, D.C.'

    filteredDataClick = props.dataStateClick
  } else {
    filteredDataClick = props.dataNational
  }

  if (props.dataStateHover) {
    if (props.dataStateHover.GEOID === '11')
      props.dataStateHover.state_name = 'Washington, D.C.'
  }

  const filteredDataHover = props.dataStateHover

  const stateNameHover = !props.dataStateHover
    ? ''
    : props.dataStateHover.state_name

  const unemployedPercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.employment_status.unemployed,
          filteredDataHover.demo_emp_data.employment_status.unemployed
        )
      : ''

  const partTimePercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.employment_status.part_time,
          filteredDataHover.demo_emp_data.employment_status.part_time
        )
      : ''

  const fullTimePercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.employment_status.full_time,
          filteredDataHover.demo_emp_data.employment_status.full_time
        )
      : ''

  const raceAsianPercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.race_ethnicities.asian,
          filteredDataHover.demo_emp_data.race_ethnicities.asian
        )
      : ''

  const raceBlackPercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.race_ethnicities.black,
          filteredDataHover.demo_emp_data.race_ethnicities.black
        )
      : ''

  const raceOtherPercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.race_ethnicities.other,
          filteredDataHover.demo_emp_data.race_ethnicities.other
        )
      : ''

  const raceWhitePercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.race_ethnicities.white,
          filteredDataHover.demo_emp_data.race_ethnicities.white
        )
      : ''

  const raceHispanicPercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.race_ethnicities.hispanic,
          filteredDataHover.demo_emp_data.race_ethnicities.hispanic
        )
      : ''

  const lowPercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.hourly_wage_level.low,
          filteredDataHover.demo_emp_data.hourly_wage_level.low
        )
      : ''

  const upperPercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.hourly_wage_level.upper,
          filteredDataHover.demo_emp_data.hourly_wage_level.upper
        )
      : ''

  const highPercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.hourly_wage_level.high,
          filteredDataHover.demo_emp_data.hourly_wage_level.high
        )
      : ''

  const mediumPercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.hourly_wage_level.medium,
          filteredDataHover.demo_emp_data.hourly_wage_level.medium
        )
      : ''

  const malePercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.sex.male,
          filteredDataHover.demo_emp_data.sex.male
        )
      : ''

  const femalePercentage =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.demo_emp_data?.sex.female,
          filteredDataHover.demo_emp_data.sex.female
        )
      : ''

  const percentageDifference =
    props.dataStateHover !== null &&
    props.dataStateHover !== props.dataStateClick
      ? calculatePercentageDifference(
          filteredDataClick.percent_stars,
          filteredDataHover.percent_stars
        )
      : ''

  const starsPorcentage =
    percentageDifference !== '' ? `${percentageDifference}%` : ''

  const acronym =
    filteredDataClick?.region_code === 'national' ? 'Total US' : 'Selected area'

  const handleOnGoBackClick = () => {
    props.goBack()
  }

  const renderStateName = () => {
    if (
      props.dataStateHover !== props.dataStateClick &&
      props.dataStateHover !== null &&
      stateNameHover !== filteredDataClick.state_name
    ) {
      return (
        <>
          <Box component='span' sx={{ wordBreak: 'break-all' }}>
            {truncateName(
              props.isSelectedState ? filteredDataClick.state_name : 'All US',
              75
            )}
          </Box>
          <span>&nbsp;/&nbsp;</span>
        </>
      )
    } else {
      return (
        <Box component='span' sx={{ wordBreak: 'break-all' }}>
          {truncateName(
            props.isSelectedState ? filteredDataClick.state_name : 'All US',
            75
          )}
        </Box>
      )
    }
  }

  if (!filteredDataClick.demo_emp_data) return <EmptyStateText />

  return (
    <Box display='flex' flexDirection='column'>
      <div className='map-data-table-container'>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexWrap: 'wrap',
            height: `${
              props.regionType.region_code !== 'national' ? '80px' : 'auto'
            }`
          }}
        >
          {props.isSelectedState ? (
            <Typography
              variant='bodyBold'
              onClick={handleOnGoBackClick}
              style={{ cursor: 'pointer' }}
            >
              <span style={{ fontSize: '16px', marginRight: '5px' }}>
                {'<'}
              </span>
              {renderStateName()}
            </Typography>
          ) : (
            <Typography variant='bodyBold'>{renderStateName()}</Typography>
          )}

          {props.dataStateHover !== props.dataStateClick &&
            props.dataStateHover !== null &&
            stateNameHover !== renderStateName() && (
              <Typography
                variant='bodyBold'
                sx={{ wordBreak: 'break-all', color: '#ff651a' }}
              >
                <Box>{truncateName(stateNameHover, 80)}</Box>{' '}
              </Typography>
            )}
        </Box>

        <div className='content'>
          <p className='title-text'>{acronym} workforce</p>
        </div>

        <div className='flex-container title-container'>
          <div>
            <div className='column'>
              <p className='work-status'>
                {formatNumber(filteredDataClick.total_population)}
              </p>
            </div>
          </div>
          <div>
            <div className='column'>
              <p className='work-status'>
                {Math.round(
                  parseFloat(filteredDataClick.percent_stars?.replace('%', ''))
                ) + '%' ?? ''}{' '}
                STARs
              </p>
              <p className='percentage-country'>{starsPorcentage}</p>
            </div>
          </div>
        </div>

        <div className='flex-container title-container'>
          <div className='title-column'>
            <div className='title-text '>Work Status</div>
            <div className='column'>
              <div>
                <p className='work-status'>Unemployed</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {
                    filteredDataClick?.demo_emp_data?.employment_status
                      .unemployed
                  }
                </p>
                <p className='percentage-country'>{unemployedPercentage}</p>
              </div>
            </div>
            <div className='column'>
              <div>
                <p className='work-status'>Part-time</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {
                    filteredDataClick.demo_emp_data?.employment_status
                      ?.part_time
                  }
                </p>
                <p className='percentage-country'>{partTimePercentage}</p>
              </div>
            </div>
            <div className='column'>
              <div>
                <p className='work-status'>Full-time</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {
                    filteredDataClick.demo_emp_data?.employment_status
                      ?.full_time
                  }
                </p>
                <p className='percentage-country'>{fullTimePercentage}</p>
              </div>
            </div>
          </div>
          <div className='title-column'>
            <div className='title-text'>Race & Ethnicities</div>

            <div className='column'>
              <div>
                <p className='work-status'>White</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.race_ethnicities?.white}
                </p>
                <p className='percentage-country'>{raceWhitePercentage}</p>
              </div>
            </div>

            <div className='column'>
              <div>
                <p className='work-status'>Hispanic</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.race_ethnicities?.hispanic}
                </p>
                <p className='percentage-country'>{raceHispanicPercentage}</p>
              </div>
            </div>

            <div className='column'>
              <div>
                <p className='work-status'>Asian</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.race_ethnicities?.asian}
                </p>
                <p className='percentage-country'>{raceAsianPercentage}</p>
              </div>
            </div>
            <div className='column'>
              <div>
                <p className='work-status'>Black</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.race_ethnicities?.black}
                </p>
                <p className='percentage-country'>{raceBlackPercentage}</p>
              </div>
            </div>

            <div className='column'>
              <div>
                <p className='work-status'>Other</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.race_ethnicities?.other}
                </p>
                <p className='percentage-country'>{raceOtherPercentage}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='flex-container title-container'>
          <div className='title-column'>
            <div className='title-text'>Income Levels</div>

            <div className='column'>
              <div className=''>
                <p className='work-status'>High</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.hourly_wage_level?.high}
                </p>
                <p className='percentage-country'>{highPercentage}</p>
              </div>
            </div>

            <div className='column'>
              <div className=''>
                <p className='work-status'>Upper</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.hourly_wage_level?.upper ??
                    '0%'}
                </p>
                <p className='percentage-country'>{upperPercentage}</p>
              </div>
            </div>

            <div className='column'>
              <div className=''>
                <p className='work-status'>Medium</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.hourly_wage_level?.medium ??
                    '0%'}
                </p>
                <p className='percentage-country'>{mediumPercentage}</p>
              </div>
            </div>

            <div className='column'>
              <div className=''>
                <p className='work-status'>Low</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.hourly_wage_level?.low}
                </p>
                <p className='percentage-country'>{lowPercentage}</p>
              </div>
            </div>
          </div>
          <div className='title-column'>
            <div className='title-text'>Sex</div>

            <div className='column'>
              <div className=''>
                <p className='work-status'>Male</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.sex.male}
                </p>
                <p className='percentage-country'>{malePercentage}</p>
              </div>
            </div>

            <div className='column'>
              <div className=''>
                <p className='work-status'>Female</p>
              </div>
              <div className='wrapper-percents'>
                <p className='work-status'>
                  {filteredDataClick.demo_emp_data?.sex.female}
                </p>
                <p className='percentage-country'>{femalePercentage}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default MapDataTable
