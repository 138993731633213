import React, { useState, useMemo, useEffect, useRef } from 'react'
import { MapContainer, GeoJSON, TileLayer } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import '../../styles/map.css'
import { truncateStateName } from '../../util/formattedLabels'
import { mapColorsScale } from '../../constants/demographics'
import EmptyMap from './EmptyMap'
import { GtmMapClick, GtmMapHover } from '../../util/gtm_utils'

const RegionMap = ({
  handleMapClick,
  handleMapHover,
  stateGeoJson,
  msaStateGeoJson,
  regionData,
  deselectState = false,
  onMouseLeave,
  regionType
}) => {
  const [highlightedState, setHighlightedState] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const minZoomLevel = 4
  const maxZoomLevel = 10
  const stateGeoJsonLayerRef = useRef(null)
  const selectedStateRef = useRef(selectedState)
  const selectedLayerRef = useRef(null)

  useEffect(() => {
    if (deselectState) {
      setSelectedState(null)
      setHighlightedState(null)
    }
  }, [deselectState])

  const metrosArray = useMemo(
    () =>
      msaStateGeoJson?.features.map((feature) => ({
        id: feature.properties.GEOID,
        name: feature.properties.NAME
      })),
    [msaStateGeoJson]
  )

  const filteredMsiData = useMemo(() => {
    return regionType?.geo_type_id === 2
      ? regionData?.filter((msiItem) =>
          metrosArray?.some((item) => item.id === msiItem.region_code)
        )
      : regionData
  }, [regionData, metrosArray, regionType])

  const filteredMsiDataMap = useMemo(() => {
    const map = new Map()
    filteredMsiData?.forEach((item) =>
      map.set(
        regionType?.geo_type_id === 2 ? item.region_code : item.GEOID,
        item
      )
    )
    return map
  }, [filteredMsiData, regionType])

  const getColor = (percentStars) => {
    const percentStarsArray =
      regionData?.map((item) => item?.percent_stars) || []

    let minValue, maxValue, range
    if (percentStarsArray.length === 1) {
      // If there's only one value, set minValue and maxValue to create an artificial range around the single value
      minValue = percentStarsArray[0] - 0.5
      maxValue = percentStarsArray[0] + 0.5
      range = maxValue - minValue
    } else {
      // Calculate the real minValue and maxValue when there are multiple values
      minValue = Math.min(...percentStarsArray)
      maxValue = Math.max(...percentStarsArray)
      range = maxValue - minValue
    }

    const colorCount = Object.keys(mapColorsScale).length

    // Create percentiles based on the calculated minValue, maxValue, and range
    const percentiles = Array.from(
      { length: colorCount },
      (_, i) => minValue + (range * (i + 1)) / colorCount
    )

    // Determine the appropriate color based on the percentiles
    for (let i = 0; i < percentiles.length; i++) {
      if (percentStars <= percentiles[i]) {
        return mapColorsScale[`--purple-${i + 1}`]
      }
    }

    // In case percentStars is greater than all percentiles, return the last color
    return mapColorsScale[`--purple-${colorCount}`]
  }

  const geoJSONStyle = (feature) => {
    const name = feature.properties.NAME
    const isHighlighted = highlightedState === name
    const isSelected = selectedState === name
    const matchingMsiItem = filteredMsiDataMap.get(feature.properties.GEOID)
    const fillColor = matchingMsiItem
      ? getColor(matchingMsiItem.percent_stars)
      : '#C7A7CF'
    let weight, color
    if (selectedState && isSelected) {
      weight = 4
      color = '#3D3D3D'
    } else if (isHighlighted) {
      weight = 4
      color = '#ff651a'
    } else {
      weight = 1
      color = '#fff'
    }

    return { weight, color, fillColor, fillOpacity: 9 }
  }

  const background = {
    color: 'none',
    weight: 1,
    fillColor: 'url(#diagonalHatch)',
    fillOpacity: 9,
    pointerEvents: 'none'
  }

  const borderStyle = {
    color: selectedState ? 'grey' : 'black',
    weight: 1,
    fillColor: 'none',
    fillOpacity: 9,
    pointerEvents: 'none'
  }

  useEffect(() => {
    selectedStateRef.current = selectedState
  }, [selectedState])

  useEffect(() => {
    if (!highlightedState) {
      return
    }

    if (selectedState === highlightedState) {
      return
    }

    const timer = setTimeout(
      () =>
        GtmMapHover(
          selectedState,
          highlightedState,
          regionType?.geo_type_id,
          regionType?.region_name
        ),
      5000
    )

    return () => clearTimeout(timer)
  }, [highlightedState, selectedState, regionType])

  function handleMouseLeave() {
    onMouseLeave()
    if (stateGeoJsonLayerRef.current && !selectedStateRef.current) {
      stateGeoJsonLayerRef.current.bringToFront()
    }
    if (selectedLayerRef.current) {
      selectedLayerRef.current.bringToFront()
    }
  }

  const toggleState = (state, layer) => {
    let isSameState = false

    setSelectedState((current) => {
      isSameState = current === state
      return isSameState ? null : state
    })

    selectedLayerRef.current = layer

    if (isSameState) {
      handleMapClick(
        Number(stateGeoJson?.features[0].properties.GEOID),
        stateGeoJson?.features[0].properties.NAME,
        true
      )
    } else {
      const stateObject = metrosArray.find(
        (item) => item.name === state.replace(/_/g, ' ')
      )
      const truncatedName =
        regionType?.geo_type_id === 2
          ? truncateStateName(stateObject.name)
          : stateObject.name
      handleMapClick(stateObject.id, truncatedName, true)
      GtmMapClick(
        truncatedName,
        regionType?.geo_type_id,
        regionType?.region_name
      )
    }
  }

  const handleStateMouseOver = (stateName, layer) => {
    setHighlightedState(stateName)
    const stateObject = metrosArray.find(
      (item) => item.name === stateName.replace(/_/g, ' ')
    )
    const truncatedName =
      regionType?.geo_type_id === 2
        ? truncateStateName(stateObject.name)
        : stateObject.name
    handleMapHover(stateObject.id, truncatedName)
    highlightLayer(layer)
  }

  const highlightLayer = (layer) => {
    layer?.setStyle({
      weight: 2,
      fillOpacity: 7
    })

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront()
    }
  }

  const onEachFeature = (feature, layer) => {
    const name = feature.properties.NAME
    layer.on({
      click: () => {
        toggleState(name, layer)
      },
      mouseover: (e) => {
        handleStateMouseOver(name, layer)
      },
      mouseout: () => {
        setHighlightedState(null)
        handleMouseLeave()
      }
    })
  }
  const regionBounds =
    msaStateGeoJson?.regionBounds || stateGeoJson?.regionBounds || null

  const mapHasData = msaStateGeoJson || stateGeoJson

  return (
    <>
      {regionData && mapHasData ? (
        <>
          <svg width='0' height='0' style={{ position: 'absolute' }}>
            <defs>
              <pattern
                id='diagonalHatch'
                patternUnits='userSpaceOnUse'
                width='10'
                height='10'
                patternTransform='rotate(45)'
              >
                <line
                  x1='0'
                  y1='0'
                  x2='0'
                  y2='10'
                  style={{ stroke: '#888', strokeWidth: 1 }}
                />
              </pattern>
            </defs>
          </svg>
          <MapContainer
            bounds={regionBounds}
            minZoom={minZoomLevel}
            maxZoom={maxZoomLevel}
            maxBoundsViscosity={1.0}
          >
            <TileLayer
              attribution='&copy; <a href=“https://www.openstreetmap.org/copyright”>OpenStreetMap</a> contributors &copy; <a href=“https://carto.com/attributions”>CARTO</a>'
              url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
            />
            {stateGeoJson && <GeoJSON style={background} data={stateGeoJson} />}
            {msaStateGeoJson && (
              <GeoJSON
                style={geoJSONStyle}
                data={msaStateGeoJson}
                onEachFeature={onEachFeature}
              />
            )}

            {stateGeoJson && (
              <GeoJSON
                style={borderStyle}
                data={stateGeoJson}
                ref={stateGeoJsonLayerRef}
              />
            )}
          </MapContainer>
        </>
      ) : (
        <div style={{ width: 'calc(100% - 425px)' }}>
          <EmptyMap />
        </div>
      )}
    </>
  )
}

export default React.memo(RegionMap)
