function decimalAdjust(value, exp) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.round(value);
  }

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }

  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

export function calculateAndFormatPercent(percent) {
  if (!percent) {
    return '0%';
  }

  return String(decimalAdjust(percent * 100, 0)) + '%';
}

export function formatNumber(number, decimalPlaces = 2) {
  const roundedNumber = Number(Math.round(number / 1000) * 1000).toFixed(
    decimalPlaces
  );

  const [integerPart] = roundedNumber.split('.');

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ','
  );

  return `${formattedIntegerPart}`;
}
