const chartOverviewObjects = [
  {
    pageIntro: 'Page 1: Explore regional talent landscapes',
    chartObjects: [
      {
        chartName: 'Where do STARs live?',
        id: '1.1',
        chartDescriptionArray: [
          {
            text: `Map of region showing the density of STARs by County and Neighborhood views`
          },
          {
            text: `Neighborhoods are defined as Census Bureau Public Use Microdata Areas (PUMAs), 
                        with each PUMA containing at least 100,000 people. Counties are not directly identifiable 
                        in the data, but in some cases, where a single PUMA aligns with a single county or 
                        multiple PUMAs fit wholly within a single county, counties are identified based on PUMAs. 
                        All estimates are based on the population aged 25 and older in the civilian non-institutionalized labor force.`
          },
          {
            text: (
              <>
                <strong>Source: </strong>
                US Census Bureau 2021 American Community Survey as distributed
                through IPUMS USA, Version 10.0.
              </>
            )
          }
        ]
      },
      {
        chartName: 'Does hiring STARs improve diversity?',
        id: '1.2',
        chartDescriptionArray: [
          {
            text: `Bar chart comparing STARs to area workforce population`
          },
          {
            text: `Counties are not directly identifiable in the data, however, in some cases where a single PUMAs aligns 
                        with a single county, or multiple PUMAs fit wholly within a single county, counties are identified based on PUMAs. 
                        All estimates are based on the population aged 25 and older in the active civilian labor force.`
          },
          {
            text: (
              <>
                <strong>Source: </strong>
                US Census Bureau 2021 American Community Survey as distributed
                through IPUMS USA, Version 10.0
              </>
            )
          }
        ]
      },
      {
        chartName: 'What in-demand jobs are best for STARs?',
        id: '1.3',
        chartDescriptionArray: [
          {
            text: `A table listing jobs with the top 10 most openings within the region.`
          },
          {
            text: `Data on job postings is derived from Lightcast, based on unique job 
                        postings that are extracted on a monthly basis Lightcast SOCs are 
                        harmonized to align with historically consistent occupation codes. Estimates 
                        of employer degree requirements and average salaries exclude unspecified job postings. 
                        Estimates of the proportion of STARs currently in each occupation are based on the US 
                        Census Bureau 2021 American Community Survey as distributed through IPUMS USA, Version 10.0.`
          },
          {
            text: (
              <>
                <strong>Sources: </strong>
                2022 Lightcast™ and US Census Bureau 2021 American Community
                Survey as distributed through IPUMS USA, Version 10.0.
              </>
            )
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Page 2: Compare skills between jobs',
    chartObjects: [
      {
        chartName: 'What are alternative job titles for [selected job]?',
        id: '2.2',
        chartDescriptionArray: [
          {
            text: `Table listing job titles for the same or similar jobs.`
          },
          {
            text: (
              <>
                <strong>Source: </strong>
                Data on the job titles used in employer job postings is derived
                from Lightcast; based on unique job postings extracted monthly.
                Lightcast SOCs are harmonized to align with historically
                consistent occupation codes. Job postings by staffing agencies
                are excluded.
              </>
            )
          }
        ]
      },
      {
        chartName: 'Which roles prepare workers for [selected job]?',
        id: '2.1',
        chartDescriptionArray: [
          {
            text: `A bar chart listing jobs with similar skills to the selected job, in order of skill similarity.`
          },
          {
            text: `Similarly skilled occupations shown here include roles that offer similar skills, established transition pathways, and 
                        wage gains when moving into a specified destination job. In some cases, there are no similarly skilled origin jobs identified.`
          },
          {
            text: `The skills associated with each occupation are based on the Occupational Information Network (O*Net) 25.3 database. 
                        The O*Net skills taxonomy measures 35 unique skills across basic and cross-functional content areas. The Euclidean distance 
                        across these 35 skill dimensions is calculated across all job pairs, with lower “skill distance” values indicating that 
                        occupations are more similarly skilled. SOCs are harmonized to align with historically consistent occupation codes.`
          },
          {
            text: `In addition to skill similarly as measured by the O*Net, we also factor in observed transition pathways when determining 
                        whether jobs are similarly skilled. Using the US Census Bureau 2012 to 2021 Current Population Survey Annual Social and Economic 
                        Supplement as distributed through IPUMS CPS (Version 7.0) we identify the number of workers who moved from one occupation to 
                        another across successive years.`
          },
          {
            text: `Job pairs are defined as similarly-skilled if they meet the following criteria –`,
            bullets: [
              `Skill distance is less than 2.25 and there have been at least 2,000 observed transitions in the past 10 years.`,
              `Skill distance is less than 3.0 and there have been at least 15,000 observed transitions in the past 10 years.`,
              `Skill distance is 3.0 or higher and there have been at least 100,000 observed transitions in the past 10 years.`
            ]
          },
          {
            text: `Workers in the similarly-skilled occupations shown here would experience at least a 10 percent wage gain when 
                        moving into the specified destination job based on national median wages for workers aged 25 and older.`
          },
          {
            text: (
              <>
                <strong>Sources: </strong>
                Occupational Information Network (O*Net) 25.3 database; US
                Census Bureau 2012 to 2021 Current Population Survey Annual
                Social and Economic Supplement as distributed through IPUMS CPS
                (Version 7.0); and US Census Bureau 2021 American Community
                Survey; distributed through IPUMS USA, Version 10.0.
              </>
            )
          }
        ]
      },
      {
        chartName:
          'What career paths are possible with skills from [Selected Job]?',
        id: '2.3',
        chartDescriptionArray: [
          {
            text: `Sankey chart depicting source jobs that feed into a selected job, and destination jobs that refer out of it. See 
                        description above for details on how similarly-skilled jobs are defined.`
          },
          {
            text: `Career pathways shown here illustrate the most commonly traversed similarly skilled job pathways that offer at 
                        least a 10 percent wage gain into and out of a specified target job. Annual job transitions are based on national averages 
                        over the 2012 to 2021 period. In some cases, there are no similarly skilled origin or destination pathways identified.`
          },
          {
            text: `Opportunity@Work classifies jobs as Origin, Gateway, or Destination. Here’s what each term means:`,
            bullets: [
              <>
                <strong>ORIGIN:</strong> 'primarily entry-level, low to
                middle-wage occupation'
              </>,
              <>
                <strong>GATEWAY:</strong> 'primarily middle-wage occupation,
                offering further mobility pathways to higher wages'
              </>,
              <>
                <strong>DESTINATION:</strong> 'middle to high-wage occupation
                that provides a wage gain'
              </>
            ]
          },
          {
            text: `Wage classes are based on national median wages for workers aged 25 and older.`,
            bullets: [
              <>
                <strong>Low-wage</strong> jobs are those that pay less than
                two-thirds national median wage ($15/hour).
              </>,
              <>
                <strong>Middle-wage</strong> jobs are those that pay between
                two-thirds ($15/hour) to less than four-thirds ($29/hour)
                national median wage.
              </>,
              <>
                <strong>Upper-wage</strong> jobs pay between four-thirds
                ($29/hour) and less than two times national median wage
                ($44/hour).
              </>,
              <>
                <strong>High-wage</strong> jobs pay at least two times national
                median wage ($44/hour).
              </>
            ]
          },
          {
            text: (
              <>
                <strong>Sources: </strong>
                Occupational Information Network (O*Net) 25.3 database; US
                Census Bureau 2012 to 2021 Current Population Survey Annual
                Social and Economic Supplement as distributed through IPUMS CPS
                (Version 7.0); and US Census Bureau 2021 American Community
                Survey; distributed through IPUMS USA, Version 10.0.
              </>
            )
          }
        ]
      },
      {
        chartName: 'Compare skills between jobs',
        id: '2.4',
        chartDescriptionArray: [
          {
            text: `Radar chart comparing the shared skills between two selected jobs.`
          },
          {
            text: `This chart illustrates the skills overlap for two occupations based on the O*Net 25.3 database. 
                        The seven skills shown here vary based on the most uniquely important skills associated with the selected destination job.`
          },
          {
            text: `The annual number of transitions made by workers across each job pair is based on national estimates from the US 
                        Census Bureau 2012 to 2021 Current Population Survey Annual Social and Economic Supplement as distributed through IPUMS 
                        CPS (Version 7.0).`
          },
          {
            text: (
              <>
                <strong>Sources: </strong>
                Occupational Information Network (O*Net) 25.3 database and US
                Census Bureau 2012 to 2021 Current Population Survey Annual
                Social and Economic Supplement as distributed through IPUMS CPS
                (Version 7.0).
              </>
            )
          }
        ]
      }
    ]
  },
  {
    pageIntro: 'Page 3: Hiring for skills',
    chartObjects: [
      {
        chartName:
          'How do job requirements for [selected job] impact the available talent pool?',
        id: '3.4',
        chartDescriptionArray: [
          {
            text: `Scatter plot chart showing the size of the talent pool within a region for the selected job, based on average 
                        education and skill requirements. These may be customized by the user to compare the impact changing job requirements 
                        has on the talent pool size.`
          },
          {
            text: `This chart illustrates the talent pool of workers in similarly skilled, but lower-paying jobs based on a given 
                        target occupation. Default educational attainment is based on typical requirements as reported by the Bureau of Labor 
                        Statistics. Modifying the education requirement for the target occupation updates the talent pool to reflect the educational 
                        attainment of workers with experience in similarly skilled occupations.`
          },
          {
            text: `The skills shown here vary based on the most uniquely important skills associated with the selected destination job. 
                        Modifying the level of skills required for the target occupation updates the number of workers that would be considered similarly 
                        skilled. `
          },
          {
            text: (
              <>
                <strong>Sources: </strong>
                Bureau of Labor Statistics Employment Projections, 2020;
                Occupational Information Network (O*Net) 25.3 database; and US
                Census Bureau 2012 to 2021 Current Population Survey Annual
                Social and Economic Supplement as distributed through IPUMS CPS
                (Version 7.0). See for details on how similarly-skilled jobs are
                defined.
              </>
            )
          }
        ]
      },
      {
        chartName:
          'What skills are most frequently required for [selected job]?',
        id: '3.1',
        chartDescriptionArray: [
          {
            text: `Treemap chart showing the most common skills listed on job requirements for a given job.`
          },
          {
            text: `Data on the top requested skills by employers are derived from Lightcast, based on unique job postings that are extracted on a monthly basis. Lightcast SOCs are harmonized to align with historically consistent occupation codes`
          },
          {
            text: (
              <>
                <strong>Source: </strong>
                2024 Lightcast™.
              </>
            )
          }
        ]
      }
    ]
  }
]

const definitionArray = [
  {
    term: 'Workers',
    definition: [
      {
        fontStyle: 'normal',
        text: `This analysis is limited to members of the civilian non-institutionalized labor force aged 25 and older. 
            We exclude approximately 20 million members of the labor force under age 25 in order to ensure that the majority 
            of the population studied has completed their formal education. Members of the labor force may be employed, 
            or unemployed and looking for work.`
      }
    ]
  },
  {
    term: 'STARs',
    definition: [
      {
        fontStyle: 'normal',
        text: `STARs are workers who are Skilled Through Alternative Routes, rather than a bachelor’s degree. STARs are 
            aged 25 and older, in the civilian non-institutionalized labor force, and have a high school diploma, but no 
            bachelor’s degree.`
      }
    ]
  },
  {
    term: 'Occupations',
    definition: [
      {
        fontStyle: 'normal',
        text: `Because we are interested in better understanding how workers have historically translated their skills 
            to move into higher-paying jobs, we use modified occupational categories that allow us to make consistent comparisons 
            over time, as opposed to the most recent Federal standard occupation codes (SOCs). In the majority of cases, 
            historically harmonized occupations align to a single 2018 SOC. However, in some cases, occupations listed in this 
            tool include multiple SOCs. For example, when we refer to the occupation of “Driver/Sales Workers & Truck Drivers” 
            it includes three distinct occupations: Drivers/Sales Workers; Heavy and Tractor-Trailer Truck Drivers; and Light Truck Drivers.`
      }
    ]
  },
  {
    term: 'Metro Areas',
    definition: [
      {
        fontStyle: 'normal',
        text: `The metro areas included in this tool are limited to the 50 most populous metropolitan statistical areas (MSAs) 
                in the United States (with the addition of Youngstown, OH). When we refer to metro areas in this tool, we often refer 
                to them by their colloquial name, such as "Boston." However, estimates are based on the entire MSA, which in this example 
                would be defined as "Boston-Cambridge-Newton, MA-NH." MSAs are defined by the Office of Management and Budget and are 
                designed to capture large population cores along with adjacent communities that have a high degree of economic and social 
                integration. Because MSAs capture both the urban core as well as broader suburbs and commuter areas they are ideal when 
                studying a metropolitan area’s economy and workforce.`
      },
      {
        fontStyle: 'normal',
        text: `In some cases, multiple metro areas are shown as a single geographic unit. For example, the "San Francisco Bay Area" 
                shown in this tool includes five distinct MSAs: Napa, CA; San Francisco-Oakland-Hayward, CA; San Jose-Sunnyvale-Santa Clara, CA; 
                Santa Rosa, CA; and Vallejo-Fairfield, CA.`
      }
    ]
  },
  {
    term: 'Race & Hispanic Origin',
    definition: [
      {
        fontStyle: 'normal',
        text: `The American Community Survey gives respondents the option of reporting more than one race. Respondents 
                separately report Hispanic origin, meaning that Hispanics may be any race.`
      },
      {
        fontStyle: 'normal',
        text: `For this analysis, we separately classify Hispanics and those who report more than one race. Therefore, 
                “Black” or “white” refers to respondents who reported being a single race alone, and non-Hispanic.`
      },
      {
        fontStyle: 'normal',
        text: `The “other” category includes American Indians and Alaskan Natives; those reporting some other race,
                such as Middle Eastern or North African; and individuals who reported two or more races. Native Hawaiians 
                and Pacific Islanders are included in the Asian race category.`
      },
      {
        fontStyle: 'normal',
        text: `It's important to recognize that race is a social construct and the discrete classifications used 
                here include many diverse identities and ancestries within a given classification.`
      }
    ]
  }
]

const sourceOverviewArray = [
  {
    sourceText:
      'US Census Bureau 2021 American Community Survey as distributed through IPUMS USA, Version 10.0',
    sourceLink: 'https://usa.ipums.org/usa/',
    sourceDescription: 'Data on employment and worker demographics.'
  },
  {
    sourceText:
      'US Census Bureau 2012 to 2021 Current Population Survey Annual Social and Economic Supplement as distributed through IPUMS CPS (Version 7.0)',
    sourceLink: 'https://cps.ipums.org/cps/index.shtml',
    sourceDescription: 'Data on year-to-year occupational transitions.'
  },
  {
    sourceText: 'Occupational Information Network (O*Net) 25.3 Database.',
    sourceLink: 'https://www.onetcenter.org/database.html',
    sourceDescription: 'Data on occupational skill similarity.'
  },
  {
    sourceText: '2024 Lightcast.™',
    sourceLink:
      'https://lightcast.io/solutions/economic-and-workforce-development/developer',
    sourceDescription:
      'Data on employer job postings and minimum educational requirements.'
  }
]

export { chartOverviewObjects, definitionArray, sourceOverviewArray }
