import { useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import { LoginQuestionnaire } from './pages/LoginQuestionnaire'
import DataGuidance from './pages/DataGuidance'
import HomePage from './pages/HomePage'
import Redirect from './pages/Redirect'
import TermsOfService from './pages/TermsOfService'
import PrivacyPolicy from './pages/PrivacyPolicy'

import RequireAuth from './helpers/RequireAuth'
import RequireQuestionnaire from './helpers/RequireQuestionnaire'

import AppWrapper from './layout/AppWrapper'
import Layout from './layout/Layout'

import { useTheme, LinearProgress } from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'

import './App.css'
import useAuthGTMEvents from './hooks/useAuthGTMEvents'

function App() {
  const [formData] = useState({})
  const theme = useTheme()

  const { isLoading } = useAuth0()

  useAuthGTMEvents()

  return (
    <div className='App' data-app-version={process.env.REACT_APP_VERSION}>
      {!isLoading ? (
        <Routes>
          <Route path='redirect' element={<Redirect />} />
          <Route element={<Layout />}>
            <Route path='/' element={<HomePage />} />
            <Route path='/terms-of-services' element={<TermsOfService />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route element={<RequireAuth />}>
              <Route
                path='/login-questionnaire'
                element={
                  <LoginQuestionnaire
                    isFormSubmitted={formData.isFormSubmitted}
                  />
                }
              />
              <Route element={<RequireQuestionnaire />}>
                <Route path='/data-guidance' element={<DataGuidance />} />
                <Route path='*' element={<AppWrapper />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      ) : (
        <div
          style={{
            height: '100vh',
            width: '100wh',
            backgroundColor: theme?.palette.secondary.main
          }}
        >
          <LinearProgress />
        </div>
      )}
    </div>
  )
}

export default App
