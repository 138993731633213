import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
/**
 * Hook which will update the document title as state via browser API.
 * @param {string} title Value to set as the document title state
 * @returns Title state value and setter function
 */
const useDocumentTitle = (title) => {
  const location = useLocation()
  useEffect(() => {
    document.title = title
    setTimeout(() => {
      window.dataLayer.push({
        event: 'pageview',
        page_path: location.pathname + location.search,
        page_title: document.title
      })
    }, 100)
  }, [title, location])
}

export { useDocumentTitle }
