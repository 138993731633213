const GEO_KEY = 'GEOID'
const NATIONAL_CODE = "national"
const NATIONAL_NAME = "National"
const PERPETUATED_PARAMS = ['region', 'job_title', 'geo_type_id', 'region_code']
const REGION_COUNTYFP = "Not Applicable"
const UNIDENTIFIED_COUNTIES = "Unidentified counties"
const DOWNLOAD_ID = 'dl-element'
const DOWNLOAD_CLASS_NO_DOWNLOAD = 'no-download'

export {
    GEO_KEY,
    NATIONAL_CODE,
    NATIONAL_NAME,
    PERPETUATED_PARAMS,
    REGION_COUNTYFP,
    UNIDENTIFIED_COUNTIES,
    DOWNLOAD_ID,
    DOWNLOAD_CLASS_NO_DOWNLOAD,
}
