export const ACRONYM = {
  Alabama: 'AL',
  Alaska: 'AK',
  California: 'CA',
  Hawaii: 'HI',
  Texas: 'TX',
  Montana: 'MT',
  North_Dakota: 'ND',
  Washington: 'WA',
  Oregon: 'OR',
  Idaho: 'ID',
  Nevada: 'NV',
  Utah: 'UT',
  Arizona: 'AZ',
  Wyoming: 'WY',
  Colorado: 'CO',
  New_Mexico: 'NM',
  South_Dakota: 'SD',
  Nebraska: 'NE',
  Kansas: 'KS',
  Oklahoma: 'OK',
  Louisiana: 'LA',
  Arkansas: 'AR',
  Missouri: 'MO',
  Iowa: 'IA',
  Minnesota: 'MN',
  Wisconsin: 'WI',
  Illinois: 'IL',
  Tennessee: 'TN',
  Mississippi: 'MS',
  Michigan: 'MI',
  Indiana: 'IN',
  Ohio: 'OH',
  Kentucky: 'KY',
  Florida: 'FL',
  Georgia: 'GA',
  South_Carolina: 'SC',
  North_Carolina: 'NC',
  Virginia: 'VA',
  West_Virginia: 'WV',
  Pennsylvania: 'PA',
  New_York: 'NY',
  Maine: 'ME'
}

export const mapColorsScale = {
  '--purple-1': '#E2D1E5',
  '--purple-2': '#E0CDE3',
  '--purple-3': '#DAC4DE',
  '--purple-4': '#D4BBD9',
  '--purple-5': '#CFB2D4',
  '--purple-6': '#C9A8CF',
  '--purple-7': '#C39FCA',
  '--purple-8': '#BD96C5',
  '--purple-9': '#B283BA',
  '--purple-10': '#AC7AB5',
  '--purple-11': '#A671B0',
  '--purple-12': '#A068AB',
  '--purple-13': '#9B5EA6',
  '--purple-14': '#9555A1',
  '--purple-15': '#8F4C9C',
  '--purple-16': '#8C4799'
}

export const STATEINITIALS = {
  ...ACRONYM,
  ...{
    Connecticut: 'CT',
    Delaware: 'DE',
    District_of_Columbia: 'DC',
    Maryland: 'MD',
    Massachusetts: 'MA',
    New_Hampshire: 'NH',
    New_Jersey: 'NJ',
    Rhode_Island: 'RI',
    Vermont: 'VT'
  }
}

export const MARKERS = [
  {
    StateName: 'District of Columbia',
    Coordinate: [32.899999, -68.46062]
  },
  {
    StateName: 'Massachusetts',
    Coordinate: [42.656664, -68.409183]
  },
  {
    StateName: 'Rhode Island',
    Coordinate: [41.107663, -68.460626]
  },
  {
    StateName: 'Connecticut',
    Coordinate: [39.507663, -68.46062]
  },
  {
    StateName: 'New Jersey',
    Coordinate: [37.911663, -68.46062]
  },
  {
    StateName: 'Delaware',
    Coordinate: [36.299999, -68.46062]
  },
  {
    StateName: 'Maryland',
    Coordinate: [34.699999, -68.46062]
  },
  {
    StateName: 'New Hampshire',
    Coordinate: [48.319071, -73.116722]
  },
  {
    StateName: 'Vermont',
    Coordinate: [48.319071, -77.5]
  }
]

export const GEO_TYPE_METRO_OR_NATIONAL = 1
export const GEO_TYPE_STATE = 2
export const GEO_TYPE_COUNTY = 3
