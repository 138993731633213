import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'

function Redirect() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    window.dataLayer.push({ event: 'redirect' })
    if (!localStorage.getItem('redirect')) {
      localStorage.setItem(
        'redirect',
        '/find-stars?region=national&geo_type_id=1&region_code=national'
      )
    }
    setLoading(false)
  }, [])

  return <>{!loading && <Navigate to={localStorage.redirect} replace />}</>
}

export default Redirect
