import styles from './InputRadio.module.css';

export function InputRadio({
  name,
  value,
  label,
  selected,
  onChange,
  requiredClassName,
}) {
  return (
    <div className={styles.container}>
      <label className={requiredClassName}>
        <input
          type='radio'
          name={name}
          value={value}
          checked={selected}
          onChange={onChange}
          required
        />
        {label}
      </label>
    </div>
  );
}
