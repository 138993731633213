import { FC, useEffect, useState, useCallback } from 'react'

import { Grid } from '@mui/material'

import GoToStateButton from './buttonTypes/GoToStateButton'
import GoToMetroButton from './buttonTypes/GoToMetroButton'
import GoToCountyButton from './buttonTypes/GoToCountyButton'
import { Region, RegionType } from 'src/interfaces/demographics'
import { GtmMapButtonClick, GtmRegionChange } from 'src/util/gtm_utils'

interface MapDataTableButtonsProps {
  region: Region
  handleRegionChange: (region: Region) => void
  onShowRegionMap: (regionCode: string, geoTypeId: number) => void
  currentRegion: Region
}
declare global {
  interface Window {
    dataLayer: any[]
  }
}

const MapDataTableButtons: FC<MapDataTableButtonsProps> = ({
  region,
  handleRegionChange,
  onShowRegionMap,
  currentRegion
}) => {
  const regionId = region.region_code

  function getPageType() {
    if (currentRegion.geo_type_id === 2) return 'state'
    if (currentRegion.geo_type_id === 3) return 'county'
    if (
      currentRegion.geo_type_id === 1 &&
      currentRegion.region_code !== 'national'
    )
      return 'metro'
    return 'national'
  }

  const getRegionType = useCallback((value: Region) => {
    if (value.geo_type_id === 2) return 'state'
    if (value.geo_type_id === 3) return 'county'
    if (value.geo_type_id === 1 && value.region_code !== 'national')
      return 'msa'
    return 'national'
  }, [])
  const pageType = getPageType()

  const api_url = process.env.REACT_APP_API_URL

  const [metroSubregionsData, setMetroSubregionsData] = useState([])
  const [countySubregionsData, setCountySubregionsData] = useState([])
  const [loadingDropdown, setLoadingDropdown] = useState(true)

  const handleSearchRegionChange = useCallback(
    (event: React.ChangeEvent, values: Region) => {
      const selected_region: Region = {
        region_name: values.region_name,
        region_name_long: values.region_name_long,
        geo_type_id: values.geo_type_id,
        region_code: values.region_code,
        region: getRegionType(values),
        statefip: values?.statefip
      }
      onShowRegionMap(values.region_code, values.geo_type_id)
      handleRegionChange(selected_region)
      GtmMapButtonClick(currentRegion, selected_region, region)
      GtmRegionChange(currentRegion, selected_region, 'map filter')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getRegionType, onShowRegionMap, handleRegionChange]
  )

  // Get Metros
  useEffect(() => {
    let apiParams
    switch (pageType) {
      case 'state':
        apiParams = `statefip=${Number(regionId)}`
        break
      default:
        apiParams = `statefip=${Number(regionId)}`
    }
    setLoadingDropdown(true)

    fetch(`${api_url}/get-metros?${apiParams}`)
      .then((response) => response.json())
      .then((jsonData) => {
        jsonData.msg.forEach((obj: Region) => {
          obj.geo_type_id = 1
        })

        let sortedData = jsonData.msg.sort(function (a: Region, b: Region) {
          var textA = a.region_name.toUpperCase()
          var textB = b.region_name.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })

        setMetroSubregionsData(sortedData)
        setLoadingDropdown(false)
      })
  }, [api_url, regionId, pageType])

  // Get Counties
  useEffect(() => {
    if (pageType === 'national') return

    let apiParams
    switch (pageType) {
      case 'metro':
        apiParams = `msa=${Number(currentRegion.region_code)}`
        break
      default:
        apiParams = `statefip=${Number(regionId)}`
    }
    setLoadingDropdown(true)

    fetch(`${api_url}/get-counties?${apiParams}`)
      .then((response) => response.json())
      .then((jsonData) => {
        jsonData.msg.forEach((obj: Region) => {
          obj.geo_type_id = 3
        })
        let sortedData = jsonData.msg.sort(function (a: Region, b: Region) {
          var textA = a.region_name.toUpperCase()
          var textB = b.region_name.toUpperCase()
          return textA < textB ? -1 : textA > textB ? 1 : 0
        })
        setCountySubregionsData(sortedData)
        setLoadingDropdown(false)
      })
  }, [api_url, regionId, pageType, currentRegion.region_code])

  function renderFirstButton(pageType: RegionType) {
    switch (pageType) {
      case 'national':
        return (
          <Grid item xs={6} alignItems='center' justifyContent='center'>
            <GoToStateButton
              handleSearchRegionChange={handleSearchRegionChange}
              region={region}
            />
          </Grid>
        )
      case 'state':
        return (
          <Grid
            item
            xs={region.geo_type_id !== 1 ? 6 : 12}
            alignItems='center'
            justifyContent='center'
          >
            {region.geo_type_id !== 1 ? (
              <GoToCountyButton
                handleSearchRegionChange={handleSearchRegionChange}
                region={region}
                subregionsData={countySubregionsData}
                loadingDropdown={loadingDropdown}
              />
            ) : (
              <GoToStateButton
                handleSearchRegionChange={handleSearchRegionChange}
                region={region}
              />
            )}
          </Grid>
        )
      case 'metro':
        return (
          <Grid item xs={12} alignItems='center' justifyContent='center'>
            <GoToCountyButton
              handleSearchRegionChange={handleSearchRegionChange}
              region={currentRegion}
              subregionsData={countySubregionsData}
              loadingDropdown={loadingDropdown}
            />
          </Grid>
        )
      default:
        return <></>
    }
  }

  function renderSecondButton(pageType: RegionType) {
    switch (pageType) {
      case 'national':
        return (
          <Grid item xs={6} alignItems='center' justifyContent='center'>
            <GoToMetroButton
              handleSearchRegionChange={handleSearchRegionChange}
              region={region}
              subregionsData={metroSubregionsData}
              loadingDropdown={loadingDropdown}
            />
          </Grid>
        )
      case 'state':
        return (
          <>
            {region.geo_type_id !== 1 ? (
              <Grid item xs={6} alignItems='center' justifyContent='center'>
                <GoToMetroButton
                  handleSearchRegionChange={handleSearchRegionChange}
                  region={region}
                  subregionsData={metroSubregionsData}
                  loadingDropdown={loadingDropdown}
                />
              </Grid>
            ) : (
              <></>
            )}
          </>
        )
      default:
        return <></>
    }
  }

  if (region === null || region.region_code === 'national') {
    return <></>
  }

  return (
    <Grid
      container
      spacing={1}
      direction='row'
      alignItems='center'
      justifyContent='center'
    >
      {renderFirstButton(pageType)}
      {renderSecondButton(pageType)}
    </Grid>
  )
}

export default MapDataTableButtons
