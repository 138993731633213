import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Chip from '@mui/material/Chip'
import { useTheme } from '@emotion/react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'

import { topNavClick, footerClick } from 'src/util/gtm_utils'
import { useCookies } from 'react-cookie'

function LoginCreateAccount({ footer = false }) {
  const theme = useTheme()
  const { loginWithRedirect } = useAuth0()
  const [cookies] = useCookies(['user'])

  const login = () => {
    if (footer) {
      footerClick('login', false)
    } else {
      topNavClick('login', false)
    }
    loginWithRedirect({
      _ga: (cookies as { _ga?: string })._ga
    })
  }

  const createAccount = () => {
    if (footer) {
      footerClick('create account', false)
    } else {
      topNavClick('create account', false)
    }
    loginWithRedirect({
      screen_hint: 'signup'
    })
  }

  return (
    <Stack direction='row' spacing={1}>
      <Chip
        label={
          <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
            Log in
          </Typography>
        }
        variant='outlined'
        onClick={login}
        clickable
        sx={{ border: 2, borderColor: theme.palette.neutral.gray500 }}
      />

      <Chip
        label={
          <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
            Create Account
          </Typography>
        }
        onClick={createAccount}
        clickable
        sx={{
          color: theme.palette.neutral.white,
          bgcolor: theme.palette.orange.orange100,
          '&:hover': {
            bgcolor: theme.palette.orange.orange125
          }
        }}
      />
    </Stack>
  )
}

export default LoginCreateAccount
