import { useState, useEffect, useMemo } from 'react'
import styles from '../styles/Demographics.module.css'
import { useTheme } from '@emotion/react'

import MapDataTable from '../components/map-leaflet/MapDataTable'
import MapDataTableButtons from '../components/map-leaflet/MapDataTableButtons'
import NationalMap from '../components/map-leaflet/NationalMap'
import DemographicsDonuts from '../components/demographics/DemographicsDonuts'
import {
  calculateAndFormatPercent,
  formatNumber
} from '../helpers/numberFormating'
import { Box, Grid, LinearProgress, Paper, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Footer from '../components/Footer'
import { ReactComponent as HeaderImage } from '../assets/images/demographics_header.svg'

import DemographicsTable from '../components/demographics/DemographicsTable'
import RegionMap from 'src/components/map-leaflet/RegionMap'
import { NATIONAL_CODE } from '../constants/globalKeys'
import {
  GEO_TYPE_METRO_OR_NATIONAL,
  GEO_TYPE_COUNTY,
  GEO_TYPE_STATE
} from '../constants/demographics'
import { useQueryApi } from '../hooks/useQueryApi'
import {
  valueOrPlaceholder,
  buildParamHashLink,
  regionNameFormatting
} from '../util/util'
import { useDocumentTitle } from '../hooks/useDocumentTitle'
import { formattedNumberhundredThousandsFloor } from '../util/formattedLabels'
import { useNavigationHistories } from 'src/providers/NavHistoriesContext'
import { useMapHandler } from 'src/hooks/useMapHandler'

function getTotalStars(data) {
  let result = {}
  if (data) {
    data.forEach((regionObj) => {
      result[regionObj.region_code] = regionObj.total_stars_origin
    })
  }

  return result
}

function prepareDataTable(data) {
  if (data && Array.isArray(data)) {
    return data.map((item) => ({
      ...item,
      total_stars_origin: item.total_stars,
      total_stars: formatNumber(item.total_stars),
      percent_stars: calculateAndFormatPercent(item.percent_stars),
      unemployed: calculateAndFormatPercent(
        item.demo_emp_data['Employment Status']['Unemployed']
      ),
      demo_emp_data: {
        ...item.demo_emp_data,
        employment_status: {
          ...item.demo_emp_data['Employment Status'],
          unemployed: calculateAndFormatPercent(
            item.demo_emp_data['Employment Status']['Unemployed']
          ),
          part_time: calculateAndFormatPercent(
            item.demo_emp_data['Employment Status']['Part-time']
          ),
          full_time: calculateAndFormatPercent(
            item.demo_emp_data['Employment Status']['Full-time']
          )
        },
        race_ethnicities: {
          ...item.demo_emp_data['Race & ethnicities'],
          black: calculateAndFormatPercent(
            item.demo_emp_data['Race & ethnicities']['Black']
          ),
          asian: calculateAndFormatPercent(
            item.demo_emp_data['Race & ethnicities']['Asian']
          ),
          other: calculateAndFormatPercent(
            item.demo_emp_data['Race & ethnicities']['Other']
          ),
          white: calculateAndFormatPercent(
            item.demo_emp_data['Race & ethnicities']['White']
          ),
          hispanic: calculateAndFormatPercent(
            item.demo_emp_data['Race & ethnicities']['Hispanic']
          )
        },
        sex: {
          ...item.demo_emp_data['Sex'],
          male: calculateAndFormatPercent(item.demo_emp_data['Sex']['Male']),
          female: calculateAndFormatPercent(item.demo_emp_data['Sex']['Female'])
        },
        hourly_wage_level: {
          ...item.demo_emp_data['Hourly Wage Level'],
          low: calculateAndFormatPercent(
            item.demo_emp_data['Hourly Wage Level']['Low']
          ),
          upper: calculateAndFormatPercent(
            item.demo_emp_data['Hourly Wage Level']['Upper']
          ),
          high: calculateAndFormatPercent(
            item.demo_emp_data['Hourly Wage Level']['High']
          ),
          medium: calculateAndFormatPercent(
            item.demo_emp_data['Hourly Wage Level']['Medium']
          )
        }
      }
    }))
  }

  return []
}

function Demographics({ msaSelectedRegion, msaRegion, handleRegionChange }) {
  useDocumentTitle('Explore Regional Talent Landscape - StarSight')

  const theme = useTheme()
  const [tableData, setTableData] = useState([])
  const [publicSectorData, setPublicSectorData] = useState([])

  const { navHistoriesValue, setNavHistoriesValue } = useNavigationHistories()

  const [loading, setLoading] = useState({
    demographicsDonutsLoading: true,
    demographicsMapLoading: true,
    demographicsBarLoading: true,
    demographicsTableLoading: true
  })

  const api_url = process.env.REACT_APP_API_URL
  const msa_region_code = msaSelectedRegion?.region_code
  const CDN_BASE = process.env.REACT_APP_CDN_BASE || ''
  const CDN_URL = `${CDN_BASE}/geo`

  const persistedStore = navHistoriesValue

  let navigationHistories = persistedStore
  if (
    persistedStore?.length > 0 &&
    msaSelectedRegion?.geo_type_id === 1 &&
    msaSelectedRegion?.region_code === 'national'
  ) {
    navigationHistories = []
    setNavHistoriesValue([])
  }
  const searchBy = useMemo(() => {
    var searchBy
    switch (msaSelectedRegion?.geo_type_id) {
      case 1:
        if (msa_region_code !== 'national') {
          searchBy = 'msa'
        } else {
          searchBy = 'national'
        }
        break
      case 2:
        searchBy = 'state'
        break
      case 3:
        searchBy = 'county'
        break
      default:
        searchBy = 'national'
    }
    return searchBy
  }, [msaSelectedRegion?.geo_type_id, msa_region_code])

  //GET state data

  const queryState = useQueryApi({ endpoint: `${api_url}/1.1-state` })

  const stateData = useMemo(() => {
    return prepareDataTable(queryState?.data)
  }, [queryState?.data])

  const stateTotalStars = getTotalStars(stateData)

  //GET puma/county data

  const queryFilterPumas = useQueryApi({
    endpoint: `${api_url}/1.1-puma?region_code=${msa_region_code}&search_by=${searchBy}`,
    enable:
      !!msa_region_code &&
      (msaSelectedRegion.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL ||
        msaSelectedRegion.geo_type_id === GEO_TYPE_COUNTY) &&
      msaSelectedRegion.region_code !== 'national',
    dependency: msa_region_code
  })

  const mapPumaData = useMemo(() => {
    return prepareDataTable(queryFilterPumas?.data)
  }, [queryFilterPumas?.data])

  const filteredDataNational =
    stateData.find((dado) => dado.GEOID === 'national') ?? null

  const queryMsa = useQueryApi({
    endpoint: `${api_url}/1.1-msa`,
    enable: Boolean(msaRegion)
  })

  const msaData = prepareDataTable(queryMsa?.data)
  const msaTotalStars = getTotalStars(msaData)

  const queryCounty = useQueryApi({
    endpoint: `${api_url}/1.1-county`,
    enable: Boolean(msaRegion)
  })

  const countyData = prepareDataTable(queryCounty?.data)
  const countyTotalStars = getTotalStars(countyData)

  const {
    mapClickRegion,
    isSelectedState,
    filteredDataClick,
    filteredDataHover,
    deselectState,
    isRegionMap,
    handleMapClick,
    handleMapHover,
    handleMouseLeave,
    handleOnHideRegionMap,
    handleOnRegionChange,
    handleOnShowRegionMap
  } = useMapHandler(
    stateData,
    msaData,
    mapPumaData,
    countyData,
    msa_region_code,
    msaRegion,
    handleRegionChange,
    navigationHistories,
    msaSelectedRegion
  )

  useEffect(() => {
    setLoading((loading) => {
      return { ...loading, demographicsTableLoading: true }
    })
    const msaRegionCode = Number(msa_region_code) || msa_region_code
    fetch(`${api_url}/1.3?region_code=${msaRegionCode}`)
      .then((resp) => resp.json())
      .then((jsonTableData) => {
        setTableData(jsonTableData.filter((o) => o.stars_in_this_role > 0.05))

        // If geo_typ_id is 2 or 3 it is a state or county and should
        // have public sector data.
        if ([2, 3].includes(msaSelectedRegion.geo_type_id)) {
          fetch(
            api_url + `/1.3?region_code=${msaRegionCode}&public_employers=true`
          )
            .then((resp) => resp.json())
            .then((jsonPublicTableData) => {
              setPublicSectorData(
                jsonPublicTableData
                  .filter((o) => o.stars_in_this_role > 0.05)
                  .filter((o) => o.public_job_postings > 0)
              )
              setLoading((loading) => {
                return { ...loading, demographicsTableLoading: false }
              })
            })
        } else {
          setLoading((loading) => {
            setPublicSectorData([])
            return { ...loading, demographicsTableLoading: false }
          })
        }
      })
  }, [api_url, msa_region_code, msaSelectedRegion])

  const regionTotalStars = {
    ...stateTotalStars,
    ...msaTotalStars,
    ...countyTotalStars
  }

  //Region Data format when refresh browser
  function regionData() {
    let data = {}

    if (msaSelectedRegion.geo_type_id === GEO_TYPE_STATE) {
      const foundData = stateData?.find(
        (item) => item.region_code === msaSelectedRegion.region_code
      )
      if (foundData) {
        data = { ...foundData, parentState: 'National' }
      }
    } else if (
      msaSelectedRegion.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL &&
      msaSelectedRegion.region_code !== 'national' &&
      msaData?.length > 0
    ) {
      const foundData = msaData?.find(
        (item) => item.region_code === msaSelectedRegion.region_code
      )
      if (foundData) {
        data = { ...foundData, parentState: 'National' }
      }
    } else if (
      msaSelectedRegion.geo_type_id === GEO_TYPE_COUNTY &&
      countyData?.length
    ) {
      data = countyData?.find(
        (item) => item.region_code === msaSelectedRegion.region_code
      )
      const parentStateRegionCode = msaSelectedRegion.region_code.slice(0, -3)
      data.parentState = msaRegion?.find(
        (item) => Number(item.region_code) === Number(parentStateRegionCode)
      ).region_name
    } else {
      data = null
      return
    }

    data.state_name = msaSelectedRegion.region_name_long
    data.geo_type_id = msaSelectedRegion.geo_type_id

    return data
  }

  const msaRegionCode = Number(msa_region_code) || msa_region_code

  const { data: donutData, isLoading: donutsDataLoading } = useQueryApi({
    endpoint: `${api_url}/1.2?region_code=${msaRegionCode}&search_by=${searchBy}`,
    enable: !!msaRegionCode,
    dependency: msa_region_code
  })

  //GET state map geojson
  const { data: msaStateGeoJson, isLoading: msaLoading } = useQueryApi({
    endpoint: `${CDN_URL}/msa_by_state/${msaRegionCode}.geojson`,
    enable:
      !!msa_region_code && msaSelectedRegion.geo_type_id === GEO_TYPE_STATE,
    dependency: msa_region_code
  })
  const { data: stateGeoJson, isLoading: stateLoading } = useQueryApi({
    endpoint: `${CDN_URL}/state/${msaRegionCode}.geojson`,
    enable:
      !!msa_region_code && msaSelectedRegion.geo_type_id === GEO_TYPE_STATE,
    dependency: msa_region_code
  })

  //GET metros map geojson GEO_TYPE_METRO_OR_NATIONAL
  const { data: metroGeoJson, isLoading: metroMapLoading } = useQueryApi({
    endpoint: `${CDN_URL}/msa/${msaRegionCode}.geojson`,
    enable:
      !!msa_region_code &&
      msaSelectedRegion.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL &&
      msaSelectedRegion.region_code !== 'national',
    dependency: msa_region_code
  })

  //GET counties map geojson GEO_TYPE_COUNTY
  const { data: countyGeoJson, isLoading: countyMapLoading } = useQueryApi({
    endpoint: `${CDN_URL}/county/${msaRegionCode}.geojson`,
    enable:
      !!msa_region_code && msaSelectedRegion.geo_type_id === GEO_TYPE_COUNTY,
    dependency: msa_region_code
  })
  const { data: countyPumasGeoJson, isLoading: countyPumasMapLoading } =
    useQueryApi({
      endpoint: `${CDN_URL}/puma_by_county/${msaRegionCode}.geojson`,
      enable:
        !!msa_region_code && msaSelectedRegion.geo_type_id === GEO_TYPE_COUNTY,
      dependency: msa_region_code
    })

  const regionMapData = useMemo(() => {
    return msaSelectedRegion.geo_type_id === GEO_TYPE_STATE
      ? queryMsa?.data
      : msaSelectedRegion.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL ||
        msaSelectedRegion.geo_type_id === GEO_TYPE_COUNTY
      ? queryFilterPumas?.data
      : null
  }, [msaSelectedRegion.geo_type_id, queryMsa?.data, queryFilterPumas?.data])

  const regionMapGeoJson = useMemo(() => {
    return msaSelectedRegion.geo_type_id === GEO_TYPE_STATE
      ? msaStateGeoJson
      : msaSelectedRegion.geo_type_id === GEO_TYPE_METRO_OR_NATIONAL
      ? metroGeoJson
      : countyPumasGeoJson
  }, [
    msaSelectedRegion.geo_type_id,
    msaStateGeoJson,
    metroGeoJson,
    countyPumasGeoJson
  ])

  const borderGeoJson = useMemo(() => {
    return msaSelectedRegion.geo_type_id === GEO_TYPE_COUNTY
      ? countyGeoJson
      : msaSelectedRegion.geo_type_id === GEO_TYPE_STATE
      ? stateGeoJson
      : null
  }, [msaSelectedRegion.geo_type_id, stateGeoJson, countyGeoJson])

  const mapLoading =
    msaLoading ||
    stateLoading ||
    countyPumasMapLoading ||
    countyMapLoading ||
    msaLoading ||
    metroMapLoading

  const dataLoading =
    queryMsa.isLoading || queryFilterPumas.isLoading || queryState.isLoading

  return (
    <div>
      <header
        style={{
          backgroundColor: theme.palette.secondary.main,
          paddingBottom: '10rem',
          alignItems: 'center'
        }}
      >
        <Grid
          container
          sx={{ position: 'relative', width: 1160, margin: 'auto' }}
        >
          <HeaderImage
            style={{ position: 'absolute', right: '0', overflow: 'visible' }}
          />
        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            width: 1160,
            margin: 'auto',
            maxWidth: 1160,
            minWidth: 1160,
            padding: '3.625rem 0rem 3rem 0rem'
          }}
        >
          <Grid item xs={7}>
            <Grid item sx={{ pb: 2 }}>
              <Typography variant='display2'>
                More than{' '}
                {valueOrPlaceholder(
                  formattedNumberhundredThousandsFloor(
                    regionTotalStars[String(msaSelectedRegion?.region_code)]
                  ),
                  '________',
                  false
                )}{' '}
                STARs call{' '}
                {msaSelectedRegion?.region_code !== NATIONAL_CODE &&
                msaSelectedRegion?.geo_type_id === 1
                  ? ''
                  : 'the'}{' '}
                {valueOrPlaceholder(
                  regionNameFormatting(
                    msaSelectedRegion?.region_name,
                    msaSelectedRegion?.region_code,
                    msaSelectedRegion?.geo_type_id
                  ),
                  '________',
                  false
                )}{' '}
                home.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body'>
                Use the charts below to identify areas with high STAR density,
                assess income disparities related to race and educational
                attainment, identify geographies with diverse workers, and
                explore in-demand jobs that provide career mobility within the
                region.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </header>
      <Container>
        <section
          style={{
            margin: '-12rem 0rem 1.5rem 0rem'
          }}
        >
          <Grid container spacing={5} justifyContent='center'>
            <Grid item xs={12} zIndex={100}>
              <Paper style={{ overflow: 'hidden' }}>
                <div className={styles.parentstarsGeographyContainer}>
                  {mapLoading || dataLoading ? (
                    <div>
                      <LinearProgress color='primary' />
                    </div>
                  ) : null}
                  {!mapLoading && !dataLoading && (
                    <>
                      <div className={styles.starsGeographyContainer}>
                        <div className={styles.starsGeographyMap}>
                          {isRegionMap ? (
                            <RegionMap
                              handleMapClick={handleMapClick}
                              handleMapHover={handleMapHover}
                              msaStateGeoJson={regionMapGeoJson}
                              stateGeoJson={borderGeoJson}
                              regionData={regionMapData}
                              loading={mapLoading}
                              onMouseLeave={handleMouseLeave}
                              deselectState={deselectState}
                              regionType={msaSelectedRegion}
                            ></RegionMap>
                          ) : stateData.length > 0 && !isRegionMap ? (
                            <NationalMap
                              handleMapClick={handleMapClick}
                              handleMapHover={handleMapHover}
                              states={stateData}
                              isRegionMap={isRegionMap}
                              onMouseLeave={handleMouseLeave}
                              deselectState={deselectState}
                            />
                          ) : null}
                        </div>
                        <Box
                          flexDirection='column'
                          sx={{
                            display: 'flex',
                            px: '20px',
                            pt: '10px',
                            width: '22rem'
                          }}
                        >
                          {msaSelectedRegion.region_code === 'national' && (
                            <Typography
                              variant='h6'
                              fontFamily='DM Sans'
                              sx={{ py: '10px' }}
                            >
                              Where do STARs live?
                            </Typography>
                          )}

                          {stateData.length === 0 ||
                            msaData.length === 0 ||
                            (countyData.length === 0 && (
                              <div className={styles.lineProgress}>
                                <LinearProgress color='primary' />
                              </div>
                            ))}
                          <Box
                            flexDirection='column'
                            justifyContent='space-between'
                            sx={{ display: 'flex', height: '100%', pb: '10px' }}
                          >
                            {(filteredDataNational || regionMapData) && (
                              <>
                                <MapDataTable
                                  dataNational={filteredDataNational}
                                  dataStateClick={
                                    filteredDataClick || regionData()
                                  }
                                  dataStateHover={filteredDataHover}
                                  isSelectedState={isSelectedState}
                                  regionType={msaSelectedRegion}
                                  goBack={handleOnHideRegionMap}
                                />
                              </>
                            )}
                            {isSelectedState && (
                              <MapDataTableButtons
                                region={mapClickRegion || msaSelectedRegion}
                                currentRegion={msaSelectedRegion}
                                handleRegionChange={handleOnRegionChange}
                                onShowRegionMap={handleOnShowRegionMap}
                              />
                            )}
                          </Box>
                        </Box>
                      </div>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <div className={styles.footerGeographyMap}>
                          <div className={styles.boxGradientBar}>
                            <div className={styles.gradientBar}></div>
                            <p>More STARs</p>
                          </div>
                          {isRegionMap && (
                            <div className={styles.circle}>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='12'
                                height='12'
                                viewBox='0 0 12 12'
                                fill='none'
                              >
                                <circle
                                  cx='6'
                                  cy='6'
                                  r='5.5'
                                  fill='white'
                                  stroke='#C6C6C6'
                                />
                              </svg>
                              <p>No data</p>
                            </div>
                          )}
                          <a href='/data-guidance#1.1'>
                            <p>Data sources</p>
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Grid container spacing={1} sx={{ padding: '1rem' }}>
                  <Grid
                    item
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography variant='header'>
                      {msaSelectedRegion?.region_name === 'National'
                        ? msaSelectedRegion?.region_name
                        : regionNameFormatting(
                            msaSelectedRegion?.region_name,
                            msaSelectedRegion?.region_code,
                            msaSelectedRegion?.geo_type_id
                          )}{' '}
                      workers and STARs
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ zIndex: 1 }}>
                    <DemographicsDonuts
                      region={msaSelectedRegion}
                      data={donutData}
                      donutsLoading={donutsDataLoading}
                      isToggled={true}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <DemographicsTable
                tableData={tableData.slice(0, 10)}
                publicSectorData={publicSectorData}
                region={msaSelectedRegion}
                tableLoading={loading.demographicsTableLoading}
              />
            </Grid>
          </Grid>
        </section>
      </Container>
      <Box sx={{ marginTop: 9 }}>
        <Footer
          footerText='Learn about career pathways in your region'
          buttonText='Compare Skills Between Jobs'
          buttonUrl={buildParamHashLink('/match-skills')}
        />
      </Box>
    </div>
  )
}

export default Demographics
