import React from 'react'
import {
  Grid,
  LinearProgress,
  Typography,
  Paper,
  Autocomplete,
  TextField,
  InputAdornment
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { regionNameFormatting } from '../util/util'

const JobSearchSection = ({
  careerSearchOptions,
  searchTerm,
  handleSearchTermChange,
  submittedSearchObj,
  msaSelectedRegion,
  jobSearchLoading
}) => {
  const regionNameString = () => {
    let regionString = `${regionNameFormatting(
      msaSelectedRegion?.region_name,
      msaSelectedRegion?.region_code,
      msaSelectedRegion?.geo_type_id
    )}`
    return regionString
  }

  function handleSearchTermChangeMIM(event, userInput) {
    window.dataLayer.push({
      event: 'job_change',
      job: userInput,
      previous_job: searchTerm,
      region: msaSelectedRegion.region_name_long
    })

    handleSearchTermChange(event, userInput)
  }

  return (
    <Paper sx={{ padding: 3 }}>
      <Grid container>
        <Grid item sx={{ pb: 3 }}>
          <Typography variant='subheader'>
            Search for a job title to explore top skills
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} sx={{ pb: 3 }}>
          <Autocomplete
            options={careerSearchOptions}
            id='job-title-search'
            clearOnEscape
            disableClearable
            value={searchTerm || null}
            onChange={handleSearchTermChangeMIM}
            sx={{ width: 600 }}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography variant='body1' fontFamily={'DM Sans'}>
                  {option.label || option}
                </Typography>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '20px',
                    fontFamily: 'DM Sans',
                    height: '38px'
                  },
                  '& .MuiInputBase-input': {
                    height: '5px',
                    padding: '0 14px'
                  }
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item>
          <Typography
            variant='subheader2'
            style={{ fontWeight: 400, paddingTop: '1rem' }}
          >
            Currently in this job or similar jobs in {regionNameString()}:
            <br />
          </Typography>
          {jobSearchLoading ? (
            <div style={{ width: '70%' }}>
              <LinearProgress color='primary' />
            </div>
          ) : (
            <>
              <Typography
                variant='subheader'
                style={{ fontWeight: 700, paddingRight: '2rem' }}
              >
                {Math.round(submittedSearchObj?.total_workers).toLocaleString(
                  'en-US'
                )}{' '}
                workers /{' '}
                {Math.round(
                  submittedSearchObj?.stars_population
                ).toLocaleString('en-US')}{' '}
                STARs
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default JobSearchSection
