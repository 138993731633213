const demographicsMapLegendKeys = [
  {
    keyName: 'highest',
    colorStr: '#560066'
  },
  {
    keyName: 'high',
    colorStr: '#7E3F8B'
  },
  {
    keyName: 'mid',
    colorStr: '#A772B0'
  },
  {
    keyName: 'low',
    colorStr: '#D0A7D7'
  },
  {
    keyName: 'lowest',
    colorStr: '#FADDFF'
  }
]

const demographicsBarLegendKeys = [
  {
    keyName: 'STARs',
    colorStr: '#8C4799'
  },
  {
    keyName: 'Total workforce',
    colorStr: '#dbc6df'
  }
]

const pathwaysBarLegendKeys = [
  {
    keyName: 'Asian',
    colorStr: 'rgba(255, 127, 65, 0.7)'
  },
  {
    keyName: 'Black',
    colorStr: 'rgba(61, 61, 61, 0.8)'
  },
  {
    keyName: 'Hispanic',
    colorStr: 'rgba(66, 152, 181, 0.7)'
  },
  {
    keyName: 'White',
    colorStr: 'rgba(229, 231, 235, 0.7)'
  },
  {
    keyName: 'Other',
    colorStr: 'rgba(177, 181, 188, 0.7)'
  }
]

const pathwaysSankeyLegendKeys = [
  {
    keyName: 'Low (< $15)',
    colorStr: '#E3E48D'
  },
  {
    keyName: 'Medium ($15 - <$29)',
    colorStr: '#6CC24A'
  },
  {
    keyName: 'Upper ($29 - <$44)',
    colorStr: '#4298B5'
  },
  {
    keyName: 'High ($44+)',
    colorStr: '#1D4289'
  }
]

const hirestarsTreeLegendKeys = [
  {
    keyName: 'high',
    colorStr: '#560066'
  },
  {
    keyName: 'med',
    colorStr: '#A772B0'
  },
  {
    keyName: 'low',
    colorStr: '#FADDFF'
  }
]

export {
  demographicsMapLegendKeys,
  demographicsBarLegendKeys,
  pathwaysBarLegendKeys,
  pathwaysSankeyLegendKeys,
  hirestarsTreeLegendKeys
}
