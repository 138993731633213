import React, { useState } from 'react'
import { Box, Button, TextField, Typography } from '@mui/material'
import { ReactComponent as UrlCopyIcon } from './../assets/images/url_copy_icon.svg'
import { ReactComponent as UrlCopiedIcon } from './../assets/images/url_copied_icon.svg'
import {
  DOWNLOAD_CLASS_NO_DOWNLOAD,
  DOWNLOAD_ID
} from '../constants/globalKeys'
import { useTheme } from '@emotion/react'
import { toPng } from 'html-to-image'
import downloadjs from 'downloadjs'

async function onExportPageElementsAsImg(e) {
  e.preventDefault()

  // grab email and search/config
  const email = e.target.email.value
  const fileNameParts = e.target.file_name_parts.value

  // Consolidate filename to only word characters
  const filenameBase = [document.title, fileNameParts].join('_')
  const chartIdentifier = filenameBase.replaceAll(/\W/g, '')
  const filename = `download_${chartIdentifier}.png`

  // TODO: send email/config to save

  // 'print' the element to download
  console.debug(`Print element to png file ${filename} for ${email}`)
  const dataUrl = await toPng(document.getElementById(DOWNLOAD_ID), {
    backgroundColor: 'white',
    filter: (node) => {
      // exclude elements with class 'no-download'
      const exclusionClasses = [DOWNLOAD_CLASS_NO_DOWNLOAD]
      return !exclusionClasses.some((classname) =>
        node.classList?.contains(classname)
      )
    }
  })
  console.debug(`Download file ${filename} for ${email}`)
  downloadjs(dataUrl, filename, 'image/png')
}

const ResultShare = ({ fileNameParts }) => {
  const theme = useTheme()
  const [urlCopied, setUrlCopied] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const handleUrlCopy = () => {
    let shareUrl = new URL(window.location.href)
    shareUrl.searchParams.set('shared_link', true)
    navigator.clipboard.writeText(shareUrl.toString())
    setUrlCopied(true)
    setTimeout(() => {
      setUrlCopied(false)
    }, 5000)
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div style={{ padding: '2rem' }} className={DOWNLOAD_CLASS_NO_DOWNLOAD}>
      <Typography align='left' variant='h6' color='primary' marginBottom={1}>
        Share what you learned
      </Typography>
      <form onSubmit={onExportPageElementsAsImg}>
        <input
          type='hidden'
          name='file_name_parts'
          value={fileNameParts?.join('-')}
        />
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '0.5rem'
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <TextField
              name='email'
              id='email-download-form'
              hiddenLabel
              placeholder='Enter your email to download'
              fullWidth
              size='small'
              type='email'
              required={true}
              variant='filled'
              sx={{
                borderRadius: '4px 4px 0px 0px',
                background: 'white',
                input: {
                  '&::placeholder': {
                    color: 'rgba(0, 0, 0, 0.6)'
                  }
                },
                '& .MuiFilledInput-root': {
                  background: '#FFFFFF'
                }
              }}
            />
          </Box>
          <Box>
            <Button
              id='download-results'
              variant='contained'
              color='secondary'
              type='submit'
              style={{
                fontWeight: 'bold',
                textTransform: 'capitalize',
                boxSizing: 'contain-box',
                margin: '0 0.75rem 0 1rem'
              }}
            >
              Download
            </Button>
          </Box>
          <Box>
            <Button
              id='copy-url'
              variant={urlCopied ? 'filled' : 'outlined'}
              sx={{
                width: '130px',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                color: urlCopied ? 'white' : 'rgba(0, 0, 0, 0.6)',
                backgroundColor: urlCopied && theme.palette.primary.main,
                border: '1px solid #DDDDDD',
                '&:hover': {
                  backgroundColor: theme.palette.primary.main,
                  color: 'white'
                }
              }}
              startIcon={
                urlCopied ? (
                  <UrlCopiedIcon />
                ) : (
                  <UrlCopyIcon fill={isHovered ? '#FFFFFF' : '#979797'} />
                )
              }
              onClick={handleUrlCopy}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {urlCopied ? 'Copied!' : 'Copy Link'}
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  )
}

export default ResultShare
