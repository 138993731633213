import { ResponsivePie } from '@nivo/pie'
import { Link } from 'react-router-dom'
import { LinearProgress, Typography, Grid, Box } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { formattedNumberLabel } from '../../util/formattedLabels'
import { buildParamHashLink } from '../../util/util'
import {
  DemographicData,
  Demographics,
  Region,
  StartTypeDemographics
} from 'src/interfaces/demographics'

import style from '../../styles/DemographicsDonuts.module.css'
import EmptyStateText from '../EmptyStateText'

type Demographic = {
  demo: string
  color: string
}

type LinePattern = {
  id: string
  type: string
  background: string
  color: string
  rotation: number
  lineWidth: number
  spacing: number
}

type CenteredMetricProps = {
  dataWithArc: any
  centerX: number
  centerY: number
}

function DemographicsDonuts({
  isToggled,
  region,
  data,
  donutsLoading
}: {
  isToggled: Boolean
  region: Region
  data: Demographics[]
  donutsLoading: Boolean
}) {
  const regionName = region?.region_name

  const chartGuidanceLinkTo = buildParamHashLink('/data-guidance', '1.2')

  // This is needed because the old data in the 1.2 has multiple
  // entries for each region code. They need to be removed from the DB
  // and api but until that happens this piece of code cleans up the
  // results.
  const filteredData =
    data?.length > 0
      ? data.length > 1
        ? data.find((region) => region.location === regionName)
        : data[0]
      : undefined

  const percentStars = Math.round(
    filteredData?.demographics?.find((x) => x.demographic === 'All')
      .percent_stars * 100
  )

  // This function was used by the old 1.2 chart and it is still
  // somewhat useful. This manipulates the API results to a more
  // easily used format for the pie charts. It's almost exactly the
  // same as what it was before. It could probably be cleaned up a
  // little bit.
  function transformedDemographics(filteredData: Demographics[]) {
    const demographics = filteredData?.demographics

    const transformedArray = demographics.map(
      (demographicElement: DemographicData) => {
        return {
          demographic: demographicElement.demographic.toUpperCase(),
          STARs: demographicElement.total_stars,
          'Total workforce':
            demographicElement?.total_population -
            demographicElement?.total_stars,
          total_population: demographicElement.total_population,
          percent_stars: demographicElement.percent_stars
        }
      }
    )
    const orderedArray = transformedArray.sort((a, b) => {
      var demographicsOrder = ['WHITE', 'HISPANIC', 'BLACK', 'ASIAN', 'OTHER']
      return (
        demographicsOrder.indexOf(a.demographic) -
        demographicsOrder.indexOf(b.demographic)
      )
    })

    return { ...filteredData, demographics: orderedArray }
  }

  enum DemographicColor {
    WHITE = '#E5E7EB',
    OTHER = '#8F959F',
    HISPANIC = '#4298B5',
    BLACK = '#3D3D3D',
    ASIAN = '#FF7F41',
    NON_STARS = '#ffffff'
  }
  function demographicColorLookup(
    demographic: keyof typeof DemographicColor
  ): string {
    return DemographicColor[demographic] || ''
  }

  // Color and label options for demographic donuts legend
  const legendOptions: Demographic[] = [
    { demo: 'White', color: demographicColorLookup('WHITE') },
    { demo: 'Hispanic', color: demographicColorLookup('HISPANIC') },
    { demo: 'Black', color: demographicColorLookup('BLACK') },
    { demo: 'Asian', color: demographicColorLookup('ASIAN') },
    { demo: 'Others', color: demographicColorLookup('OTHER') },
    { demo: 'Non-STARs', color: demographicColorLookup('NON_STARS') }
  ]

  const linePattern: LinePattern = {
    id: 'lines',
    type: 'patternLines',
    background: 'white',
    color: 'rgba(199, 199, 199, 0.4)',
    rotation: 45,
    lineWidth: 2.5,
    spacing: 9
  }

  // This is the metric in the middle of the first donut.
  const allWorkersCenteredMetric: React.FC<CenteredMetricProps> = ({
    dataWithArc,
    centerX,
    centerY
  }) => {
    const allDemo = transformedDemographics(filteredData).demographics
    const demoOnlyAll = allDemo.find(
      (demographic) => demographic.demographic === 'ALL'
    )
    return (
      <>
        <text
          x={centerX}
          y={centerY - 15}
          fill='#6C727F'
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            fontSize: '16px',
            fontWeight: 400
          }}
        >
          All workers
        </text>
        <text
          x={centerX}
          y={centerY + 15}
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            fontSize: '24px',
            fontWeight: 600
          }}
        >
          {formattedNumberLabel(demoOnlyAll.total_population, 1)}
        </text>
      </>
    )
  }

  const getDonutCenterText = ({ dataWithArc, centerX, centerY, typeStar }) => {
    const typeStarData = filteredData?.star_types_demographics.find(
      (type) => type.star_type === typeStar
    )

    var allDemo
    var demoOnlyAll
    if (typeStarData) {
      allDemo = transformedStarsTypesDemographics(typeStarData).demographics
      demoOnlyAll = allDemo.find(
        (demographic) => demographic.demographic === 'ALL'
      )
    }

    return (
      <>
        <text
          x={centerX}
          y={centerY}
          fill='#777'
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            fontSize: '24px',
            fontWeight: 600
          }}
        >
          {!demoOnlyAll ? '0' : formattedNumberLabel(demoOnlyAll.STARs, 1)}
        </text>
      </>
    )
  }

  // This is the metric in the middle of the second donut.
  const starsWorkersCenteredMetric: React.FC<CenteredMetricProps> = ({
    dataWithArc,
    centerX,
    centerY
  }) => {
    const allDemo = transformedDemographics(filteredData).demographics
    const demoOnlyAll = allDemo.find(
      (demographic) => demographic.demographic === 'ALL'
    )

    return (
      <>
        <text
          x={centerX}
          y={centerY - 15}
          fill='#6C727F'
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            fontSize: '16px',
            fontWeight: 400
          }}
        >
          STARs
        </text>
        <text
          x={centerX}
          y={centerY + 15}
          textAnchor='middle'
          dominantBaseline='central'
          style={{
            fontSize: '24px',
            fontWeight: 600
          }}
        >
          {formattedNumberLabel(demoOnlyAll.STARs, 1)}
        </text>
      </>
    )
  }

  // This is the data for the second donut.
  function starsWorkersData() {
    const allDemo = transformedDemographics(filteredData)?.demographics
    const demoWithoutAll = allDemo.filter(
      (demographic) => demographic.demographic !== 'ALL'
    )
    const demoOnlyAll = allDemo.find(
      (demographic) => demographic.demographic === 'ALL'
    )

    const populationRemainder =
      100 -
      percentCalc(
        demoOnlyAll.STARs,
        filteredData.demographics.find((data) => data.demographic === 'All')
          .total_population
      )

    var test = demoWithoutAll.map((demographic) => {
      return {
        id: demographic.demographic,
        value: percentCalc(
          demographic.STARs,
          isToggled ? demoOnlyAll.STARs : demoOnlyAll.total_population
        ),
        color: demographicColorLookup(demographic.demographic)
      }
    })

    if (!isToggled)
      test.unshift({
        id: 'Non-STARs',
        value: populationRemainder,
        color: demographicColorLookup('NON_STARS')
      })

    return test
  }

  function transformedStarsTypesDemographics(
    filteredDataTypeStar: StartTypeDemographics | undefined
  ) {
    const { demographics } = filteredDataTypeStar

    const filteredDemographicData = filteredData?.demographics.find(
      (data) => data.demographic === 'All'
    )
    const totalStarByType = demographics.find(
      (dado) => dado.demographic === 'All'
    ).total_stars

    const totalPopulation = filteredDemographicData?.total_population

    const totalPopulationByEthnicity = filteredDemographicData?.total_population

    const transformedArray = demographics.map(
      (demographicElement: DemographicData[]) => {
        const percentWorkersStars =
          (demographicElement?.total_stars / totalPopulationByEthnicity) * 100
        const percentTotalWorkForce = (totalStarByType / totalPopulation) * 100

        return {
          demographic: demographicElement?.demographic.toUpperCase(),
          totalWorkforce: percentTotalWorkForce,
          STARs: demographicElement?.total_stars,
          percent_stars: percentWorkersStars
        }
      }
    )

    const demographicsOrder = ['WHITE', 'HISPANIC', 'BLACK', 'ASIAN', 'OTHER']

    const orderedArray = transformedArray.sort((a, b) => {
      return (
        demographicsOrder.indexOf(a.demographic) -
        demographicsOrder.indexOf(b.demographic)
      )
    })

    return { ...filteredDataTypeStar, demographics: orderedArray }
  }

  function starsTypeData(typeStar, isTitle = false) {
    const typeStarData = filteredData.star_types_demographics.find(
      (type) => type.star_type === typeStar
    )

    const allDemo = transformedStarsTypesDemographics(typeStarData).demographics

    const demoWithoutAll = allDemo.filter(
      (demographic) => demographic.demographic !== 'ALL'
    )
    const demoOnlyAll = allDemo.find(
      (demographic) => demographic.demographic === 'ALL'
    )

    const populationRemainder =
      100 -
      percentCalc(
        demoOnlyAll.STARs,
        filteredData.demographics.find((data) => data.demographic === 'All')
          .total_population
      )

    var dataDonut = demoWithoutAll.map((demographic) => {
      return {
        id: demographic.demographic,
        value:
          isToggled && !isTitle
            ? percentCalc(demographic.STARs, demoOnlyAll.STARs)
            : Number(demographic.percent_stars.toFixed(2)),
        color: demographicColorLookup(demographic.demographic)
      }
    })

    if (!isToggled)
      dataDonut.unshift({
        id: 'Non-STARs',
        value: populationRemainder,
        color: demographicColorLookup('NON_STARS')
      })

    return dataDonut
  }

  // Just a simple percent calculator function
  function percentCalc(num, denom) {
    return Math.round((num / denom) * 100)
  }

  // This is the data for the first donut.
  function allWorkersData() {
    const allDemo = transformedDemographics(filteredData).demographics
    const demoWithoutAll = allDemo.filter(
      (demographic) => demographic.demographic !== 'ALL'
    )
    const demoOnlyAll = allDemo.find(
      (demographic) => demographic.demographic === 'ALL'
    )

    return demoWithoutAll.map((demographic) => {
      return {
        id: demographic.demographic,
        value: percentCalc(
          demographic.total_population,
          demoOnlyAll.total_population
        ),
        color: demographicColorLookup(demographic.demographic)
      }
    })
  }

  function starsPercent(starWorkersData) {
    const dataWithoutNonStars = starWorkersData.filter(
      (demographic) => demographic.id !== 'Non-STARs'
    )

    const starsSum = dataWithoutNonStars.reduce((n, { value }) => n + value, 0)
    return Math.round(starsSum)
  }

  function calculatePercentageByStarType(type) {
    const typeStarData = filteredData?.star_types_demographics?.find(
      (data) => data.star_type === type
    )

    const totalStarsByType = typeStarData?.demographics?.find(
      (data) => data.demographic === 'All'
    ).total_stars

    const totalStars = filteredData?.demographics?.find(
      (data) => data.demographic === 'All'
    ).total_stars

    const percentageByStarType = percentCalc(totalStarsByType, totalStars)
    return percentageByStarType
  }

  return (
    <>
      {donutsLoading && (
        <div style={{ height: '40rem' }}>
          <LinearProgress color='primary' />
        </div>
      )}
      {!donutsLoading && filteredData ? (
        <>
          <Grid container spacing={0} sx={{ marginTop: 4 }}>
            <Grid
              item
              xs={6}
              sx={{ height: 300, borderBottom: '1px solid #E5E7EB' }}
            >
              <Box sx={{ marginBottom: 2 }}>
                <Typography variant='bodyBold'>
                  100% of {regionName} workers
                </Typography>
              </Box>
              <ResponsivePie
                data={allWorkersData()}
                innerRadius={0.75}
                width={256}
                height={256}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                valueFormat={(value) => `${value}%`}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]]
                }}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]]
                }}
                colors={{
                  datum: 'data.color'
                }}
                layers={[
                  'arcs',
                  'arcLabels',
                  'arcLinkLabels',
                  'legends',
                  allWorkersCenteredMetric
                ]}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ height: 300, borderBottom: '1px solid #E5E7EB' }}
            >
              <Box sx={{ marginBottom: 2 }}>
                <Typography variant='bodyBold'>
                  {percentStars}% of {regionName} workers are STARs
                </Typography>
              </Box>
              <ResponsivePie
                data={starsWorkersData()}
                innerRadius={0.75}
                width={256}
                height={256}
                enableArcLabels={false}
                enableArcLinkLabels={false}
                valueFormat={(value) => `${value}%`}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]]
                }}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]]
                }}
                colors={{
                  datum: 'data.color'
                }}
                defs={[linePattern]}
                fill={[
                  {
                    match: {
                      id: 'Non-STARs'
                    },
                    id: 'lines'
                  }
                ]}
                layers={[
                  'arcs',
                  'arcLabels',
                  'arcLinkLabels',
                  'legends',
                  starsWorkersCenteredMetric
                ]}
              />
            </Grid>

            <div className={style.donutContainer}>
              <div className={style.boxDonut}>
                <strong>Forming STARs</strong>
                <p>
                  {' '}
                  {isToggled
                    ? calculatePercentageByStarType('Forming') + '% of STARs'
                    : starsPercent(starsTypeData('Forming', true)) +
                      '% of workers'}
                </p>

                <div className={style.donutGroup}>
                  <ResponsivePie
                    data={starsTypeData('Forming')}
                    innerRadius={0.75}
                    width={128}
                    height={128}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    valueFormat={(value) => `${value}%`}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]]
                    }}
                    arcLinkLabelsColor={{ from: 'color' }}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]]
                    }}
                    colors={{
                      datum: 'data.color'
                    }}
                    defs={[linePattern]}
                    fill={[
                      {
                        match: {
                          id: 'Non-STARs'
                        },
                        id: 'lines'
                      }
                    ]}
                    layers={[
                      'arcs',
                      'arcLabels',
                      'arcLinkLabels',
                      'legends',
                      (props) =>
                        getDonutCenterText({ ...props, typeStar: 'Forming' })
                    ]}
                  />

                  <p>
                    STARs who can see smaller wage gains through limited
                    employment pathways.
                  </p>
                </div>
              </div>

              <div className={style.boxDonut}>
                <strong>Rising STARs</strong>
                <p>
                  {isToggled
                    ? calculatePercentageByStarType('Rising') + '% of STARs'
                    : starsPercent(starsTypeData('Rising', true)) +
                      '% of workers'}
                </p>

                <div className={style.donutGroup}>
                  <ResponsivePie
                    data={starsTypeData('Rising')}
                    innerRadius={0.75}
                    width={128}
                    height={128}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    valueFormat={(value) => `${value}%`}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]]
                    }}
                    arcLinkLabelsColor={{ from: 'color' }}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]]
                    }}
                    colors={{
                      datum: 'data.color'
                    }}
                    defs={[linePattern]}
                    fill={[
                      {
                        match: {
                          id: 'Non-STARs'
                        },
                        id: 'lines'
                      }
                    ]}
                    layers={[
                      'arcs',
                      'arcLabels',
                      'arcLinkLabels',
                      'legends',
                      (props) =>
                        getDonutCenterText({ ...props, typeStar: 'Rising' })
                    ]}
                  />
                  <p>
                    STARs who have several pathways into higher paying
                    occupations.
                  </p>
                </div>
              </div>
              <div className={style.boxDonut}>
                <strong>Shining STARs</strong>
                <p>
                  {isToggled
                    ? calculatePercentageByStarType('Shining') + '% of STARs'
                    : starsPercent(starsTypeData('Shining', true)) +
                      '% of workers'}
                </p>
                <div className={style.donutGroup}>
                  <ResponsivePie
                    data={starsTypeData('Shining')}
                    innerRadius={0.75}
                    width={128}
                    height={128}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    valueFormat={(value) => `${value}%`}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.2]]
                    }}
                    arcLinkLabelsColor={{ from: 'color' }}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 2]]
                    }}
                    colors={{
                      datum: 'data.color'
                    }}
                    defs={[linePattern]}
                    fill={[
                      {
                        match: {
                          id: 'Non-STARs'
                        },
                        id: 'lines'
                      }
                    ]}
                    layers={[
                      'arcs',
                      'arcLabels',
                      'arcLinkLabels',
                      'legends',
                      (props) =>
                        getDonutCenterText({ ...props, typeStar: 'Shining' })
                    ]}
                  />

                  <p>STARs in high wage roles today.</p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid container spacing={0} sx={{ marginTop: 4 }}>
            <Grid item xs={11}>
              <Typography variant='small' sx={{ display: 'flex' }}>
                {legendOptions.map((legendOption, index) => {
                  const demo = legendOption.demo
                  const color = legendOption.color

                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginRight: 16
                      }}
                      key={index}
                    >
                      <CircleIcon
                        fontSize='small'
                        sx={{
                          color: color,
                          boxShadow: `${
                            demo === 'Non-STARs'
                              ? 'inset 0 0 10px #b5b5b5'
                              : 'none'
                          }`,
                          borderRadius: '50%'
                        }}
                      />
                      <span>{demo}</span>
                    </div>
                  )
                })}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant='small' sx={{ display: 'flex' }}>
                <Link
                  to={chartGuidanceLinkTo}
                  underline='none'
                  style={{ color: '#1D4289' }}
                >
                  Data sources
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <EmptyStateText alertText={undefined} />
      )}
    </>
  )
}

export default DemographicsDonuts
